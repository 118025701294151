import React from 'react';
import { Stop } from '../../../../../types';
import { AgisticsDateTimePicker } from '../../../../shared/inputs/agistics-date-time-picker';
import { useDraftLoadStopContext } from '../../../hooks/draft-load-stop-context';

interface EditableStopTimeProps {
    stop: Stop;
    property: 'readyTime' | 'requiredBegin' | 'requiredEnd';
}

export const EditableStopTime: React.FC<EditableStopTimeProps> = (props) => {
    const { stop, property } = props;
    const { onEditedStopChanged, isCarrier, isComplete } = useDraftLoadStopContext();
    const dateTime = stop[property];

    return (
        <AgisticsDateTimePicker
            value={dateTime}
            disabled={isCarrier || isComplete}
            onChange={(dt: string | null) => onEditedStopChanged({ [property]: dt || null })}
            smallText={true}
        />
    );
};
