import React from 'react';
import { Stop } from '../../../../../types';
import { IconButton } from '@mui/material';
import { L } from 'harmony-language';
import { Functions } from '@mui/icons-material';
import { useDraftLoadStopContext } from '../../../hooks/draft-load-stop-context';
import { AgisticsTooltip } from '../../../../shared/agistics-tooltip';

interface EditableStopAutoCalcProps {
    stop: Stop;
}

export const EditableStopAutoCalc: React.FC<EditableStopAutoCalcProps> = (props) => {
    const { stop } = props;
    const { atuoCalculateStopTimes, isCarrier, isComplete } = useDraftLoadStopContext();

    return (
        <AgisticsTooltip tooltip={L.calculateOtherStopTimes()}>
            <IconButton
                size='small'
                onClick={() => atuoCalculateStopTimes(stop)}
                disabled={!stop.arrivalTime || isCarrier || isComplete}
                sx={{ padding: 0 }}
            >
                <Functions fontSize='inherit' />
            </IconButton>
        </AgisticsTooltip>
    );
};
