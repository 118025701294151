import React, { createContext, FC, ReactNode, useContext, useState } from 'react';
import { QueryKeys } from '../api/config';
import { DateFilter } from '../types';
import { getWorkingWeek } from '../utils/date-time-utils';

const week0 = getWorkingWeek(0);

const loads: DateFilter = {
    page: '/loads', // this should match react-router page
    mode: 'week',
    offset: 0,
    from: week0.sundayIso,
    to: week0.saturdayIso,
    includeDrafts: false,
    // includedDates: [],
};

const drafts: DateFilter = {
    page: '/drafts',
    mode: 'week',
    offset: 0,
    from: week0.sundayIso,
    to: week0.saturdayIso,
    includeDrafts: true,
    // includedDates: [],
};

const productionCardGroups: DateFilter = {
    page: '/production-planning',
    mode: 'week',
    offset: 0,
    from: week0.sundayIso,
    to: week0.saturdayIso,
    includeDrafts: false,
    // includedDates: [],
};

interface AgisticsContext {
    companyId: number;
    updateCompanyId: (n: number) => void;
    dateFilters: { [x: string]: DateFilter };
    updateDateFilters: (k: string, f: Partial<DateFilter>) => void;
    socketStopIds: number[];
    addSocketStopId: (n: number) => void;
    resetSocketStopIds: () => void;
}

const AgisticsContext = createContext<AgisticsContext | undefined>(undefined);

interface AgisticsProviderProps {
    children: ReactNode;
}

export const AgisticsProvider: FC<AgisticsProviderProps> = (props) => {
    const { children } = props;
    const [companyId, setCompanyId] = useState(0);
    const [dateFilters, setDateFilters] = useState({
        [QueryKeys.loads]: loads,
        [QueryKeys.drafts]: drafts,
        [QueryKeys.productionCardGroups]: productionCardGroups,
    });
    const [socketStopIds, setStocketStopIds] = useState<number[]>([]);

    const updateCompanyId = (value: number) => {
        setCompanyId(value);
    }

    const updateDateFilters = (key: string, newFilter: Partial<DateFilter>) => {
        const filterToUpdate = dateFilters[key];

        const updatedFilter = {
            ...filterToUpdate,
            ...newFilter,
        }

        const updatedFilters = {
            ...dateFilters,
            [key]: {
                ...updatedFilter
            }
        }

        setDateFilters(updatedFilters);
    }

    const addSocketStopId = (stopId: number) => {
        setStocketStopIds((prevState) => {
            return [
                ...prevState,
                stopId,
            ];
        });
    }

    const resetSocketStopIds = () => {
        setStocketStopIds([]);
    }

    return (
        <AgisticsContext.Provider
            value={{
                companyId,
                updateCompanyId,
                dateFilters,
                updateDateFilters,
                socketStopIds,
                addSocketStopId,
                resetSocketStopIds,
        }}>
            {children}
        </AgisticsContext.Provider>
    )
}

export const useAgisticsContext = () => {
    const agisticsContext = useContext(AgisticsContext);

    if (agisticsContext === undefined) {
        throw new Error('useAgisticsContext must be used within a AgisticsProvider');
    }

    return agisticsContext;
}

// There is a difference between companyId and organizationId
// A normal users companyId will always be their organizationId, the difference is
// admin@dptl.com the companyId is the company that they are viewing at the time
export const useCompanyId = () => {
    const agisticsContext = useContext(AgisticsContext);

    if (agisticsContext === undefined) {
        throw new Error('useCompanyId must be used within a AgisticsProvider');
    }

    return agisticsContext.companyId;
}
