import { StopStatus, StopType } from 'harmony-constants';
import { Stop } from '../../../types';

type CreateStop = Omit<Stop, 'id'>;

export const typedCreateStop = (loadId: number | undefined, stop: Partial<Stop> ): CreateStop => {

    return {
        // id: null,
        // @ts-ignore
        orderId: loadId,
        organizationLocationId: -1,
        status: StopStatus['Not Started'],
        sequence: stop.sequence || 0,
        cargoTypeId: stop.cargoTypeId || null,
        type: stop.type || StopType.Origin,
        arrivalTime: null,
        quantity: stop.quantity || null,
        actualQuantity: null,
        orderNumber: stop.orderNumber || null,
        productionPlan: stop.productionPlan || null,
        compartments: stop.compartments || null,
        lotId: stop.lotId || null,
        organizationSubLocationIds: null,
        splitId: stop.splitId || null,
        notes: stop.notes,
        customerOrderNumber: null,
        requiredBegin: null,
        requiredEnd: null,
        readyTime: null,
        // domKey: Math.random(),
        weight: stop.weight || null,
        actualWeight: null,
        metadata: null,
        timeSeconds: null,
        distanceMeters: null,
        startedAt: null,
        completedAt: null,
        completedByUserId: null,
    }
}
