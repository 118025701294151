import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { L } from 'harmony-language';
import { useSnackbar } from '../../../../components/notifications/use-snackbar';
import { Load } from '../../../../types';
import { useLoadsKey } from '../../../config-hooks';

const addLoad = async (options: { load: Load }) => {
    const load = {
        ...options.load,
        date: options.load?.stops?.[0].arrivalTime
    };

    return (await axios.post(`${API_BASE}/api/orders`, load)).data;
}

export const useAddLoadMutation = () => {
    const snackbar = useSnackbar();
    const queryKey = useLoadsKey();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: addLoad,
        // do not mutate on add as we need the load id for selection/expanded state
        // onMutate: () => {},
        onSuccess: (data) => {
            // map organizationId to owningOrganizationId as web project only knows organizationId
            data.organizationId = data.owningOrganizationId;
            const previousCache = queryClient.getQueryData<Load[]>(queryKey);

            if (previousCache) {
                const newLoads = previousCache.concat(data);
                queryClient.setQueryData<Load[]>(queryKey, newLoads);

                snackbar(L.loadAddedSuccessfully());
            }
        },
        // onSettled: () => {
        //     queryClient.invalidateQueries(queryKey);
        // },
    });

    return mutation;
};
