import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { L } from 'harmony-language';
import { OnTimeReportColumns } from '../report-types';

export const useOnTimeColumns = (isAdmin: boolean) => {
    const columnHelper = createColumnHelper<OnTimeReportColumns>();

    const columns = React.useMemo(() => {
        const adminSettings = () => {
            return [
                columnHelper.accessor('organization_id', {
                    header: () => L.organizationId(),
                    cell: info => info.getValue(),
                }),
            ]
        }

        const adminColumns = isAdmin ? adminSettings() : [];
        const restColumns = [
            columnHelper.accessor('customer_name', {
                header: () => L.customer(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('origin_name', {
                header: () => L.origin(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('date_begin', {
                header: () => L.dateBegin(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('date_end', {
                header: () => L.dateEnd(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('sum_stops', {
                header: () => L.numberOfStops(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('on_time', {
                header: () => L.onTimeStops(),
                cell: info => info.getValue(),
            }),
            columnHelper.accessor('on_time_percent', {
                header: () => L.onTimePercent(),
                cell: info => {
                    const s = Number(info.getValue()).toLocaleString(undefined, { style: 'percent', minimumFractionDigits:2 });
                    return s;
                }
            }),
            columnHelper.accessor('undelivered', {
                header: () => L.undelivered(),
                cell: info => info.getValue()
            }),
            columnHelper.accessor('stop_type', {
                header: () => L.stopType(),
                cell: info => info.getValue(), // translated api side for cvs language support
            }),
            columnHelper.accessor('cargo_type_id', {
                header: () => L.cargoType(),
                cell: info => info.getValue(), // translated api side to utilize language keys
            }),
            columnHelper.accessor('cargo_category', {
                header: () => L.cargoCategory(),
                cell: info => info.getValue(), // translated api side for cvs language support
            }),
        ];

        return [...adminColumns, ...restColumns];
    }, []);

    return columns;
}
