import { Features } from 'harmony-constants';
import React from 'react';
import { OrgQueryKeys, QueryKeys } from '../../../../api/config';
import { useOrganizationQuery } from '../../../../api/queries/use-organization-query';
import { useStaticQuery } from '../../../../api/queries/use-static-query';
import { StandardRoute, type AvoidanceZone, type DeviceLocation, type OrganizationLocation, type TripEvent } from '../../../../types';
import { toSiteDisplayName } from '../../../../utils/data-mapping';
import { localTime } from '../../../../utils/date-time-utils';
import { type HereMapRefObject } from '../../../here-maps/here-map';
import { HereMapContext } from '../../../here-maps/here-map-context';
import { HereMapHeading } from '../../../here-maps/here-map-heading';
import { HereMapMarker } from '../../../here-maps/here-map-marker';
import { HereMapRectangle } from '../../../here-maps/here-map-rectangle';
import { HereMapRoute } from '../../../here-maps/here-map-route';
import { SublocationCircle } from '../../../here-maps/sublocation-circle';
import { getStopIcon } from '../../../live-map/markers/live-map-stop-icon';
import { translatedTypeString } from '../delivery-log/delivery-log-event-row';
import { MapPlaceholder } from './map-placeholder';
import { useOrgLocationColorProxy } from '../../../here-maps/use-org-location-color-proxy';
import { useDraftLoadContext } from '../../hooks/draft-load-context';
import { useFeatures } from '../../../user/selectors/use-permissions';
import { HereMapSpeed } from '../../../here-maps/here-map-speed';
import { AvoidanceZoneBox } from '../../../here-maps/avoidance-zone-box';
import { StandardRouteLines } from '../../../here-maps/standard-route-lines';

export const HereMapOrderContent = ({ mapRef, deviceLocations, trailerDeviceLocations, tripEvents }: {
    mapRef: React.RefObject<HereMapRefObject>;
    deviceLocations?: DeviceLocation[];
    trailerDeviceLocations?: DeviceLocation[];
    tripEvents?: TripEvent[];
}) => {
    const hasStandardRoutes = useFeatures(Features.StandardRoutes);
    const { data: organizationLocations, organizationId } = useOrganizationQuery<OrganizationLocation[]>(OrgQueryKeys.locations);
    const { data: avoidanceZones = [], isLoading: isLoadingAvoidanceZones } = useStaticQuery<AvoidanceZone[]>(QueryKeys.avoidanceZones);
    const { data: orgStandardRoutes = [] } = useOrganizationQuery<StandardRoute[]>(OrgQueryKeys.standardRoutes, { enabled: hasStandardRoutes });
    const [heading, setHeading] = React.useState<React.ReactNode>(null);
    const [trailerHeading, setTrailerHeading] = React.useState<React.ReactNode>(null);
    const [mappedDeviceLocations, setMappedDeviceLocations] = React.useState<(number | null)[][]>([]);
    const [mappedTrailerLocations, setMappedTrailerLocations] = React.useState<(number | null)[][]>([]);
    const [organizationSublocations, setOrganizationSublocations] = React.useState<React.ReactNode>(null);
    // const { load } = useLoadContext();
    const { editedLoad: load } = useDraftLoadContext();

    //Im unsure this is right, especially when dealing with carriers and when they see whos avoidance zones.
    //different screens need to show avoidance zones differently, especially when if/when logged in as a carrier
    let filteredAvoidanceZones = avoidanceZones.filter(x => !x.deactivatedAt);

    //Check to see if a carrier user is viewing this trip log/map and scope the avoidance zones down
    //just to the global zones, and the ones belonging to the org the load is owned by
    if (organizationId !== load.organizationId) {
        filteredAvoidanceZones = filteredAvoidanceZones.filter(x => x.organizationId === load.organizationId || x.organizationId === null);
    }

    const hereContext = React.useContext(HereMapContext);

    const stopLocations = load.stops
        .sortBy(stop => stop.sequence)
        .map(stop => {
            const orgLoc = organizationLocations?.find(l => l.id === stop.organizationLocationId);
            const organizationSublocations = orgLoc?.orgSubLocations;
            const customGeofence = orgLoc?.customGeofence;
            const tooltip = orgLoc ? toSiteDisplayName(orgLoc.name, orgLoc.description) : '';

            return {
                id: stop.id,
                organizationLocationId: orgLoc?.id || -1,
                customGeofence,
                sequence: stop.sequence,
                tooltip: tooltip,
                organizationSublocations: organizationSublocations,
            };
        })
        .filter(stop => stop.customGeofence?.latitude && stop.customGeofence?.longitude);

    const uniqueSitesFromStopLocations = [...new Map(stopLocations.map(x => [x.organizationLocationId, x])).values()];

    //https://developer.here.com/documentation/examples/maps-js/clustering/marker-clustering
    React.useEffect(() => {
        if (hereContext.map && tripEvents && organizationLocations) {
            //potential bug?
            //delivery log can be refreshed in the backgroud and if events are removed after fetch happens, they are removed from grid, but 'blue' dots are not removed from map.
            //currently it is not possiable to remove events from DB, but may need to remove the layer, if exists and add back
            const dataPoints = tripEvents.map(function (item) {
                /* eslint-disable-next-line */
                return new H.clustering.DataPoint(item.latitude, item.longitude, undefined, item);
            });
            const getBubbleContent = (eventData: TripEvent) => {
                return `<div class='bubble'><div>${localTime(eventData.timestamp)}</div><div>${translatedTypeString(eventData, organizationLocations, avoidanceZones)}</div></div>`;
            };

            const onMarkerClick = (e: H.util.ChangeEvent) => {
                // Get position of the "clicked" marker
                const position = e.target.getGeometry();
                // Get the data associated with that marker
                const data = e.target.getData();
                if (!data.isCluster()) {
                    const bubbleContent = getBubbleContent(data.getData());
                    // @ts-ignore
                    let bubble = onMarkerClick.bubble;

                    if (!bubble) {
                        bubble = new H.ui.InfoBubble(position, {
                            content: bubbleContent
                        });
                        hereContext.ui.addBubble(bubble);
                        // @ts-ignore
                        onMarkerClick.bubble = bubble;
                    } else {
                        bubble.setPosition(position);
                        bubble.setContent(bubbleContent);
                        bubble.open();
                    }

                    // Move map's center to a clicked marker
                    hereContext.map.setCenter(position, true);
                }
            };

            const clusteredDataProvider = new H.clustering.Provider(dataPoints, {
                clusteringOptions: {
                    // Maximum radius of the neighbourhood
                    eps: 4,
                    // minimum weight of points required to form a cluster
                    minWeight: 3
                },
                //theme: clusterTheme,
            });
            clusteredDataProvider.addEventListener('tap', onMarkerClick);

            const clusteringLayer = new H.map.layer.ObjectLayer(clusteredDataProvider);

            //2nd arg is index layer is inserted. We want this layer on top
            hereContext.map?.addLayer(clusteringLayer, 10);
        }
    }, [hereContext.map, tripEvents, organizationLocations]);

    React.useEffect(() => {
        const waypoints = deviceLocations?.map(location => [location.latitude, location.longitude, null]);
        setMappedDeviceLocations(waypoints || []);

        const heading = deviceLocations?.map((location, i) => {
            return (
                <>
                    <HereMapHeading key={`mobile${i}`} location={location} type={'mobile'} />
                    <HereMapSpeed key={`speed${i}`} location={location} />
                </>
            );
        });
        setHeading(heading);
    }, [deviceLocations]);

    React.useEffect(() => {
        const waypoints = trailerDeviceLocations?.map(location => [location.latitude, location.longitude, null]);
        setMappedTrailerLocations(waypoints || []);

        const heading = trailerDeviceLocations?.map((location, i) => {
            return (<HereMapHeading key={i} location={location} type={'trailer'} />);
        });
        setTrailerHeading(heading);
    }, [trailerDeviceLocations]);

    React.useEffect(() => {
        const circles = uniqueSitesFromStopLocations?.flatMap(x => x.organizationSublocations?.map((organizationSublocation, i) => {
            if (organizationSublocation?.customGeofence) {
                return (
                    <SublocationCircle
                        organizationSubLocation={organizationSublocation}
                        key={i} />
                )
            }
            return null;
        }));

        setOrganizationSublocations(circles);
    }, [load]);

    const zones = filteredAvoidanceZones?.map((x, i) =>
        <AvoidanceZoneBox key={i} avoidanceZone={x} />
    );

    // edit/create load and delivery log
    if (stopLocations.length >= 2 && !isLoadingAvoidanceZones) {
        const waypoints = stopLocations.map(stopLocation => ({
            ...stopLocation,
            latitude: stopLocation.customGeofence?.latitude,
            longitude: stopLocation.customGeofence?.longitude,
        }));

        const markers = waypoints.map((waypoint, i) => (
            <HereMapMarker key={i}
                icon={getStopIcon(waypoint.sequence)}
                latitude={waypoint.latitude}
                longitude={waypoint.longitude}/>
        ));

        const geofences = uniqueSitesFromStopLocations.map((x, i) => {
            if (x.customGeofence) {
                return (
                    <HereMapRectangle
                        key={i}
                        pointA={{ lat: x.customGeofence.bbox.topLeft.latitude, lng: x.customGeofence.bbox.topLeft.longitude }}
                        pointB={{ lat: x.customGeofence.bbox.bottomRight.latitude, lng: x.customGeofence.bbox.bottomRight.longitude }}
                        strokeColor={useOrgLocationColorProxy()[x.organizationLocationId].stroke}
                        fillColor={useOrgLocationColorProxy()[x.organizationLocationId].fill}
                        type='site'
                        changeable={false}
                    >
                        <div>
                            <span className='map-tooltip-label-name'>{x.tooltip}</span>
                        </div>
                    </HereMapRectangle>
                );
            }
        });

        const standardRoutes = waypoints.reduce((result: StandardRoute[], waypoint, i) => {
            if (!waypoints[i + 1]) return result;

            const nextWaypoint = waypoints[i +1];

            const matchingRoute = orgStandardRoutes.find(route => !route.deletedAt
                && route.originId === waypoint.organizationLocationId
                && route.destinationId === nextWaypoint.organizationLocationId);

            if (matchingRoute) result.push(matchingRoute);

            return result;
        }, []);

        return (
            <React.Fragment>
                {markers}
                {zones}
                {geofences}
                {organizationSublocations}
                {heading}
                {trailerHeading}
                <HereMapRoute calculateRouteFromWaypoints={true} waypoints={waypoints}
                    avoidanceZones={filteredAvoidanceZones}
                    onRouteDrawn={() => mapRef.current?.fitMapToAllObjects(true, undefined, false)}
                />
                {mappedDeviceLocations.length > 1 &&
                    <HereMapRoute calculateRouteFromWaypoints={false}
                        waypoints={mappedDeviceLocations}
                    />
                }
                {mappedTrailerLocations.length > 1 &&
                    <HereMapRoute calculateRouteFromWaypoints={false} green={true}
                        waypoints={mappedTrailerLocations}
                    />
                }
                {standardRoutes.map(route => 
                    <StandardRouteLines
                        key={route.id}
                        flexiblePolyline={route.flexiblePolyline}
                        onRouteDrawn={() => mapRef.current?.fitMapToAllObjects(true, undefined, false)}
                    />
                )}
            </React.Fragment>
        );
    // resource log - this is a mess, probably need to rework someday
    } else if (mappedDeviceLocations?.length > 0 && !isLoadingAvoidanceZones) {
        if (mappedDeviceLocations.length === 1) return <MapPlaceholder isResourceLog={true} />
        
        return (
            <>
                {zones}
                {heading}
                {trailerHeading}
                {mappedDeviceLocations.length > 1 &&
                    <HereMapRoute
                        calculateRouteFromWaypoints={false}
                        waypoints={mappedDeviceLocations}
                        onRouteDrawn={() => {
                            mapRef.current?.fitMapToAllObjects(true, undefined, false);
                        }}
                    />
                }
                {mappedTrailerLocations.length > 1 &&
                    <HereMapRoute
                        calculateRouteFromWaypoints={false}
                        green={true}
                        waypoints={mappedTrailerLocations}
                    />
                }
            </>
        );
    } else {
        return <MapPlaceholder/>;
    }
};
