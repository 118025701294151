import React, { FC, FormEvent } from 'react';
import { useDraftLoadContext } from '../../hooks/draft-load-context';
import { Load } from '../../../../types';
import { Row } from '@tanstack/react-table';
import { LoadTableRow } from './load-table-row';
import { LoadTableStops } from '../stops/load-table-stops';
import { useEditLoadMutation } from '../../../../api/mutations/reworked/edit/use-edit-load-mutation';

interface EditableLoadTableRowProps {
    isDrafts: boolean;
    showEstimatedTotalTripTime: boolean;
    showTractor: boolean;
    showTrailer: boolean;
    isRowExpanded: boolean;
    row: Row<Load>;
}

export const EditableLoadTableRow: FC<EditableLoadTableRowProps> = (props) => {
    const { isDrafts, showEstimatedTotalTripTime, showTractor, showTrailer, isRowExpanded, row } = props;
    const { editedLoad } = useDraftLoadContext();
    const { mutate: editLoad } = useEditLoadMutation(isDrafts);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        editLoad({ load: editedLoad });
    };

    return (
        <form onSubmit={handleSubmit}>
            <LoadTableRow
                isDrafts={isDrafts}
                showEstimatedTotalTripTime={showEstimatedTotalTripTime}
                showTractor={showTractor}
                showTrailer={showTrailer}
                row={row}
            />
            {isRowExpanded && (
                <LoadTableStops
                    isDrafts={isDrafts}
                    showInlineSaveButton={true}
                />
            )}
        </form>
    );
};
