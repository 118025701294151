import React, { useState } from 'react';
import { Stop } from '../../../../types';
import { stopGridTemplateColumnsGenerator } from '../../utils/drafts-loads-utils';
import { PerformanceExpander } from '../../drafts-loads-table-cells/performance-expander';
import { L } from 'harmony-language';
import { DisplayStopType } from '../../drafts-loads-table-cells/stops/display/display-stop-type';
import { DisplayStopLocation } from '../../drafts-loads-table-cells/stops/display/display-stop-location';
import { DisplayStopSublocations } from '../../drafts-loads-table-cells/stops/display/display-stop-sublocations';
import { DisplayStopTime } from '../../drafts-loads-table-cells/stops/display/display-stop-time';
import { DisplayStopQuantity } from '../../drafts-loads-table-cells/stops/display/display-stop-quantity';
import { DisplayStopWeight } from '../../drafts-loads-table-cells/stops/display/display-stop-weight';
import { DisplayStopCargoType } from '../../drafts-loads-table-cells/stops/display/display-stop-cargo-type';
import { DisplayStopText } from '../../drafts-loads-table-cells/stops/display/display-stop-text';
import { DisplayStopNotes } from '../../drafts-loads-table-cells/stops/display/display-stop-notes';
import { DisplayStopCompartments } from '../../drafts-loads-table-cells/stops/display/display-stop-compartments';
import { CargoTypeMode, cargoTypeModeProxyNullHandler } from './cargo-type-mode-proxy';
import { DisplayUnitSwitcher } from '../../drafts-loads-table-cells/stops/unit-switcher';

interface DisplayStopTableRowProps {
    isDrafts: boolean;
    stop: Stop;
    trailerId: number | null; // compartments needs this passed down
}

export const DisplayStopTableRow: React.FC<DisplayStopTableRowProps> = (props) => {
    const { isDrafts, stop, trailerId } = props;
    const [secondRowVis, setSecondRowVis] = useState(false);
    const [isQuantity, setIsQuantity] = useState(cargoTypeModeProxyNullHandler(stop.cargoTypeId) === CargoTypeMode.Quantity);

    const handleUnitsChange = () => {
        setIsQuantity(!isQuantity);
    }

    return (
        <>
            <div
                className='lt-row-stop lt-row-stop-item'
                style={{ gridTemplateColumns: stopGridTemplateColumnsGenerator(isDrafts, false) }}
            >
                <div className='lt-cell lt-cell-sub'><DisplayStopType stop={stop} /></div>
                <div className='lt-cell lt-cell-sub'><DisplayStopCargoType stop={stop} /></div>
                <div className='lt-cell lt-cell-sub'><DisplayStopLocation stop={stop} /></div>
                <div className='lt-cell lt-cell-sub'><DisplayStopSublocations stop={stop} /></div>

                <div className='lt-cell lt-cell-sub'><DisplayStopTime dateTime={stop.arrivalTime} /></div>
                {isDrafts ? null : <div className='lt-cell lt-cell-sub'><DisplayStopTime dateTime={stop.completedAt} /></div>}

                {isQuantity ? (
                    <>
                        <div className='lt-cell lt-cell-sub'><DisplayStopQuantity quantity={stop.quantity} /></div>
                        {isDrafts ? null : <div className='lt-cell lt-cell-sub'><DisplayStopQuantity quantity={stop.actualQuantity} /></div>}
                    </>
                ) : (
                    <>
                        <div className='lt-cell lt-cell-sub'><DisplayStopWeight weight={stop.weight} /></div>
                        {isDrafts ? null : <div className='lt-cell lt-cell-sub'><DisplayStopWeight weight={stop.actualWeight} /></div>}
                    </>
                )}

                <div className='lt-cell lt-cell-sub'>
                    <DisplayUnitSwitcher
                        stop={stop}
                        handleUnitsChange={handleUnitsChange}
                    />
                </div>

                <div className='lt-cell lt-cell-sub'><DisplayStopText text={stop.orderNumber} /></div>
                <div className='lt-cell lt-cell-sub'><DisplayStopText text={stop.productionPlan} /></div>

                <div className='lt-cell lt-cell-sub'><DisplayStopNotes stop={stop} /></div>

                <PerformanceExpander
                    title={stop.id.toString()}
                    expanded={secondRowVis}
                    onChange={() => {
                        setSecondRowVis(!secondRowVis);
                    }}
                />
            </div>
            {secondRowVis ? <>
                <div className='lt-row-stop-hidden lt-row-stop-header'>
                    <div>{L.readyTime()}</div>
                    <div>{L.compartments()}</div>
                    <div>{L.lotId()}</div>
                    <div>{L.requiredBegin()}</div>
                    <div>{L.requiredEnd()}</div>
                    <div>{L.customerOrderNumber()}</div>
                </div>
                <div className='lt-row-stop-hidden'>
                    <div className='lt-cell lt-cell-sub'><DisplayStopTime dateTime={stop.readyTime} /></div>
                    <div className='lt-cell lt-cell-sub'><DisplayStopCompartments stop={stop} trailerId={trailerId} /></div>
                    <div className='lt-cell lt-cell-sub'><DisplayStopText text={stop.lotId} /></div>
                    <div className='lt-cell lt-cell-sub'><DisplayStopTime dateTime={stop.requiredBegin} /></div>
                    <div className='lt-cell lt-cell-sub'><DisplayStopTime dateTime={stop.requiredEnd} /></div>
                    <div className='lt-cell lt-cell-sub'><DisplayStopText text={stop.customerOrderNumber} /></div>
                </div>
            </> : null}
        </>
    );
};
