import React, { FC } from 'react';
import { Load } from '../../../../../types';
import { getConvertedDistance } from '../../../../../utils/distance-utils';

interface ActualDistanceProps {
    load: Load;
}

export const ActualDistance: FC<ActualDistanceProps> = (props) => {
    const { load } = props;
    const { convertedDistance, abbreviation } = getConvertedDistance(load.distanceMeters);

    if (convertedDistance) {
        return <div>{convertedDistance} {abbreviation}</div>;
    } else {
        return <div />;
    }
};
