import { Autocomplete, TextField } from '@mui/material';
import { StopType } from 'harmony-constants';
import React from 'react';

export interface AgisticsItem {
    id: StopType;
    value: string;
}

interface AgisticsSelectStringProps {
    item: StopType;
    items: AgisticsItem[];
    disabled: boolean;
    onChange: (n: StopType) => void;
}

// the noneItem with id -1 and value being nullable is throwing TS off
// basically this is only for StopType select. To get styles right with the rest of the selects in the row
export const AgisticsSelectString: React.FC<AgisticsSelectStringProps> = (props) => {
    const { item, items, disabled, onChange } = props;
    const selectedValue = React.useMemo(() => items.find(x => x.id === item), [item]);

    return (
        <Autocomplete
            disableClearable={true}
            disabled={disabled}
            options={items}
            getOptionLabel={(option) => option.value}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={selectedValue}
            onChange={(event, value) => {
                if (event.type !== 'keydown' && value) {
                    onChange(value.id);
                }
            }}
            sx={{ input: { fontSize: '.875rem' }}}
            renderInput={(params) => {

                return (
                    <TextField
                        {...params}
                        variant='standard'
                    />
                );
            }}
            renderOption={(props, option) => {
                return (
                    <li
                        {...props}
                        key={option.id}
                    >
                        {option.value}
                    </li>
                )
            }}
        />
    );
};
