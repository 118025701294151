import { ColumnSort } from '@tanstack/react-table';
import React from 'react';
import { localNow } from '../../../utils/date-time-utils';
import { ReportBase } from '../report-base';
import { ReportFilters } from '../report-filters';
import { type DriverEfficiencyReportColumns, type ReportProps } from '../report-types';
import { getDriverEfficiencyUrlAndParams } from '../report-utils';
import { useReports } from '../use-reports';
import { useDriverEfficiencyColumns } from './driver-efficiency-columns';
import { DriverEfficiencyFilters } from './driver-efficiency-filters';

const defaultSort: ColumnSort = {
    id: 'loads',
    desc: true,
}

export const DriverEfficiencyReport: React.FC<ReportProps> = (props) => {
    const { isAdmin, companyId } = props;
    const [organizationId, setOrganizationId] = React.useState<number>(isAdmin ? 0 : companyId);
    const [startDate, setStartDate] = React.useState<string>(localNow().subtract(1, 'day').startOf('day').toISOString());
    const [endDate, setEndDate] = React.useState<string>(localNow().subtract(1, 'day').endOf('day').toISOString());
    const [driverIds, setDriverIds] = React.useState<number[]>([]);
    const [originSiteIds, setOriginSiteIds] = React.useState<number[]>([]);
    const [employmentTypeIds, setEmploymentTypeIds] = React.useState<number[]>([]);
    const columns = useDriverEfficiencyColumns(isAdmin);
    const { reportUrl, reportParams } = getDriverEfficiencyUrlAndParams(isAdmin, organizationId, startDate, endDate, driverIds, originSiteIds, employmentTypeIds);
    const { data, runReport, table, isLoading } = useReports<DriverEfficiencyReportColumns>(defaultSort, columns, reportUrl, reportParams, true);

    return (
        <>
            <ReportFilters
                isAdmin={isAdmin}
                organizationId={organizationId}
                setOrganizationId={setOrganizationId}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                runReport={runReport}>
                    <DriverEfficiencyFilters
                        isAdmin={isAdmin}
                        driverIds={driverIds}
                        setDriverIds={setDriverIds}
                        originSiteIds={originSiteIds}
                        setOriginSiteIds={setOriginSiteIds}
                        employmentType={employmentTypeIds}
                        setEmploymentType={setEmploymentTypeIds}
                    />
            </ReportFilters>
            <ReportBase
                table={table}
                hasData={Boolean(data)}
                isLoading={isLoading}
                runReport={runReport}
            />
        </>
    );
};
