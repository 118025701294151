import { CircularProgress, InputAdornment, TextField } from '@mui/material';
import { Column } from '@tanstack/react-table';
import React, { useEffect, useState } from 'react';
import { Load } from '../../../../types';
import { COMPLETED_AT_COLUMN, FIRST_STOP_COLUMN, LAST_STOP_COLUMN } from '../../hooks/use-draft-load-columns';
import { useDebounce } from '../../../shared/hooks/use-debounce';

interface TextFilterProps {
    column: Column<Load, unknown>;
}

export const TextFilter: React.FC<TextFilterProps> = (props) => {
    const { column } = props;
    const [search, setSearch] = useState('');
    const { debouncedValue, isDebouncing } = useDebounce(search);

    const isDate = column.id === FIRST_STOP_COLUMN || column.id === LAST_STOP_COLUMN || column.id === COMPLETED_AT_COLUMN;

    useEffect(() => {
        column.setFilterValue(debouncedValue);
    }, [debouncedValue]);

    // this is to catch when global table.resetColumnFilters is called
    useEffect(() => {
        if (column.getFilterValue() !== search) {
            setSearch('');
        }
    }, [column.getFilterValue()]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSearch(e.target.value);
    }

    return (
        <TextField
            variant='standard'
            // value={column.getFilterValue() || ''}
            value={search}
            onChange={handleChange}
            placeholder={isDate ? 'YYYY-MM-DD' : undefined}
            InputProps={{
                endAdornment: isDebouncing
                ? <InputAdornment position='end'><CircularProgress size={'1em'} /></InputAdornment>
                : undefined
                // : search
                // ? <InputAdornment position='end'>
                //     <IconButton size='small' onClick={() => setSearch('')}>
                //         <CloseIcon fontSize='inherit' />
                //     </IconButton>
                // </InputAdornment>
                // : undefined
            }}
        />
    );
};
