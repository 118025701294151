import React, { FC } from 'react';
import { Stop } from '../../../../../types';
import { IconButton } from '@mui/material';
import CallSplitOutlinedIcon from '@mui/icons-material/CallSplitOutlined';
import { useDraftLoadContext } from '../../../hooks/draft-load-context';
import { typedCreateStop } from '../../../utils/typed-create-stop';
import { orderToDefaultCargoType, orderToMissingStopQuantity, orderToMissingStopWeight } from '../../../../../utils/data-mapping-utils';
import { useOrganizationQuery } from '../../../../../api/queries/use-organization-query';
import { OrgQueryKeys } from '../../../../../api/config';
import { withThroughputs } from '../../../../../utils/throughput-utils';
import { stopHasCargo } from '../../../../../constants/constants';
import { useDraftLoadStopContext } from '../../../hooks/draft-load-stop-context';

interface EditableStopSplitProps {
    stop: Stop;
}

export const EditableStopSplit: FC<EditableStopSplitProps> = (props) => {
    const { stop } = props;
    const { editedLoad, onEditedLoadChanged } = useDraftLoadContext();
    const { isCarrier } = useDraftLoadStopContext();
    const { data: throughputs } = useOrganizationQuery(OrgQueryKeys.throughput);

    if (!stopHasCargo(stop)) {
        return null;
    }

    const handleSplitStop = () => {
        const nextSequence = stop.sequence + 1;

        const resequencedStops = editedLoad.stops.map(editedStop => {
            if (editedStop.sequence >= nextSequence) {
                return {
                    ...editedStop,
                    sequence: editedStop.sequence + 1,
                }
            }
            return editedStop;
        });

        const cargoTypeId = orderToDefaultCargoType({ stops: editedLoad.stops });
        const quantity = orderToMissingStopQuantity({ stops: editedLoad.stops });
        const weight = orderToMissingStopWeight({ stops: editedLoad.stops });

        const newStop = typedCreateStop(editedLoad.id, {
            sequence: nextSequence,
            type: stop.type,
            cargoTypeId: cargoTypeId,
            quantity: quantity,
            weight: weight,
            orderNumber: stop.orderNumber,
            productionPlan: stop.productionPlan,
            lotId: stop.lotId,
            splitId: stop.id,
            notes: stop.notes,
        });

        const newStops = [
            ...resequencedStops,
            newStop,
        ];

        const sortedStops = newStops.toSorted((a, b) => a.sequence - b.sequence);

        onEditedLoadChanged({
            ...editedLoad,
            stops: sortedStops.map(withThroughputs(throughputs)),
        });
    };

    return (
        <IconButton
            color='primary'
            disabled={!stop.id || !editedLoad.id || isCarrier}
            onClick={handleSplitStop}
            sx={{ padding: 0 }}
        >
            <CallSplitOutlinedIcon />
        </IconButton>
    )
};
