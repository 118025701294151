import { L } from 'harmony-language';
import { styled } from '@mui/material/styles';
import { StopIcon } from '../table-cells/stop/stop-icon';
import PropTypes from 'prop-types';
import React from 'react';
import { StopItem } from './stop-item';
import { getTransCargoTypeLabel } from '../../../../constants/constants';

const BaseDetailsTooltip = styled('div')({
    fontSize: '0.75rem',
});

const Row = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
});

const DetailsTooltipTitle = styled('div')({
    textAlign: 'center',
    padding: '0.1rem 0 0.1rem 0.2rem',
    borderBottom: '1px dotted #aaa',
    marginBottom: '0.2rem',
});

const CargoText = styled('div')({
    whiteSpace: 'nowrap'
});

export const DetailsTooltip = (props) => {
    const { draft, organizationLocations, basisStopId } = props;

    return (
        <BaseDetailsTooltip>
            <DetailsTooltipTitle>
                {`${L.load()} #${draft.id}`}
            </DetailsTooltipTitle>
            {draft.stops.map(stop => {
                return (
                    <Row key={stop.id}>
                        <StopItem
                            stop={stop}
                            icon={<StopIcon type={stop.type} fontSize={'inherit'}/>}
                            organizationLocations={organizationLocations}
                            isBasis={stop.id === basisStopId}/>
                        <CargoText>{getTransCargoTypeLabel(stop.cargoTypeId)}</CargoText>
                    </Row>
                );
            })}
        </BaseDetailsTooltip>
    );
};

DetailsTooltip.propTypes = {
    draft: PropTypes.object,
    organizationLocations: PropTypes.array,
    basisStopId: PropTypes.number,
};
