import React, { FC, useMemo } from 'react';
import { Load } from '../../../types';
import { DraftLoadProvider } from '../hooks/draft-load-context';
import { EditCreateLoadForm } from './edit-create-load-form';
import { typedCreateLoad } from '../utils/typed-create-load';
import { useCompanyId } from '../../../app/agistics-context';

interface EditCreateLoadProps {
    isDrafts: boolean;
    existingLoad: Load | null;
    onSubmit: () => void;
}

export const EditCreateLoad: FC<EditCreateLoadProps> = (props) => {
    const { isDrafts, existingLoad, onSubmit } = props;
    const companyId = useCompanyId();

    return (
        <DraftLoadProvider
            inputLoad={existingLoad ? existingLoad : typedCreateLoad(companyId)}
        >
            <EditCreateLoadForm isDrafts={isDrafts} onSubmit={onSubmit} />
        </DraftLoadProvider>
    );
};
