import { ColumnSort } from "@tanstack/react-table";
import React from "react";
import { localNow } from "../../../utils/date-time-utils";
import { ReportBase } from '../report-base';
import { ReportFilters } from "../report-filters";
import { type ReportProps, type SiteIssuesReportColumns } from "../report-types";
import { getSiteIssuesUrlAndParams } from "../report-utils";
import { useReports } from "../use-reports";
import { useSiteIssuesColumns } from "./site-issues-columns";
import { SiteIssuesFilters } from './site-issues-filters';

const defaultSort: ColumnSort = {
    id: 'created_at',
    desc: true
}

export const SiteIssuesReport: React.FC<ReportProps> = (props) => {
    const { companyId, isAdmin} = props;

    const [organizationId, setOrganizationId] = React.useState<number>(isAdmin ? 0 : companyId);
    const [startDate, setStartDate] = React.useState<string>(localNow().weekday(0).startOf('day').toISOString());
    const [endDate, setEndDate] = React.useState<string>(localNow().weekday(6).endOf('day').toISOString());
    const [siteIds, setSiteIds] = React.useState<number[]>([]);
    const [siteIssueTypeIds, setSiteIssueTypeIds] = React.useState<number[]>([]);
    const [isResolved, setIsResolved] = React.useState<number[]>([]);

    const columns = useSiteIssuesColumns(isAdmin);
    const { siteIssuesParams, siteIssuesUrl } = getSiteIssuesUrlAndParams(isAdmin, organizationId, startDate, endDate, siteIds, siteIssueTypeIds, isResolved);
    const { data, runReport, table, isLoading} = useReports<SiteIssuesReportColumns>(defaultSort, columns, siteIssuesUrl, siteIssuesParams, false);

    return (
        <>
            <ReportFilters
                isAdmin={isAdmin}
                organizationId={organizationId}
                setOrganizationId={setOrganizationId}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                runReport={runReport}
            >
                <SiteIssuesFilters
                    isAdmin={isAdmin}
                    siteIds={siteIds}
                    setSiteIds={setSiteIds}
                    siteIssueTypeIds={siteIssueTypeIds}
                    setSiteIssueTypeIds={setSiteIssueTypeIds}
                    isResolved={isResolved}
                    setIsResolved={setIsResolved}
                />
            </ReportFilters>
            <ReportBase
                table={table}
                hasData={Boolean(data)}
                isLoading={isLoading}
                runReport={runReport}
            />
        </>
    )
}