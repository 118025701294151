import { L } from 'harmony-language';
import React, { FC } from 'react';
import { stopGridTemplateColumnsGenerator } from '../../utils/drafts-loads-utils';
import { OrderNumberHeader } from './order-number-header';

interface StopsHeaderProps {
    isDrafts: boolean;
    isEditable: boolean;
}

export const StopsHeader: FC<StopsHeaderProps> = (props) => {
    const { isDrafts, isEditable } = props;

    return (
        <div
            className='lt-row-stop lt-row-stop-header'
            style={{ gridTemplateColumns: stopGridTemplateColumnsGenerator(isDrafts, isEditable) }}
        >
            {isEditable ? <div></div> : null}
            <div>{L.stopType()}</div>
            <div>{L.cargoType()}</div>
            <div>{L.location()}</div>
            <div>{L.subLocations()}</div>

            <div>{L.scheduled()}</div>
            {isEditable ? <div></div> : null}
            {isDrafts ? null : <div>{L.completed()}</div>}

            <div>{L.units()}</div>
            {isDrafts ? null : <div>{L.actualUnits()}</div>}

            <div></div>
            {isEditable ? <div></div> : null}

            {isEditable ? <OrderNumberHeader /> : <div>{L.orderNumber()}</div>}
            <div>{L.productionPlan()}</div>
            <div>{L.notes()}</div>
            <div />
            <div />
        </div>
    );
}
