import React from 'react';
import { Load } from '../../../../../types';
import { orderToCargoQuantities } from '../../../../../utils/data-mapping-utils';
import { AgisticsTooltip } from '../../../../shared/agistics-tooltip';

interface LoadCargoProps {
    load: Load;
    convertFromGramsDisplay: (g: number) => string;
}

export const LoadCargo: React.FC<LoadCargoProps> = (props) => {
    const { load, convertFromGramsDisplay } = props;
    const cargoQuantities = orderToCargoQuantities(load, convertFromGramsDisplay);

    return (
        <>
            {Object.entries(cargoQuantities).map(([cargo, quantity]) => {
                return (
                    <AgisticsTooltip
                        key={cargo}
                        tooltip={cargo}
                    >
                        {quantity ? `${quantity} - ${cargo}` : `${cargo}`}
                    </AgisticsTooltip>
                );
            })}
            {/* <SalmonellaMeter severity={load.metadata?.cargo?.salmonellaResult} /> */}
        </>
    );
};
