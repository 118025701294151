import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { L } from 'harmony-language';

const EtaThresholdEntry = ({ etaThresholdMinutes, defaultValue, translationPrefix, isLoading, onChange, error }) => {
    const [displayValue, setDisplayValue] = React.useState('');

    React.useEffect(() => {
        if (etaThresholdMinutes === undefined || etaThresholdMinutes === null) {
            setDisplayValue(defaultValue);
        } else {
            setDisplayValue(etaThresholdMinutes);
        }
    }, []);

    return (
        <Grid item xs={12} sm={6}>
            <Typography>{L[`${translationPrefix}ThresholdSettingLabel`]()}</Typography>
            <TextField
                variant='standard'
                fullWidth
                disabled={isLoading}
                id={`default-eta-threshold-minutes-${etaThresholdMinutes}`}
                label={L[`${translationPrefix}ThresholdSettingBaselineLabel`]()}
                type='number'
                value={displayValue}
                error={error}
                helperText={error && L.invalidThresholdNumber()}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                    style: {
                        marginTop: '1.7em',
                    },
                    inputProps: { min: 0, max: 600 },
                    endAdornment: <InputAdornment position='end'>{L.mins()}</InputAdornment>
                }}
                onChange={(e) => {
                    setDisplayValue(Number(e.target.value) || '');
                    onChange(Number(e.target.value));
                }} />
        </Grid>
    );
};

EtaThresholdEntry.propTypes = {
    isLoading: PropTypes.bool,
    defaultValue: PropTypes.number,
    translationPrefix: PropTypes.string,
    etaThresholdMinutes: PropTypes.number,
    onChange: PropTypes.func,
    error: PropTypes.bool,
}

export default EtaThresholdEntry;
