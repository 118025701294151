import React, { FC } from 'react';
import { localDateTimeDisplay } from '../../../../../utils/date-time-utils';

interface DisplayStopTimeProps {
    dateTime: string | null;
}

export const DisplayStopTime: FC<DisplayStopTimeProps> = (props) => {
    const { dateTime } = props;

    if (dateTime) {
        return localDateTimeDisplay(dateTime);
    }
};
