import React, { FC } from 'react';
import { useTravelTimesLoad } from '../../../../travel-times/use-travel-times';
import { getConvertedDistance } from '../../../../../utils/distance-utils';
import { Load } from '../../../../../types';

interface EstimatedDistanceProps {
    load: Load;
}

export const EstimatedDistance: FC<EstimatedDistanceProps> = (props) => {
    const { load } = props;
    const { travelDistance } = useTravelTimesLoad(load);
    const { convertedDistance, abbreviation } = getConvertedDistance(travelDistance);

    return (
        <div>{convertedDistance && `${convertedDistance} ${abbreviation}`}</div>
    );
};
