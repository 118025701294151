import { useEffect } from 'react';
import { useUser } from '../../../../../api/queries/use-user';
import { useQueryClient } from '@tanstack/react-query';
import { findUnknownPairsInCache, TravelTimesCache, updatePointlessPairs } from '../../../../travel-times/use-travel-times';
import { useDraftLoadContext } from '../../../hooks/draft-load-context';
import { useAddTravelTimes } from '../../../../../api/mutations/reworked/add/use-add-travel-times';
import { OrgQueryKeys } from '../../../../../api/config';
import { loadToOrganizationLocationIdPairs } from '../../../../../utils/data-mapping';
import { Stop } from '../../../../../types';

// this does not return anything, just moving logic out of
// editable-stop-location incase I figure out a better way to do this someday
export const useEditableStopLocationTravelTimes = (stop: Stop) => {
    const { editedLoad } = useDraftLoadContext();
    const { organizationId } = useUser();
    const queryKey = OrgQueryKeys.resolve(organizationId, OrgQueryKeys.locationTravelTimes);
    const queryClient = useQueryClient()
    const travelTimesCache = queryClient.getQueryData<TravelTimesCache>(([queryKey]));
    const { mutate: addTravelTimes } = useAddTravelTimes(organizationId);

    useEffect(() => {
        if (travelTimesCache) {
            const p = loadToOrganizationLocationIdPairs(editedLoad);
            const unknowPairs = findUnknownPairsInCache(travelTimesCache, p);
            const filteredUnknowPairs = updatePointlessPairs(unknowPairs, travelTimesCache, queryClient, queryKey);

            if (filteredUnknowPairs.length) {
                addTravelTimes(unknowPairs);
            }
        }
    }, [stop.organizationLocationId]);
}
