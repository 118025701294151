import React from 'react';
import { Stop } from '../../../../../types';
import { STOP_TYPES } from '../../../../../constants/constants';
import { useDraftLoadStopContext } from '../../../hooks/draft-load-stop-context';
import { AgisticsSelectString } from '../../../../shared/agistics-select-string';
import { StopType } from 'harmony-constants';

interface EditableStopTypeProps {
    stop: Stop;
}

export const EditableStopType: React.FC<EditableStopTypeProps> = (props) => {
    const { stop } = props;
    const { onEditedStopChanged, isCarrier, isComplete } = useDraftLoadStopContext();

    const stopTypes = React.useMemo(() => Object.values(STOP_TYPES()).map(type => ({
        id: type.key,
        value: type.label,
    })), []);

    return (
        <AgisticsSelectString
            item={stop.type}
            items={stopTypes}
            disabled={isCarrier || isComplete}
            onChange={(stopType) => {
                if (stopType === StopType.Origin || stopType === StopType.Destination) {
                    onEditedStopChanged({ type: stopType });
                } else {
                    onEditedStopChanged({
                        type: stopType,
                        cargoTypeId: null,
                    });
                }
            }}
        />
    );
};
