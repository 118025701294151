import React from 'react';
import { Load, OrganizationCarrier } from '../../../../../types';
import { ORDER_STATUSES } from '../../../../../constants/constants';

interface LoadStatusProps {
    load: Load;
    carriers: OrganizationCarrier[];
}

export const LoadStatus: React.FC<LoadStatusProps> = (props) => {
    const { load, carriers } = props;
    const isCarrier = load.organizationId !== load.transportingOrganizationId;

    if (isCarrier) {
        const carrierName = carriers.find(x => x.carrierOrganizationId === load.transportingOrganizationId)?.carrier?.name;
        return <div title={carrierName} style={{ color: '#29a84a', fontWeight: 'bold' }}>{ORDER_STATUSES()[load.status].label}</div>
    } else {
        return ORDER_STATUSES()[load.status].label;
    }
};
