import React from 'react';
import Grid from '@mui/material/Grid';
import { AgisticsCard } from '../../../shared/containers/agistics-card';
import { L } from 'harmony-language';
import { useMergeDrafts } from './use-merge-drafts';
import LoadingButton from '../../../shared/loading-button';
import Save from '@mui/icons-material/Save';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import { Driver, Load, Stop } from '../../../../types';
import { DraftLoadProvider, useDraftLoadContext } from '../../hooks/draft-load-context';
import { useOrganizationQuery } from '../../../../api/queries/use-organization-query';
import { OrgQueryKeys } from '../../../../api/config';
import { useLoadEditableItems } from '../../hooks/use-load-editable-items';
import { useMergeDraftsMutation } from '../../../../api/mutations/reworked/use-merge-drafts-mutation';
import { STOP_TYPES } from '../../../../constants/constants';
import { withThroughputs } from '../../../../utils/throughput-utils';
import { LoadStopTime } from '../../drafts-loads-table-cells/loads/display/load-stop-time';
import { EstimatedTotalTripTime } from '../../drafts-loads-table-cells/loads/display/estimated-total-trip-time';
import { EditableLoadDriver } from '../../drafts-loads-table-cells/loads/editable/editable-load-driver';
import { EditableLoadTractor } from '../../drafts-loads-table-cells/loads/editable/editable-load-tractor';
import { EditableLoadTrailer } from '../../drafts-loads-table-cells/loads/editable/editable-load-trailer';
import { LoadTableStops } from '../../table-components/stops/load-table-stops';

interface MergeDraftsProps {
    drafts: Load[];
    onSubmit: () => void;
}

export const MergeDrafts: React.FC<MergeDraftsProps> = (props) => {
    const { drafts, onSubmit } = props;
    const mergedDrafts = useMergeDrafts(drafts);

    return (
        <DraftLoadProvider inputLoad={mergedDrafts}>
            <MergingDraftsForm previousDrafts={drafts} onSubmit={onSubmit} />
        </DraftLoadProvider>
    );
};

interface MergingDraftsFormProps {
    previousDrafts: Load[];
    onSubmit: () => void;
}

const MergingDraftsForm: React.FC<MergingDraftsFormProps> = (props) => {
    const { previousDrafts, onSubmit } = props;
    const { data: drivers = [] } = useOrganizationQuery<Driver[]>(OrgQueryKeys.drivers);
    const loadsEditableItems = useLoadEditableItems();
    const { mutate: mergeDrafts, isLoading: isMergingDraft } = useMergeDraftsMutation();
    const { data: throughputs } = useOrganizationQuery(OrgQueryKeys.throughput);
    const { editedLoad, onEditedLoadChanged } = useDraftLoadContext();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        mergeDrafts({ draft: editedLoad, previousDrafts: previousDrafts });
        onSubmit();
    };

    const handleReverseDestinations = React.useCallback(() => {
        const destinationKey = STOP_TYPES().Destination.key;
        const reorderedStops = [...editedLoad.stops];

        const isDestinationArray = reorderedStops.map((s) => {
            if (s.type !== destinationKey) {
                return false;
            } else {
                return true;
            }
        });

        const newStops: Stop[] = [];
        let takenCareOfIndex = 0;
        isDestinationArray.map((isDestination, i) => {
            if (!isDestination) {
                newStops.push(reorderedStops[i]);
            } else {
                if (i >= takenCareOfIndex) {
                    const nextNonDestinationIndex = isDestinationArray.indexOf(false, i);

                    // goto end
                    if (nextNonDestinationIndex === -1) {
                        const destChunk = reorderedStops.slice(i, reorderedStops.length);
                        const reversed = Array.from(destChunk).reverse();
                        newStops.push(...reversed);
                        takenCareOfIndex = reorderedStops.length;
                    } else {
                        const destChunk = reorderedStops.slice(i, nextNonDestinationIndex);
                        const reversed = Array.from(destChunk).reverse();
                        newStops.push(...reversed);
                        takenCareOfIndex = nextNonDestinationIndex;
                    }
                }
            }
        });

        const sequencedNewStops = newStops.map((x, i) => ({ ...x, sequence: i + 1 }));

        onEditedLoadChanged({
            ...editedLoad,
            stops: sequencedNewStops.map(withThroughputs(throughputs)),
        });
    }, [editedLoad.stops]);

    return (
        <div style={{ width: '90vw', fontSize: '0.875rem' }} data-testid='merge-drafts'>
            <form onSubmit={handleSubmit} id='merge-drafts-form' name='merge-drafts-form'>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <AgisticsCard title={L.mergedDraftPreview()} paddingSmall>
                            <div
                                style={{
                                    display: 'grid',
                                    gridTemplateColumns: 'repeat(4, 1fr)',
                                    gridColumnGap: '1rem',
                                }}
                            >
                                <div>
                                    <div style={{ display: 'flex', columnGap: '.3rem' }}>
                                        <span>{L.date()}:</span>
                                        <div><LoadStopTime time={editedLoad.date} /></div>
                                    </div>
                                    <div style={{ display: 'flex', columnGap: '.3rem' }}>
                                        <span>{L.estimatedTotalTripTime()}:</span>
                                        <EstimatedTotalTripTime load={editedLoad} />
                                    </div>
                                </div>
                                <EditableLoadDriver
                                    drivers={drivers}
                                    getDriverProperties={loadsEditableItems.getDriverProperties}
                                />
                                <EditableLoadTractor
                                    getTractorProperties={loadsEditableItems.getTractorProperties}
                                />
                                <EditableLoadTrailer
                                    getTrailerProperties={loadsEditableItems.getTrailerProperties}
                                />
                            </div>
                            <LoadTableStops isDrafts={true} />
                        </AgisticsCard>
                    </Grid>
                </Grid>
                <div
                    style={{
                        paddingTop: '1rem',
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <LoadingButton
                        isLoading={isMergingDraft}
                        icon={<FlipCameraAndroidIcon/>}
                        onClick={handleReverseDestinations}
                        color='primary'
                        variant='contained'
                        style={{ marginRight: '1rem' }}
                    >
                        {L.reverseDestinations()}
                    </LoadingButton>
                    <LoadingButton
                        isLoading={isMergingDraft}
                        icon={<Save/>}
                        type='submit'
                        color='primary'
                        variant='contained'
                    >
                        {L.mergeDrafts()}
                    </LoadingButton>
                </div>
            </form>
        </div>
    );
};
