import React, { FC } from 'react';
import { useDraftLoadContext } from '../../hooks/draft-load-context';
import { EditableStopTableRow } from './editable-stop-table-row';
import { EditableStopAdd } from '../../drafts-loads-table-cells/stops/editable/editable-stop-add';
import { closestCenter, DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { DraftsLoadsSaveButton } from '../../drafts-loads-table-cells/drafts-loads-save-button';

interface EditableLoadStopsProps {
    isDrafts: boolean;
    showInlineSaveButton: boolean;
}

export const EditableLoadStops: FC<EditableLoadStopsProps> = (props) => {
    const { isDrafts, showInlineSaveButton } = props;
    const { editedLoad, onEditedLoadChanged } = useDraftLoadContext();

    const handleOnDragEnd = (event: DragEndEvent) => {
        const reorderedStops = [...editedLoad.stops];
        if (!event.over || typeof event.active.id === 'string' || typeof event.over.id === 'string') {
            return reorderedStops;
        }

        const dragIndex = event.active.id - 1;
        const hoverIndex = event.over.id - 1;
        const dragStop = editedLoad.stops[dragIndex];

        const updatedStops = reorderedStops.resortValue(dragIndex, hoverIndex, dragStop).map((stop, i) => ({
            ...stop,
            sequence: i + 1,
        }));

        onEditedLoadChanged({
            ...editedLoad,
            stops: updatedStops,
        });
    }

    return (
        <>
            <DndContext
                onDragEnd={handleOnDragEnd}
                modifiers={[restrictToVerticalAxis, restrictToParentElement]}
                collisionDetection={closestCenter}
            >
                <SortableContext
                    items={editedLoad.stops.map(x => x.sequence)}
                    strategy={verticalListSortingStrategy}
                >
                    {editedLoad.stops.map((stop, i) => {
                        return (
                            <EditableStopTableRow
                                // inline edit add/split has no stopId
                                key={stop.id || i}
                                isDrafts={isDrafts}
                                stop={stop}
                                trailerId={editedLoad.trailerId}
                                index={i}
                            />
                        )
                    })}
                </SortableContext>
            </DndContext>
            <div
                className='lt-row-stop-item'
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: showInlineSaveButton ? '.5rem 1rem .5rem 0' : undefined,
                }}
            >
                <EditableStopAdd />
                {showInlineSaveButton && <DraftsLoadsSaveButton />}
            </div>
        </>
    )
}
