import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { L } from 'harmony-language';
import { useSnackbar } from '../../../components/notifications/use-snackbar';
import { Load } from '../../../types';
import { useDraftsKey } from '../../config-hooks';

async function modifyDrafts(options: { drafts: Load[] }) {
    const drafts = options.drafts;

    return (await axios.put(`${API_BASE}/api/orders`, drafts)).data;
}

export const useDraftsMutation = () => {
    const snackbar = useSnackbar();
    const queryKey = useDraftsKey();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: modifyDrafts,
        onMutate: ({ drafts }: { drafts: Load[] }) => {
            queryClient.cancelQueries(queryKey);

            const previousCache = queryClient.getQueryData<Load[]>(queryKey);

            if (previousCache) {
                const draftIds = drafts.map(d => d.id);
                const newDrafts = previousCache.map((existingDraft) => {
                    if (draftIds.includes(existingDraft.id)) {
                        return {
                            ...existingDraft,
                            ...drafts.find(d => d.id === existingDraft.id),
                        };
                    }
                    return existingDraft;
                });

                queryClient.setQueryData(queryKey, newDrafts);
            }

            return previousCache;
        },
        onError: (_1, _2, context) => {
            if (context) {
                queryClient.setQueryData<Load[]>(queryKey, context);
            }
        },
        onSuccess: (data) => {
            snackbar(L.draftsUpdateSuccessful(data.length));
        },
        // onSettled: () => {
        //     queryClient.invalidateQueries(queryKey);
        // },
    });
};
