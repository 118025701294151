import React, { createContext, FC, ReactNode, useContext, useMemo } from 'react';
import { Stop } from '../../../types';
import { useDraftLoadContext } from './draft-load-context';
import { withThroughputs } from '../../../utils/throughput-utils';
import { useOrganizationQuery } from '../../../api/queries/use-organization-query';
import { OrgQueryKeys } from '../../../api/config';
import { calculateStopArrivalTimes } from '../../../utils/data-mapping-utils';
import { useTravelTimesLoad } from '../../travel-times/use-travel-times';
import { getNewLoadDate } from '../utils/drafts-loads-utils';
import { useCarrier } from '../../user/selectors/use-permissions';
import { StopStatus } from 'harmony-constants';

interface DraftLoadStopContext {
    onEditedStopChanged: (value: Partial<Stop>, loadDate?: string) => void;
    atuoCalculateStopTimes: (s: Stop) => void;
    orderNumberEditable: boolean;
    isCarrier: boolean;
    isComplete: boolean;
}

const DraftLoadStopContext = createContext<DraftLoadStopContext | undefined>(undefined);

interface DraftLoadStopProviderProps {
    inputStop: Stop;
    children: ReactNode;
}

export const DraftLoadStopProvider: FC<DraftLoadStopProviderProps> = (props) => {
    const { inputStop, children } = props;
    const { editedLoad, onEditedLoadChanged } = useDraftLoadContext();
    const { data: throughputs } = useOrganizationQuery(OrgQueryKeys.throughput);
    const { travelTimesCache } = useTravelTimesLoad(editedLoad);
    const orderNumberEditable = useMemo(() => !inputStop.orderNumber, []);
    const isCarrier = useCarrier();
    const isComplete = inputStop.status === StopStatus.Complete;

    const onEditedStopChanged = (value: Partial<Stop>, loadDate?: string) => {
        const newLoad = {
            ...editedLoad,
            ...(loadDate ? { date: loadDate } : {}),
            stops: editedLoad.stops.map(stop => {
                if (stop.sequence === inputStop.sequence) {
                    return {
                        ...stop,
                        ...value,
                    }
                } else {
                    return stop;
                }
            }).map(withThroughputs(throughputs)),
        }

        onEditedLoadChanged(newLoad);
    }

    const atuoCalculateStopTimes = (stop: Stop) => {
        const newStops = calculateStopArrivalTimes(editedLoad.stops.map(withThroughputs(throughputs)), stop.sequence, travelTimesCache);
        const loadDate = getNewLoadDate(newStops, null) || editedLoad.date;

        const newLoad = {
            ...editedLoad,
            date: loadDate,
            stops: newStops,
        }

        onEditedLoadChanged(newLoad);
    };

    return (
        <DraftLoadStopContext.Provider
            value={{
                onEditedStopChanged: onEditedStopChanged,
                atuoCalculateStopTimes: atuoCalculateStopTimes,
                orderNumberEditable: orderNumberEditable,
                isCarrier: isCarrier,
                isComplete: isComplete,
            }}
        >
            {children}
        </DraftLoadStopContext.Provider>
    );
}

export const useDraftLoadStopContext = () => {
    const draftLoadStopContext = useContext(DraftLoadStopContext);

    if (draftLoadStopContext === undefined) {
        throw new Error('useDraftLoadStopContext must be used within a DraftLoadStopProvider');
    }

    return draftLoadStopContext;
}
