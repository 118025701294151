import React, { FC } from 'react';
import { Icon } from '@mui/material';
import { L } from 'harmony-language';
import { useCargoTypes } from '../../../api/queries/use-cargo-types';
import { orderCargoEligibleForCompartments } from '../../../utils/data-mapping-utils';
import { useDraftLoadContext } from '../../drafts-loads/hooks/draft-load-context';

export const CompartmentsHeader: FC = () => {
    const { editedLoad, compartmentLinkingEnabled, setCompartmentLinkingEnabled } = useDraftLoadContext();
    const { data: cargoTypes, isLoading: isLoadingCargoTypes } = useCargoTypes();

    if (isLoadingCargoTypes) {
        return null;
    }

    const cargoEligibleForCompartments = orderCargoEligibleForCompartments(cargoTypes, editedLoad);

    return (
        <>
            {L.compartments()}
            {cargoEligibleForCompartments &&
                <span
                    style={{ marginLeft: '0.3rem', color: '#555' }}
                    onClick={() => {
                        setCompartmentLinkingEnabled(!compartmentLinkingEnabled);
                    }}
                >
                    <Icon style={{ fontSize: '1rem', cursor: 'pointer' }} title={L.toggleCompartmentLinking()}>
                        {compartmentLinkingEnabled ? 'link' : 'link_off'}
                    </Icon>
                </span>
            }
        </>
    );
}
