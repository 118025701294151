import React from 'react';
import { connectSocket } from './connect-socket';
import { useUser } from '../../api/queries/use-user';
import { useAgisticsContext } from '../../app/agistics-context';
import { getSocket } from '../../services/socket-factory';

export const SocketListener = () => {
    const { user: self } = useUser();
    const { addSocketStopId } = useAgisticsContext();

    React.useEffect(() => {
        if (self) {
            connectSocket(addSocketStopId);
        }

        return () => {
            getSocket().disconnect();
        };
    }, [self]);

    return null;
};
