import { DraggableAttributes } from '@dnd-kit/core';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import React, { FC } from 'react';

// These probably only need to be used when an icon is needed to be displayed 100+ at a time.
// When that is not needed use normal MUI icon
// I tried to start converting some of the stops icons to this, but ran into 'flashing' when expanding the row
// related to https://v5.mui.com/material-ui/icons/#font-vs-svgs-which-approach-to-use
// MUI uses svg these use font

// There is a difference between ICONS and SYMBOLS we use ICONS
// https://fonts.google.com/icons?icon.set=Material+Icons

interface PerformanceIcon {
    icon: string;
}

export const PerformanceIcon: FC<PerformanceIcon> = (props) => {
    const { icon } = props;

    return (
        <span
            className='material-icons lt-button-color size-16'
            style={{ cursor: 'default' }}
        >
            {icon}
        </span>
    );
}

interface PerformanceDragIcon {
    isDragging: boolean;
    listeners?: SyntheticListenerMap;
    attributes: DraggableAttributes;
    isCarrier: boolean;
}

export const PerformanceDragIcon: FC<PerformanceDragIcon> = (props) => {
    const { isDragging, listeners, attributes, isCarrier } = props;

    const colorClass = isCarrier ? 'lt-button-disabled' : 'lt-button-color';

    return (
        <span
            className={`material-icons ${colorClass} size-24`}
            style={{ cursor: isDragging ? 'grabbing' : isCarrier ? 'default' : 'grab' }}
            {...listeners}
            {...attributes}
        >
            drag_handle
        </span>
    );
}

interface PerformanceIconButtonProps {
    icon: string;
    onClick: () => void;
    disabled?: boolean;
    size?: 'small';
    color?: 'primary' | 'default';
}

export const PerformanceIconButton: FC<PerformanceIconButtonProps> = (props) => {
    const { icon, onClick, disabled = false, size, color = 'primary' } = props;

    const sizeClass = size === 'small' ? 'size-20' : 'size-24';
    const colorClass = color === 'primary' ? 'lt-button-color' : 'lt-button-default';

    return (
        <button
            type='button'
            className={`lt-button ${!disabled && `cursor-pointer ${colorClass}`}`}
            onClick={onClick}
            disabled={disabled}
        >
            <span className={`material-icons ${sizeClass}`}>{icon}</span>
        </button>
    );
};
