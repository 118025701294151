import React, { FC } from 'react';
import { ResourceScheduling } from './resource-scheduling';
import { L } from 'harmony-language';
import { ModalDialog } from '../../../shared/containers/modal-dialog';
import { PerformanceIconButton } from '../../drafts-loads-table-cells/performance-icons';
import { Load } from '../../../../types';
import { ConditionalResourceScheduling } from './conditional-resource-scheduling';
import { LoadEditableItems } from '../../hooks/use-load-editable-items';

interface ResourceSchedulingButtonProps {
    loadEditableItems: LoadEditableItems;
    load?: Load;
}

export const ResourceSchedulingButton: FC<ResourceSchedulingButtonProps> = (props) => {
    const { loadEditableItems, load } = props;
    const [modalOpen, setModalOpen] = React.useState(false);

    const onClose = () => {
        setModalOpen(false);
    }

    return (
        <>
            <PerformanceIconButton
                icon='person_search'
                onClick={() => setModalOpen(true)}
                size='small'
                color='default'
            />
            <ModalDialog
                title={L.resourceScheduling()}
                onClose={onClose}
                open={modalOpen}
            >
                {load
                    ? <ConditionalResourceScheduling
                        loadEditableItems={loadEditableItems}
                        load={load}
                        onClose={onClose}
                    />
                    : <ResourceScheduling loadEditableItems={loadEditableItems} />
                }
            </ModalDialog>
        </>
    );
};
