import React, { FC, useMemo } from 'react';
import { OrganizationLocation, Stop } from '../../../../../types';
import { useOrganizationQuery } from '../../../../../api/queries/use-organization-query';
import { OrgQueryKeys } from '../../../../../api/config';
import { toSiteDisplayNameFromOrganizationLocation } from '../../../../../utils/data-mapping';

interface DisplayStopLocationProps {
    stop: Stop;
}

export const DisplayStopLocation: FC<DisplayStopLocationProps> = (props) => {
    const { stop } = props;
    const { data: organizationLocations = [] } = useOrganizationQuery<OrganizationLocation[]>(OrgQueryKeys.locations);

    const stopLocationName = useMemo(() => {
        const organizationLocation = organizationLocations.find(x => x.id === stop.organizationLocationId);
        if (organizationLocation) {
            return toSiteDisplayNameFromOrganizationLocation(organizationLocation);
        }
    }, [stop]);

    return <span title={stopLocationName}>{stopLocationName}</span>;
};
