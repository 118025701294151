import { Row } from '@tanstack/react-table'
import { Load } from '../../../../types'
import { useWeights } from '../../../shared/hooks/use-weights';
import { filterStopByDestination } from '../../../../utils/data-mapping-utils';
import { Comparison, ComparisonFilter } from './comparison-filter';
import { searchByDriver, searchByLoadId, searchByOrderNumber, searchByStopCargo, searchByStopCustomerOrderNumber, searchByStopDates, searchByStopLoadingTeam, searchByStopLocation, searchByTractor, searchByTrailer } from '../../../shared/table/utils/search-functions';
import { useTractorTrailer } from '../../../../api/queries/use-tractor-trailer';
import { useOrganizationQuery } from '../../../../api/queries/use-organization-query';
import { OrgQueryKeys } from '../../../../api/config';

export interface GlobalFilterValue {
    comparison?: ComparisonFilter;
    // careful - this is actually an array organizationLocationIds not customerIds
    // like this because we only have access to the load in the global filter function and it's faster
    // update - the access part used to be true, but have reworked it since and not true anymore
    customer?: number[];
    arbitraryText?: string;
}

export const useLoadsGlobalFilter = () => {
    const { convertFromGrams } = useWeights();
    const { tractors, trailers , isLoadingTractors, isLoadingTrailers } = useTractorTrailer();
    // const { data: loadingTeams, isLoading: isLoadingLoadingTeams } = useOrganizationQuery(OrgQueryKeys.loadingTeams);
    const { data: organizationLocations, isLoading: isLoadingLocations } = useOrganizationQuery(OrgQueryKeys.locations);
    const { data: drivers, isLoading: isLoadingDrivers } = useOrganizationQuery(OrgQueryKeys.drivers);
    const isLoading = isLoadingTractors || isLoadingTrailers || isLoadingLocations || isLoadingDrivers;

    return (row: Row<Load>, _: string, filterValue: GlobalFilterValue) => {
        const load = row.original;

        const comparisonResult = () => {
            if (filterValue.comparison) {
                const filter = filterValue.comparison;
                const operator = filter.category;
                const destinationsWithWeight = load.stops.filter(stop => filterStopByDestination(stop) && stop.weight !== null);

                if (destinationsWithWeight.length === 0) {
                    return false;
                } else {
                    const loadDestinationWeightTotal = destinationsWithWeight
                        .map(stop => stop.weight || 0)
                        .reduce((acc, stopWeight) => acc + stopWeight);

                    const totalWeightForCompare = convertFromGrams(loadDestinationWeightTotal);

                    if (operator === Comparison.LessThan) {
                        return filter.value > totalWeightForCompare;
                    } else if (operator === Comparison.LessThanEqual) {
                        return filter.value >= totalWeightForCompare;
                    } else if (operator === Comparison.MoreThan) {
                        return filter.value < totalWeightForCompare;
                    } else if (operator === Comparison.MoreThanEqual) {
                        return filter.value <= totalWeightForCompare;
                    } else if (operator === Comparison.Equal) {
                        return filter.value === totalWeightForCompare;
                    }

                    // this is impossible, but TS does not like an undef
                    return true;
                }
            } else {
                return true;
            }
        }

        const customerResult = () => {
            if (filterValue.customer) {
                const filter = filterValue.customer;
                const has = load.stops.some(x => filter.includes(x.organizationLocationId));
                return has;
            } else {
                return true;
            }
        }

        const arbitraryTextResult = () => {
            if (filterValue.arbitraryText && !isLoading) {
                const filter = filterValue.arbitraryText;

                // keep short circuit return for better performance
                // also order of searches by what is 'most likely' to be searched for
                return searchByOrderNumber(load, filter) ||
                    searchByStopLocation(load, filter, organizationLocations) ||
                    searchByDriver(load, filter, drivers) ||
                    searchByStopCargo(load, filter) ||
                    searchByStopCustomerOrderNumber(load, filter) ||
                    searchByStopDates(load, filter) ||
                    searchByTrailer(load, filter, trailers) ||
                    searchByTractor(load, filter, tractors) ||
                    // searchByStopLoadingTeam(load, filter, loadingTeams) ||
                    searchByLoadId(load, filter);
            } else {
                return true;
            }
        }

        return comparisonResult() && customerResult() && arbitraryTextResult();
    }
}
