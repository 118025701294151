import Button from '@mui/material/Button';
import { useQueryClient } from '@tanstack/react-query';
import { CustomerTier, Features, PermissionType } from 'harmony-constants';
import { L } from 'harmony-language';
import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../api/queries/use-user';
import { useAgisticsContext } from '../../app/agistics-context';
import '../../scss/loading-spinner.scss';
import { Navigation } from '../navigation/navigation';
import { CircularLoading } from '../shared/circular-loading';
import { SocketListener } from '../socket-listener/socket-listener';
import { userSignOut } from '../user/actions/user-sign-out';
import { validateSession } from '../user/actions/validate-session';
import { useCarrierAccess, useEquipmentEditable, useFeatures, useHasTier, usePermissions } from '../user/selectors/use-permissions';

const carrierSometimesFunc = (carrierSometimes: string, equipmentEditable: { canEditTractors: boolean, canEditTrailers: boolean }) => {
    if (carrierSometimes === '/manage-company/tractors') {
        return equipmentEditable.canEditTractors;
    } else {
        return equipmentEditable.canEditTrailers;
    }
}

interface AuthenticatedRouteProps {
    Component: ReactElement;
    tier?: CustomerTier;
    feature?: Features;
    permission?: PermissionType | PermissionType[];
    carrier: boolean;
    carrierSometimes?: string;
}

export const AuthenticatedRoute: React.FC<AuthenticatedRouteProps> = (props) => {
    const { tier, feature, permission, carrier, carrierSometimes, Component } = props;
    const [isAuthenticated, setIsAuthenticated] = React.useState(false);
    const { isLoading } = useUser(isAuthenticated);
    const { updateCompanyId } = useAgisticsContext();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const hasTier = useHasTier(tier || CustomerTier.Standard);
    const hasFeature = useFeatures(feature);
    const hasPermission = usePermissions(permission);
    const carrierAccess = useCarrierAccess(carrier);
    const equipmentEditable = useEquipmentEditable();
    const carrierLogic = carrierSometimes ? carrierSometimesFunc(carrierSometimes, equipmentEditable) : carrierAccess;

    const canAccessRoute = hasFeature && hasPermission && carrierLogic;

    React.useEffect(() => {
        const func = async () => {
            const valid = await validateSession(updateCompanyId);
            if (valid) {
                setIsAuthenticated(true);
            } else {
                userSignOut(queryClient, navigate);
            }
        }
        func();
    }, []);

    if (!isAuthenticated || isLoading) {
        return (
            <div>
                <CircularLoading />
            </div>
        )
    }

    if (hasTier) {
        if (canAccessRoute) {
            return (
                <>
                    <div className='agistics-app'>
                        <Navigation />
                        <SocketListener />
                        <div className='agistics-content'>
                            {Component}
                        </div>
                    </div>
                </>
            )
        } else {
            return (
                <div style={{ padding: '1rem ' }}>
                    <h3>{L.unauthorized()}</h3>
                    <p>{L.unauthorizedMsg()}</p>
                    <Button variant='contained' color='primary' onClick={() => navigate(-1)}>{L.back()}</Button>
                </div>
            );
        }
    } else {
        // TODO: language updates, better screen
        return (
            <div style={{ padding: '1rem ' }}>
                <div>This is a premium feature! Talk to your administrator to upgrade.</div>
                <Button variant='contained' color='primary' onClick={() => navigate(-1)}>{L.back()}</Button>
            </div>
        );
    }
};
