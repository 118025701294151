import { ColumnSort } from '@tanstack/react-table';
import React from 'react';
import { localNow } from '../../../utils/date-time-utils';
import { ReportBase } from '../report-base';
import { ReportFilters } from '../report-filters';
import { type ReportProps, type SplitStopReportColumns } from '../report-types';
import { getSplitStopUrlAndParams } from '../report-utils';
import { useReports } from '../use-reports';
import { useSplitStopColumns } from './split-stop-columns';
import { SplitStopFilters } from './split-stop-filters';

const defaultSort: ColumnSort = {
    id: 'order_date',
    desc: true,
}

export const SplitStopReport: React.FC<ReportProps> = (props) => {
    const { isAdmin, companyId } = props;
    const [organizationId, setOrganizationId] = React.useState<number>(isAdmin ? 0 : companyId);
    const [startDate, setStartDate] = React.useState<string>(localNow().subtract(1, 'day').startOf('day').toISOString());
    const [endDate, setEndDate] = React.useState<string>(localNow().subtract(1, 'day').endOf('day').toISOString());
    const [locationIds, setLocationIds] = React.useState<number[]>([]);
    const columns = useSplitStopColumns(isAdmin);
    const { reportUrl, reportParams } = getSplitStopUrlAndParams(isAdmin, organizationId, startDate, endDate, locationIds);
    const { data, runReport, table, isLoading } = useReports<SplitStopReportColumns>(defaultSort, columns, reportUrl, reportParams, true);

    return (
        <>
            <ReportFilters
                isAdmin={isAdmin}
                organizationId={organizationId}
                setOrganizationId={setOrganizationId}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                runReport={runReport}>
                    <SplitStopFilters
                        isAdmin={isAdmin}
                        locationIds={locationIds}
                        setLocationIds={setLocationIds}
                    />
            </ReportFilters>
            <ReportBase
                table={table}
                hasData={Boolean(data)}
                isLoading={isLoading}
                runReport={runReport}
            />
        </>
    );
};
