import React from 'react';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import '../../../../../scss/bulk-creation.scss';
import { WeekOf } from './week-of';
import PropTypes from 'prop-types';
import { LoadSize } from './load-size';
import { L } from 'harmony-language';
import { SelectCargoType } from '../../../../shared/inputs/select-cargo-type';
import { SelectLocationType } from '../../../../shared/inputs/select-location-type';
import { SITE_CATEGORIES } from '../../../../../constants/constants';
import { FormControlLabel, Switch } from '@mui/material';
import { LoadWeight } from './load-weight';

export const BulkConfiguration = (props) => {
    const { bulkConfiguration, setBulkConfiguration } = props;

    const [week, setWeek] = React.useState(bulkConfiguration.week || '');
    const [siteType, setSiteType] = React.useState(bulkConfiguration.siteType || {});
    const [cargoType, setCargoType] = React.useState(bulkConfiguration.cargoType || {});
    const [loadSize, setLoadSize] = React.useState(bulkConfiguration.loadSize || null);
    const [loadWeightGrams, setLoadWeightGrams] = React.useState(bulkConfiguration.loadWeightGrams || null);
    const [loadWeightDisplay, setLoadWeightDisplay] = React.useState(bulkConfiguration.loadWeightDisplay || '');
    const [checked, setChecked] = React.useState(bulkConfiguration.checked || false);

    const nextTab = (event) => {
        event.preventDefault();
        setBulkConfiguration({ week, siteType, cargoType, loadSize, checked, loadWeightGrams, loadWeightDisplay });
    };

    const handleSwitchChanged = () => {
        setSiteType({});
        setChecked(!checked);
    };

    const sizeWeightValidation = React.useCallback(() => {
        return !loadSize && !loadWeightGrams ? false : true;
    }, [loadSize, loadWeightGrams]);

    return (
        <div className='bulk-configuration' data-testid='bulk-configuration'>
            <h2>{L.bulkConfigHeader()}</h2>
            <Paper>
                <form onSubmit={(event) => nextTab(event, false)}>
                    <div className='bulk-title-bar justify-between'>
                        <h4>{L.bulkConfigTitle()}</h4>
                        <div className='bulk-title-bar-action'>
                            <Button className='action-button' type='submit' variant='contained'
                                color='primary'>{L.startEnterLoads()}</Button>
                        </div>
                    </div>
                    <div className='bulk-config-inputs'>
                        <div style={{ position: 'relative', top: '10px' }}>
                            <FormControlLabel
                                control={<Switch
                                    checked={checked}
                                    onChange={() => handleSwitchChanged()}
                                />}
                                label={L.changeStopType()}
                                labelPlacement='start'
                            />
                        </div>
                        <span style={{ width: '15rem' }}><WeekOf setWeek={setWeek} week={week}/></span>
                        <SelectCargoType cargoType={cargoType}
                            onChange={(newCargoType) => {
                                setCargoType(newCargoType);
                            }}
                            inputProps={{
                                'aria-label': L.bulkSelectCargo()
                            }}
                            label={L.bulkSelectCargo()}
                            required
                            style={{ minWidth: '8rem' }}/>
                        <SelectLocationType locationType={siteType}
                            onChange={setSiteType}
                            filters={{
                                category: SITE_CATEGORIES.SITE,
                                cargoTypeId: cargoType?.id,
                                isBasedOnOrigin: checked,
                            }}
                            label={checked ? L.bulkSelectOrigin() : L.bulkSelectDestination()}
                            required
                            style={{ minWidth: '10rem' }}/>
                        <LoadSize loadSize={loadSize} setLoadSize={setLoadSize} sizeWeightValidation={sizeWeightValidation} />
                        <LoadWeight
                            loadWeightGrams={loadWeightGrams}
                            setLoadWeightGrams={setLoadWeightGrams}
                            setLoadWeightDisplay={setLoadWeightDisplay}
                            sizeWeightValidation={sizeWeightValidation} />
                    </div>
                </form>
            </Paper>
        </div>
    );
};

BulkConfiguration.propTypes = {
    bulkConfiguration: PropTypes.object,
    setBulkConfiguration: PropTypes.func,
    siteTypes: PropTypes.array,
    cargoTypes: PropTypes.array,
};
