import React, { useMemo } from 'react';
import { createColumnHelper, Row } from '@tanstack/react-table'
import { CargoType, Driver, Load, OrderStatus as OrderStatusFromTypes, OrganizationCarrier, OrganizationLocation, Tractor, Trailer } from '../../../types';
import { L } from 'harmony-language';
import { sortByDriver, sortByStatus, sortByStop, sortByStopCompletedTime, sortByStopTime, sortByTractor, sortByTrailer } from '../../shared/table/utils/sorting-functions';
import { useOrganizationQuery } from '../../../api/queries/use-organization-query';
import { OrgQueryKeys } from '../../../api/config';
import { filterStopByDestination, filterStopByOrigin, loadToFirstStop, loadToLastStop, orderToLastCompleted, toDriverDisplayName, toTractorDisplayName, toTrailerDisplayName } from '../../../utils/data-mapping-utils';
import { PerformanceIconButton } from '../drafts-loads-table-cells/performance-icons';
import { useTractorTrailer } from '../../../api/queries/use-tractor-trailer';
import { LoadOrderNumbers } from '../drafts-loads-table-cells/loads/display/load-order-numbers';
import { LoadStopLocation } from '../drafts-loads-table-cells/loads/display/load-stop-location';
import { LoadStopTime } from '../drafts-loads-table-cells/loads/display/load-stop-time';
import { ORDER_STATUSES } from '../../../constants/constants';
import { useWeights } from '../../shared/hooks/use-weights';
import { PerformanceCheckbox } from '../drafts-loads-table-cells/performance-checkbox';
import { PerformanceExpander } from '../drafts-loads-table-cells/performance-expander';
import { Features, OrderStatus, StopType } from 'harmony-constants';
import { useFeatures } from '../../user/selectors/use-permissions';
import { doesValueMatch } from '../../shared/table/utils/search-functions';
import { DraftConflictIcon } from '../drafts-loads-table-cells/draft-conflict-icon';
import { anyColumnFiltered, genDestinationIds, genOrderNumbers, genOriginIds, getShowEstimatedTotalTripTime, getShowTractor, getShowTrailer, isLoadRowEditable } from '../utils/drafts-loads-utils';
import { useUser } from '../../../api/queries/use-user';
import { MultiFilter } from '../table-components/filters/multi-filter';
import { toSiteDisplayNameFromOrganizationLocation } from '../../../utils/data-mapping';
import { LoadTableHeaderCell } from '../table-components/load-table-header-cell';
import { useCargoTypes } from '../../../api/queries/use-cargo-types';
import { TextFilter } from '../table-components/filters/text-filter';
import { useLoadEditableItems } from './use-load-editable-items';
import { useDraftLoadContext } from './draft-load-context';
import { LoadCargo } from '../drafts-loads-table-cells/loads/display/load-cargo';
import { LoadStatus } from '../drafts-loads-table-cells/loads/display/load-status';
import { EditableLoadDriver } from '../drafts-loads-table-cells/loads/editable/editable-load-driver';
import { EditableLoadTractor } from '../drafts-loads-table-cells/loads/editable/editable-load-tractor';
import { EditableLoadTrailer } from '../drafts-loads-table-cells/loads/editable/editable-load-trailer';
import { EstimatedTotalTripTime } from '../drafts-loads-table-cells/loads/display/estimated-total-trip-time';
import { ResourceSchedulingButton } from '../drafts/scheduling/resource-scheduling-button';
import { useDraftsLoadsContext } from '../drafts-loads-context';
import { AgisticsTooltip } from '../../shared/agistics-tooltip';

export interface OriginDestinationFilterValue {
    value: number[];
    sublocationId: number | undefined;
}

// columnIds - I'm pretty sure if no id is set, fallback is header, which is potentially translated...
// these are also the id for presets in userPreferences
const ACTIONS_COLUMN = 'actions_column'
const ORDER_NUMBER_COLUMN = 'order_number_column';
export const CARGO_COLUMN = 'cargo_column';
export const ORIGIN_COLUMN = 'origin_column';
export const DESTINATION_COLUMN = 'destination_column';
export const FIRST_STOP_COLUMN = 'first_stop_column';
export const LAST_STOP_COLUMN = 'last_stop_column';
export const COMPLETED_AT_COLUMN = 'completed_at_column';
const TRIP_TIME_COLUMN = 'trip_time_column';
const RESOURCE_SCHEDULING_COLUMN = 'resource_scheduling_column';
export const DRIVER_COLUMN = 'driver_column';
export const TRACTOR_COLUMN = 'tractor_column';
export const TRAILER_COLUMN = 'trailer_column';
export const STATUS_COLUMN = 'status_column';
const EXPANDER_COLUMN = 'expander_column';

export const useDraftLoadColumns = (
    isDrafts: boolean,
    handleEditableLoadOpen: (l: Load) => void,
    handleDeliveryLogOpen: (l: Load) => void,
    handleUnmergeOpen: (l: Load) => void,
    applyDefaultPreset: boolean,
    setApplyDefaultPreset: (b: boolean) => void,
) => {
    const columnHelper = createColumnHelper<Load>();
    const { data: organizationLocations = [], isLoading: isLoadingLocations } = useOrganizationQuery<OrganizationLocation[]>(OrgQueryKeys.locations);
    const { data: drivers = [], isLoading: isLoadingDrivers } = useOrganizationQuery<Driver[]>(OrgQueryKeys.drivers);
    const { data: carriers = [] } = useOrganizationQuery<OrganizationCarrier[]>(OrgQueryKeys.carriers);
    const { tractors = [], trailers = [], isLoadingTractors, isLoadingTrailers } = useTractorTrailer();
    const isLoadingColumns = isLoadingTractors || isLoadingTrailers || isLoadingLocations || isLoadingDrivers;
    const { cargoTypeList } = useCargoTypes();
    const orderStatuses = Object.values(ORDER_STATUSES());
    const { convertFromGramsDisplay } = useWeights();
    const hasUnmergeFeature = useFeatures(Features.UnmergeDrafts);
    const { user } = useUser();
    const showEstimatedTotalTripTime = getShowEstimatedTotalTripTime(user.preferences);
    const showTractor = getShowTractor(user.preferences);
    const showTrailer = getShowTrailer(user.preferences);

    const loadEditableItems = useLoadEditableItems();

    const columns = useMemo(() => {
        const c = [
            columnHelper.display({
                id: ACTIONS_COLUMN,
                header: (headerContext) => {
                    const { table } = headerContext;
                    const anyColFiltered = anyColumnFiltered(table);

                    return (
                        <div style={{ textAlign: 'left' }}>
                            <PerformanceCheckbox
                                // checked={table.getIsAllRowsSelected()}
                                checked={table.getIsAllPageRowsSelected()}
                                // indeterminate={table.getIsSomeRowsSelected()}
                                indeterminate={table.getIsSomePageRowsSelected()}
                                // onChange={table.getToggleAllRowsSelectedHandler()}
                                onChange={table.getToggleAllPageRowsSelectedHandler()}
                            />
                            <PerformanceIconButton
                                icon='filter_list_off'
                                disabled={!anyColFiltered}
                                onClick={() => {
                                    table.resetColumnFilters(true);
                                }}
                            />
                        </div>
                    )
                },
                cell: (info) => {
                    const isEditDisabled = info.row.original.status === OrderStatus.Delivered;
                    // all sorts of problems when inline edit/modal edit
                    // for now just not going to allow it until I have an idea of what should happen
                    const isRowEditable = isLoadRowEditable(info.row.getIsExpanded(), info.row.original);
                    const load = info.row.original;
                    const canUnmerge = hasUnmergeFeature && Boolean(load.mergedFromOrderIds) && load.isDraft;

                    return (
                        <>
                            <PerformanceCheckbox
                                checked={info.row.getIsSelected()}
                                onChange={info.row.getToggleSelectedHandler()}
                            />
                            <PerformanceIconButton
                                icon='edit'
                                disabled={isEditDisabled || isRowEditable}
                                onClick={() => {
                                    handleEditableLoadOpen(load);
                                }}
                            />
                            {!isDrafts && <PerformanceIconButton
                                icon='public'
                                onClick={() => {
                                    handleDeliveryLogOpen(load);
                                }}
                            />}
                            {canUnmerge && <PerformanceIconButton
                                icon='undo'
                                onClick={() => {
                                    handleUnmergeOpen(load);
                                }}
                            />}
                        </>
                    );
                },
                enableGlobalFilter: false,
            }),
            columnHelper.accessor((row) => {
                const orderNumbers = genOrderNumbers(row);
                return orderNumbers;
            }, {
                id: ORDER_NUMBER_COLUMN,
                header: (headerContext) => {
                    const { header } = headerContext;

                    return (
                        <>
                            <LoadTableHeaderCell
                                header={header}
                                label={L.orderNumber()}
                            />
                            <TextFilter column={header.column} />
                        </>
                    );
                },
                cell: (info) => {
                    const isRowEditable = isLoadRowEditable(info.row.getIsExpanded(), info.row.original);

                    if (isRowEditable) {
                        const { editedLoad } = useDraftLoadContext();

                        return (
                            <LoadOrderNumbers load={editedLoad} />
                        );
                    } else {
                        const load = info.row.original;

                        return (
                            <LoadOrderNumbers load={load} />
                        );
                    }
                },
                enableSorting: false,
                enableGlobalFilter: false,
                filterFn: (row: Row<Load>, columnId: string, filterValue: string) => {
                    const rowOrderNums = row.getValue<string[]>(columnId);
                    const someOrderNums = rowOrderNums.some(x => doesValueMatch(x, filterValue));
                    const loadIdMatch = doesValueMatch(row.original.id, filterValue);
                    return someOrderNums || loadIdMatch;
                },
            }),
            columnHelper.accessor((row) => {
                const cargoTypeIds = row.stops.map(x => x.cargoTypeId).filter(x => x !== null);
                const uniqueCargoTypeIds = [...new Set(cargoTypeIds)];
                return uniqueCargoTypeIds;
            }, {
                id: CARGO_COLUMN,
                header: (headerContext) => {
                    const { header } = headerContext;

                    return (
                        <>
                            <LoadTableHeaderCell
                                header={header}
                                label={L.cargo()}
                            />
                            <MultiFilter
                                column={header.column}
                                baseItems={cargoTypeList}
                                displayFunc={(c: CargoType) => {
                                    return c.label;
                                }}
                                applyDefaultPreset={applyDefaultPreset}
                            />
                        </>
                    );
                },
                cell: (info) => {
                    const isRowEditable = isLoadRowEditable(info.row.getIsExpanded(), info.row.original);

                    if (isRowEditable) {
                        const { editedLoad } = useDraftLoadContext();
                        return <LoadCargo load={editedLoad} convertFromGramsDisplay={convertFromGramsDisplay} />;
                    } else {
                        return <LoadCargo load={info.row.original} convertFromGramsDisplay={convertFromGramsDisplay} />;
                    }
                },
                enableSorting: false,
                enableGlobalFilter: false,
                filterFn: (row: Row<Load>, columnId: string, filterValue: number[]) => {
                    const rowCargoTypeIds = row.getValue(columnId) as number[];
                    return rowCargoTypeIds.some(r => filterValue.includes(r));
                },
            }),
            columnHelper.accessor((row) => {
                const originIds = genOriginIds(row);
                return originIds;
            }, {
                id: ORIGIN_COLUMN,
                header: (headerContext) => {
                    const { header } = headerContext;

                    return (
                        <>
                            <LoadTableHeaderCell
                                header={header}
                                label={L.origin()}
                            />
                            <MultiFilter
                                column={header.column}
                                baseItems={organizationLocations}
                                displayFunc={toSiteDisplayNameFromOrganizationLocation}
                                applyDefaultPreset={applyDefaultPreset}
                                setApplyDefaultPreset={setApplyDefaultPreset}
                            />
                        </>
                    );
                },
                cell: (info) => {
                    const isRowEditable = isLoadRowEditable(info.row.getIsExpanded(), info.row.original);

                    if (isRowEditable) {
                        const { editedLoad } = useDraftLoadContext();
                        const originIds = genOriginIds(editedLoad);
                        const uniqueOriginIds = [...new Set(originIds)];
                        const origins = editedLoad.stops.filter(filterStopByOrigin);

                        return uniqueOriginIds.map(uniqueOriginId => {
                            return (
                                <LoadStopLocation
                                    key={uniqueOriginId}
                                    organizationLocationId={uniqueOriginId}
                                    organizationLocations={organizationLocations}
                                    stops={origins}
                                />
                            );
                        });
                    } else {
                        const originIds = info.getValue();
                        const uniqueOriginIds = [...new Set(originIds)];
                        const origins = info.row.original.stops.filter(filterStopByOrigin);

                        return uniqueOriginIds.map(uniqueOriginId => {
                            return (
                                <LoadStopLocation
                                    key={uniqueOriginId}
                                    organizationLocationId={uniqueOriginId}
                                    organizationLocations={organizationLocations}
                                    stops={origins}
                                />
                            );
                        });
                    }
                },
                sortingFn: (rowA, rowB) => {
                    return sortByStop(organizationLocations, 'Origin')(rowA.original, rowB.original);
                },
                enableGlobalFilter: false,
                filterFn: (row: Row<Load>, _: string, filterValue: OriginDestinationFilterValue) => {
                    const load = row.original;
                    if (filterValue.sublocationId) {
                        const sub = filterValue.sublocationId;
                        return load.stops.some(stop => stop.type === StopType.Origin && stop.organizationSubLocationIds?.includes(sub));
                    }
                    return load.stops.some(stop => stop.type === StopType.Origin && filterValue.value.includes(stop.organizationLocationId));
                },
            }),
            columnHelper.accessor((row) => {
                const destinationIds = genDestinationIds(row);
                return destinationIds;
            }, {
                id: DESTINATION_COLUMN,
                header: (headerContext) => {
                    const { header } = headerContext;

                    return (
                        <>
                            <LoadTableHeaderCell
                                header={header}
                                label={L.destination()}
                            />
                            <MultiFilter
                                column={header.column}
                                baseItems={organizationLocations}
                                displayFunc={toSiteDisplayNameFromOrganizationLocation}
                                applyDefaultPreset={applyDefaultPreset}
                            />
                        </>
                    );
                },
                cell: (info) => {
                    const isRowEditable = isLoadRowEditable(info.row.getIsExpanded(), info.row.original);

                    if (isRowEditable) {
                        const { editedLoad } = useDraftLoadContext();
                        const destinationIds = genDestinationIds(editedLoad);
                        const uniqueDestinationIds = [...new Set(destinationIds)];
                        const destinations = editedLoad.stops.filter(filterStopByDestination);

                        return uniqueDestinationIds.map(uniqueDestinationId => {
                            return (
                                <LoadStopLocation
                                    key={uniqueDestinationId}
                                    organizationLocationId={uniqueDestinationId}
                                    organizationLocations={organizationLocations}
                                    stops={destinations}
                                />
                            );
                        });
                    } else {
                        const destinationIds = info.getValue();
                        const uniqueDestinationIds = [...new Set(destinationIds)];
                        const destinations = info.row.original.stops.filter(filterStopByDestination);

                        return uniqueDestinationIds.map(uniqueDestinationId => {
                            return (
                                <LoadStopLocation
                                    key={uniqueDestinationId}
                                    organizationLocationId={uniqueDestinationId}
                                    organizationLocations={organizationLocations}
                                    stops={destinations}
                                />
                            );
                        });
                    }
                },
                sortingFn: (rowA, rowB) => {
                    return sortByStop(organizationLocations, 'Destination')(rowA.original, rowB.original);
                },
                enableGlobalFilter: false,
                filterFn: (row: Row<Load>, _: string, filterValue: OriginDestinationFilterValue) => {
                    const load = row.original;
                    if (filterValue.sublocationId) {
                        const sub = filterValue.sublocationId;
                        return load.stops.some(stop => stop.type === StopType.Destination && stop.organizationSubLocationIds?.includes(sub));
                    }
                    return load.stops.some(stop => stop.type === StopType.Destination && filterValue.value.includes(stop.organizationLocationId));
                },
            }),
            columnHelper.accessor((row) => {
                const firstStop = loadToFirstStop(row);
                return firstStop.arrivalTime as string | null;
            }, {
                id: FIRST_STOP_COLUMN,
                header: (headerContext) => {
                    const { header } = headerContext;

                    return (
                        <>
                            <LoadTableHeaderCell
                                header={header}
                                label={L.firstStop()}
                            />
                            <TextFilter column={header.column} />
                        </>
                    );
                },
                cell: (info) => {
                    const isRowEditable = isLoadRowEditable(info.row.getIsExpanded(), info.row.original);

                    if (isRowEditable) {
                        const { editedLoad } = useDraftLoadContext();
                        const firstStop = loadToFirstStop(editedLoad);

                        return <LoadStopTime time={firstStop.arrivalTime} />;
                    } else {
                        return <LoadStopTime time={info.getValue()} />;
                    }
                },
                sortingFn: (rowA, rowB) => {
                    return sortByStopTime(loadToFirstStop)(rowA.original, rowB.original);
                },
                enableGlobalFilter: false,
                filterFn: 'includesString',
            }),
            columnHelper.accessor((row) => {
                const lastStop = loadToLastStop(row);
                return lastStop.arrivalTime as string | null;
            }, {
                id: LAST_STOP_COLUMN,
                header: (headerContext) => {
                    const { header } = headerContext;

                    return (
                        <>
                            <LoadTableHeaderCell
                                header={header}
                                label={L.lastStop()}
                            />
                            <TextFilter column={header.column} />
                        </>
                    );
                },
                cell: (info) => {
                    const isRowEditable = isLoadRowEditable(info.row.getIsExpanded(), info.row.original);

                    if (isRowEditable) {
                        const { editedLoad } = useDraftLoadContext();
                        const lastStop = loadToLastStop(editedLoad);

                        return <LoadStopTime time={lastStop.arrivalTime} />;
                    } else {
                        return <LoadStopTime time={info.getValue()} />;
                    }
                },
                sortingFn: (rowA, rowB) => {
                    return sortByStopTime(loadToLastStop)(rowA.original, rowB.original);
                },
                enableGlobalFilter: false,
                filterFn: 'includesString',
            }),
            ...(!isDrafts ? [columnHelper.accessor((row) => {
                const lastCompletedStop = orderToLastCompleted(row);
                if (lastCompletedStop) {
                    return lastCompletedStop.completedAt as string | null;
                }
                return null;
            }, {
                id: COMPLETED_AT_COLUMN,
                header: (headerContext) => {
                    const { header } = headerContext;

                    return (
                        <>
                            <LoadTableHeaderCell
                                header={header}
                                label={L.completed()}
                            />
                            <TextFilter column={header.column} />
                        </>
                    );
                },
                cell: (info) => {
                    return <LoadStopTime time={info.getValue()} />;
                },
                sortingFn: (rowA, rowB) => {
                    return sortByStopCompletedTime(orderToLastCompleted)(rowA.original, rowB.original);
                },
                enableGlobalFilter: false,
                filterFn: 'includesString',
            })] : []),
            ...(showEstimatedTotalTripTime ? [columnHelper.display({
                id: TRIP_TIME_COLUMN,
                header: (headerContext) => {
                    const { header } = headerContext;

                    return (
                        <LoadTableHeaderCell
                            header={header}
                            label={L.estimatedTotalTripTime()}
                        />
                    );
                },
                cell: (info) => {
                    const isRowEditable = isLoadRowEditable(info.row.getIsExpanded(), info.row.original);

                    if (isRowEditable) {
                        const { editedLoad } = useDraftLoadContext();

                        return (
                            <EstimatedTotalTripTime
                                load={editedLoad}
                                style={{ display: 'flex', justifyContent: 'center' }}
                            />
                        );
                    } else {
                        return (
                            <EstimatedTotalTripTime
                                load={info.row.original}
                                style={{ display: 'flex', justifyContent: 'center' }}
                            />
                        );
                    }
                },
                enableGlobalFilter: false,
            })] : []),
            columnHelper.display({
                id: RESOURCE_SCHEDULING_COLUMN,
                header: () => null,
                cell: (info) => {
                    if (info.row.original.status === OrderStatus.Delivered) {
                        return null;
                    }

                    const isRowEditable = isLoadRowEditable(info.row.getIsExpanded(), info.row.original);

                    if (isRowEditable) {
                        return (
                            <ResourceSchedulingButton loadEditableItems={loadEditableItems} />
                        );
                    } else {
                        return (
                            <ResourceSchedulingButton
                                loadEditableItems={loadEditableItems}
                                load={info.row.original}
                            />
                        );
                    }
                },
                enableGlobalFilter: false,
            }),
            columnHelper.accessor('transportedByUserId', {
                id: DRIVER_COLUMN,
                header: (headerContext) => {
                    const { header } = headerContext;

                    return (
                        <>
                            <LoadTableHeaderCell
                                header={header}
                                label={L.driver()}
                            />
                            <MultiFilter
                                column={header.column}
                                baseItems={drivers}
                                displayFunc={(d: Driver) => {
                                    return d.name;
                                }}
                                applyDefaultPreset={applyDefaultPreset}
                            />
                        </>
                    );
                },
                cell: (info) => {
                    const isRowExpanded = info.row.getIsExpanded();
                    const isRowEditable = isLoadRowEditable(isRowExpanded, info.row.original);

                    if (isRowEditable) {
                        return (
                            <EditableLoadDriver
                                drivers={drivers}
                                getDriverProperties={loadEditableItems.getDriverProperties}
                                showLabel={false}
                                customPopper={true}
                            />
                        );
                    }

                    const driverDisplayName = toDriverDisplayName(drivers)(info.row.original);

                    if (!showTractor || !showTrailer) {
                        const tractorDisplayName = toTractorDisplayName(tractors)(info.row.original);
                        const trailerDisplayName = toTrailerDisplayName(trailers)(info.row.original);

                        const tooltip = <div>
                            <div>{L.tractor()}: {tractorDisplayName}</div>
                            <div>{L.trailer()}: {trailerDisplayName}</div>
                        </div>;

                        return (
                            <AgisticsTooltip
                                tooltip={tooltip}
                            >
                                {driverDisplayName}
                            </AgisticsTooltip>
                        )
                    }

                    return driverDisplayName;
                },
                sortingFn: (rowA, rowB) => {
                    return sortByDriver(drivers)(rowA.original, rowB.original);
                },
                enableGlobalFilter: false,
                filterFn: (row: Row<Load>, columnId: string, filterValue: number[]) => {
                    const rowDriverId = row.getValue(columnId) as number | null;
                    return rowDriverId ? filterValue.includes(rowDriverId) : false;
                },
            }),
            ...(showTractor ? [columnHelper.accessor('tractorId', {
                id: TRACTOR_COLUMN,
                header: (headerContext) => {
                    const { header } = headerContext;

                    return (
                        <>
                            <LoadTableHeaderCell
                                header={header}
                                label={L.tractor()}
                            />
                            <MultiFilter
                                column={header.column}
                                baseItems={tractors}
                                displayFunc={(t: Tractor) => {
                                    return t.userDisplayName;
                                }}
                                applyDefaultPreset={applyDefaultPreset}
                            />
                        </>
                    );
                },
                cell: (info) => {
                    const isRowExpanded = info.row.getIsExpanded();
                    const isRowEditable = isLoadRowEditable(isRowExpanded, info.row.original);

                    if (isRowEditable) {
                        return (
                            <EditableLoadTractor
                                getTractorProperties={loadEditableItems.getTractorProperties}
                                showLabel={false}
                                customPopper={true}
                            />
                        );
                    }

                    return toTractorDisplayName(tractors)(info.row.original);
                },
                sortingFn: (rowA, rowB) => {
                    return sortByTractor(tractors)(rowA.original, rowB.original);
                },
                enableGlobalFilter: false,
                filterFn: (row: Row<Load>, columnId: string, filterValue: number[]) => {
                    const rowTractorId = row.getValue(columnId) as number | null;
                    return rowTractorId ? filterValue.includes(rowTractorId) : false;
                },
            })] : []),
            ...(showTrailer ? [columnHelper.accessor('trailerId', {
                id: TRAILER_COLUMN,
                header: (headerContext) => {
                    const { header } = headerContext;

                    return (
                        <>
                            <LoadTableHeaderCell
                                header={header}
                                label={L.trailer()}
                            />
                            <MultiFilter
                                column={header.column}
                                baseItems={trailers}
                                displayFunc={(t: Trailer) => {
                                    return t.userDisplayName;
                                }}
                                applyDefaultPreset={applyDefaultPreset}
                            />
                        </>
                    );
                },
                cell: (info) => {
                    const isRowExpanded = info.row.getIsExpanded();
                    const isRowEditable = isLoadRowEditable(isRowExpanded, info.row.original);

                    if (isRowEditable) {
                        return (
                            <EditableLoadTrailer
                                getTrailerProperties={loadEditableItems.getTrailerProperties}
                                showLabel={false}
                                customPopper={true}
                            />
                        );
                    }

                    return toTrailerDisplayName(trailers)(info.row.original);
                },
                sortingFn: (rowA, rowB) => {
                    return sortByTrailer(trailers)(rowA.original, rowB.original);
                },
                enableGlobalFilter: false,
                filterFn: (row: Row<Load>, columnId: string, filterValue: number[]) => {
                    const rowTrailerId = row.getValue(columnId) as number | null;
                    return rowTrailerId ? filterValue.includes(rowTrailerId) : false;
                },
            })] : []),
            columnHelper.accessor('status', {
                id: STATUS_COLUMN,
                header: (headerContext) => {
                    const { header } = headerContext;

                    return (
                        <>
                            <LoadTableHeaderCell
                                header={header}
                                label={L.status()}
                            />
                            <MultiFilter
                                column={header.column}
                                baseItems={orderStatuses.map(x => (
                                    {
                                        id: x.key,
                                        label: x.label,
                                    }
                                ))}
                                displayFunc={(o: any) => {
                                    return o.label;
                                }}
                                applyDefaultPreset={applyDefaultPreset}
                            />
                        </>
                    );
                },
                cell: (info) => {
                    const isRowEditable = isLoadRowEditable(info.row.getIsExpanded(), info.row.original);

                    if (isRowEditable) {
                        const { editedLoad } = useDraftLoadContext();
                        return <LoadStatus load={editedLoad} carriers={carriers} />;
                    } else {
                        return <LoadStatus load={info.row.original} carriers={carriers} />;
                    }
                },
                sortingFn: (rowA, rowB) => {
                    return sortByStatus()(rowA.original, rowB.original);
                },
                // this is only one that is true, see use-draft-load-table.ts for more info
                enableGlobalFilter: true,
                filterFn: (row: Row<Load>, columnId: string, filterValue: OrderStatusFromTypes[]) => {
                    const rowStatus = row.getValue(columnId) as OrderStatusFromTypes;
                    return filterValue.includes(rowStatus);
                },
            }),
            columnHelper.display({
                id: EXPANDER_COLUMN,
                cell: (info) => {
                    const load = info.row.original;
                    const isExpanded = info.row.getIsExpanded();
                    const { globalIsDirtyRemove } = useDraftsLoadsContext();

                    return (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '.25rem',
                            }}
                        >
                            <PerformanceExpander
                                expanded={isExpanded}
                                onChange={() => {
                                    if (isExpanded) {
                                        globalIsDirtyRemove(load.id);
                                    }

                                    info.row.toggleExpanded();
                                }}
                            />
                            {load.isDraft && <DraftConflictIcon draft={load} />}
                        </div>
                    )
                },
                enableGlobalFilter: false,
            }),
        ];

        return c;
    }, [
        isDrafts,
        organizationLocations,
        drivers,
        tractors,
        trailers,
        cargoTypeList,
        showEstimatedTotalTripTime,
        showTractor,
        showTrailer,
        applyDefaultPreset,
    ]);

    return {
        columns: columns,
        isLoadingColumns: isLoadingColumns
    };
}
