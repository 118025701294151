import React, { FC } from 'react';
import { StopsHeader } from './stops-header';
import { EditableLoadStops } from './editable-load-stops';
import { Load } from '../../../../types';
import { DisplayLoadStops } from './display-load-stops';

interface LoadTableStopsProps {
    isDrafts: boolean;
    // if load is provided we are in 'not-editable' mode
    // in edit mode we are using useDraftLoadContext
    load?: Load;
    showInlineSaveButton?: boolean;
}

export const LoadTableStops: FC<LoadTableStopsProps> = (props) => {
    const { isDrafts, load, showInlineSaveButton = false } = props;

    return (
        <div className='lt-stops-container'>
            <StopsHeader
                isDrafts={isDrafts}
                isEditable={load ? false : true}
            />
            {load ? (
                <DisplayLoadStops isDrafts={isDrafts} load={load} />
            ) : (
                <EditableLoadStops
                    isDrafts={isDrafts}
                    showInlineSaveButton={showInlineSaveButton}
                />
            )}
        </div>
    );
};
