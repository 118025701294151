import { Button, Stack, Typography } from '@mui/material';
import { L, UNITS } from 'harmony-language';
import React, { FormEvent } from 'react';
import { OrgQueryKeys } from '../../../api/config';
import { useOrganizationQuery } from '../../../api/queries/use-organization-query';
import { useUser } from '../../../api/queries/use-user';
import { calculateRoute } from '../../../services/here-maps/here-map-route-service';
import { manualHereMapRouteImport } from '../../../services/here-maps/manual-here-map-route-import';
import { CreateStandardRoute, HerePoint, Organization, OrganizationLocation, StandardRoute } from '../../../types';
import { toSiteDisplayName } from '../../../utils/data-mapping';
import { utc } from '../../../utils/date-time-utils';
import { HereMap, HereMapRefObject } from '../../here-maps/here-map';
import { OrganizationLocationBoxes } from '../../here-maps/organization-location-boxes';
import { maxDistance } from '../../here-maps/standard-route-helpers';
import { StandardRouteLines } from '../../here-maps/standard-route-lines';
import { AgisticsSelect } from '../../shared/agistics-select';
import { DraggableRouteList } from './draggable-route-list';
import { InfoPanel } from './info-panel';

interface EditCreateStandardRouteProps {
    existingStandardRoute: StandardRoute | null;
    onSubmit: (s: StandardRoute | CreateStandardRoute) => void;
    filteredStandardRoutes: StandardRoute[];
    avoidanceZoneBoxes: React.JSX.Element[];
}

export const EditCreateStandardRoute: React.FC<EditCreateStandardRouteProps> = (props) => {
    const { existingStandardRoute, onSubmit, filteredStandardRoutes, avoidanceZoneBoxes } = props;
    const [standardRoute, setStandardRoute] = React.useState<StandardRoute | CreateStandardRoute>(existingStandardRoute || {
        originId: 0,
        destinationId: 0,
        flexiblePolyline: '',
        deletedAt: null,
    });
    const [routeStorage, setRouteStorage] = React.useState<HerePoint[]>([]);
    const [draggableRoute, setDraggableRoute] = React.useState<HerePoint[]>([]);
    const [notices, setNotices] = React.useState<string | null>(null);
    const [hiddenDev, setHiddenDev] = React.useState(0);

    const { data: organization } = useOrganizationQuery<Organization>('');
    const { data: organizationLocations = [] } = useOrganizationQuery<OrganizationLocation[]>(OrgQueryKeys.locations);
    const mapRef = React.useRef<HereMapRefObject>(null);
    const { user } = useUser();

    const sameLocationsSelected = standardRoute.originId === standardRoute.destinationId && standardRoute.originId !== 0;
    const userUnits = user.contact.units || user.organization?.units || UNITS.Imperial;
    const draggableRouteMax = React.useMemo(() => maxDistance(draggableRoute, userUnits), [draggableRoute]);
    const items = organizationLocations.map(x => ({ id: x.id, value: toSiteDisplayName(x.name, x.description) }));
    const hasFlexiblePolyline = Boolean(standardRoute.flexiblePolyline);
    const pointCount = hasFlexiblePolyline ? H.geo.LineString.fromFlexiblePolyline(standardRoute.flexiblePolyline).getPointCount() : 0;
    const activeRouteAlreadyExists = filteredStandardRoutes.some(x => {
        if (existingStandardRoute) return false;
        return (x.originId === standardRoute.originId && x.destinationId === standardRoute.destinationId)
    });

    const onClick = (coordinates: HerePoint) => {
        setRouteStorage([
            ...routeStorage,
            { lat: coordinates.lat, lng: coordinates.lng }
        ]);
    }

    const giveMeAStartingPlace = () => {
        const origin = organizationLocations.find(x => x.id === standardRoute.originId);
        const dest = organizationLocations.find(x => x.id === standardRoute.destinationId);
        const waypoints = [origin, dest];
        const callback = (result: any) => {
            const resPolyline = result.routes[0].sections[0].polyline;
            setStandardRoute({
                ...standardRoute,
                flexiblePolyline: resPolyline,
            });
        };
        calculateRoute(waypoints, [], callback);
    }

    const recalculate = async () => {
        const routeImportRes = await manualHereMapRouteImport(draggableRoute);

        if (routeImportRes.polyline) {
            setStandardRoute({
                ...standardRoute,
                flexiblePolyline: routeImportRes.polyline
            })
        }

        if (routeImportRes.notices) {
            setNotices(routeImportRes.notices);
        } else {
            setNotices(null);
        }
    }

    const onFormSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        onSubmit(standardRoute);
    };

    const onInfoClick = () => {
        setHiddenDev(hiddenDev + 1);
    }

    return (
        <div style={{ width: '80vw', height: '80vh', display: 'flex', flexDirection: 'column' }}>
            <form onSubmit={onFormSubmit}>
                <div style={{ display: 'flex', height: '100%' }}>
                    <div style={{ display: 'flex', minWidth: '20%' }}>
                        <Stack spacing={1} sx={{ width: '20rem', paddingRight: '1rem' }}>
                            <AgisticsSelect
                                item={standardRoute.originId}
                                items={items}
                                onChange={(e) => {
                                    setStandardRoute({ ...standardRoute, originId: e });
                                    if (standardRoute.destinationId === e) {
                                        setNotices(L.sameStandardRouteLocations());
                                    } else {
                                        setNotices(null);
                                    }
                                }}
                                label={L.origin()}
                                disabled={hasFlexiblePolyline || Boolean(existingStandardRoute)}
                            />
                            <AgisticsSelect
                                item={standardRoute.destinationId}
                                items={items}
                                onChange={(e) => {
                                    setStandardRoute({ ...standardRoute, destinationId: e });
                                    if (standardRoute.originId === e) {
                                        setNotices(L.sameStandardRouteLocations());
                                    } else {
                                        setNotices(null);
                                    }
                                }}
                                label={L.destination()}
                                disabled={hasFlexiblePolyline || Boolean(existingStandardRoute)}
                            />
                            {activeRouteAlreadyExists && <Typography variant='caption' style={{ color: 'red' }}>{L.existingStandardRoute()}</Typography>}
                            {!existingStandardRoute && <Button
                                variant='contained'
                                disabled={hasFlexiblePolyline || !standardRoute.originId || !standardRoute.destinationId || activeRouteAlreadyExists || sameLocationsSelected}
                                onClick={giveMeAStartingPlace}
                            >
                                {L.generateStart()}
                            </Button>}
                            {hiddenDev >= 7 && <DraggableRouteList draggableRoute={draggableRoute} />}
                            <Button
                                variant='contained'
                                disabled={!draggableRoute.length}
                                onClick={recalculate}
                            >
                                {L.recalculate()}
                            </Button>
                            <Typography variant='caption' style={{ color: 'red' }}>{notices}</Typography>
                        </Stack>
                    </div>
                    <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}>
                        {organization &&
                            <HereMap
                                id='standard-route-creation-map'
                                ref={mapRef}
                                centerPosition={{ lat: organization.latitude, lng: organization.longitude }}
                                onClick={onClick}
                            >
                                {hasFlexiblePolyline &&
                                    <StandardRouteLines
                                        flexiblePolyline={standardRoute.flexiblePolyline}
                                        setDraggableRoute={setDraggableRoute}
                                        onRouteDrawn={() => mapRef.current?.fitMapToAllObjects(true, undefined, false)}
                                    />
                                }
                                {Boolean(standardRoute.originId && standardRoute.destinationId) && <OrganizationLocationBoxes
                                    ids={[standardRoute.originId, standardRoute.destinationId]}
                                />}
                                {avoidanceZoneBoxes}
                            </HereMap>
                        }
                        <InfoPanel
                            pointCount={pointCount}
                            userUnits={userUnits}
                            draggableRouteMax={draggableRouteMax}
                            onInfoClick={onInfoClick}
                        />
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1em' }}>
                    {existingStandardRoute &&
                        <Button
                            variant='contained'
                            color='secondary'
                            type='button'
                            onClick={() => {
                                onSubmit({
                                    // purposely using existing, so user can't change and delete at same time
                                    ...existingStandardRoute,
                                    deletedAt: utc(),
                                });
                            }}
                            sx={{ marginRight: '1em' }}
                        >
                            {L.delete()}
                        </Button>
                    }
                    <Button
                        variant='contained'
                        disabled={!hasFlexiblePolyline}
                        type='submit'
                    >
                        {existingStandardRoute ? L.saveChanges() : L.createRoute()}
                    </Button>
                </div>
            </form>
        </div>
    );
};
