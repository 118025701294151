import { FC } from 'react';

interface DisplayStopQuantityProps {
    quantity: number | null;
}

export const DisplayStopQuantity: FC<DisplayStopQuantityProps> = (props) => {
    const { quantity } = props;

    if (quantity) {
        return `${quantity} qty`;
    }
};
