import React from 'react';
import { useDraftLoadContext } from '../../../hooks/draft-load-context';
import { AgisticsSelect } from '../../../../shared/agistics-select';
import { Driver, Load } from '../../../../../types';
import { OrderStatus } from 'harmony-constants';
import { L } from 'harmony-language';
import { LoadEditableProperties } from '../../../hooks/use-load-editable-items';

interface EditableLoadDriverProps {
    drivers: Driver[];
    getDriverProperties: (l: Load) => LoadEditableProperties;
    showLabel?: boolean;
    customPopper?: boolean;
}

export const EditableLoadDriver: React.FC<EditableLoadDriverProps> = (props) => {
    const { drivers, getDriverProperties, showLabel = true, customPopper } = props;
    const { editedLoad, onEditedLoadChanged } = useDraftLoadContext();
    const driverProperties = getDriverProperties(editedLoad);

    const onChangeHelper = (driverId: number) => {
        const driver = drivers.find(x => x.id === driverId);
        const hasDriver = driverId !== -1;

        onEditedLoadChanged({
            status: hasDriver ? OrderStatus.Assigned : OrderStatus.Open,
            transportedByUserId: hasDriver ? driverId : null,
            tractorId: driver?.driverInfo?.defaultTractorId || null,
            trailerId: driver?.driverInfo?.defaultTrailerId || null,
        });
    }

    return (
        <AgisticsSelect
            item={editedLoad.transportedByUserId}
            items={driverProperties.items}
            disabled={driverProperties.isDisabled}
            showNone={true}
            onChange={onChangeHelper}
            label={showLabel ? L.driver() : undefined}
            confinedSpace={true}
            customPopper={customPopper}
        />
    );
};
