import { IconButton } from '@mui/material';
import React, { FC } from 'react';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { useDraftLoadContext } from '../../hooks/draft-load-context';
import { stopHasCargo } from '../../../../constants/constants';
import { Stop } from '../../../../types';

interface UnitSwitcherProps {
    stop: Stop;
    index: number;
}

export const EditableUnitSwitcher: FC<UnitSwitcherProps> = (props) => {
    const { stop, index } = props;
    const { unitSwitcherUnitStateChange } = useDraftLoadContext();

    return (
        <UnitSwitcherInternal
            stop={stop}
            onClick={() => unitSwitcherUnitStateChange(index)}
        />
    );
};

interface DisplayUnitSwitcherProps {
    stop: Stop;
    handleUnitsChange: () => void;
}

export const DisplayUnitSwitcher: FC<DisplayUnitSwitcherProps> = (props) => {
    const { stop, handleUnitsChange } = props;

    return (
        <UnitSwitcherInternal
            stop={stop}
            onClick={handleUnitsChange}
        />
    );
}

interface UnitSwitcherInternalProps {
    stop: Stop;
    onClick: () => void;
}

const UnitSwitcherInternal: FC<UnitSwitcherInternalProps> = (props) => {
    const { stop, onClick } = props;

    if (!stopHasCargo(stop)) {
        return null;
    }

    return (
        <IconButton
            onClick={onClick}
            sx={{ padding: 0 }}
        >
            <KeyboardArrowRight />
        </IconButton>
    )
}
