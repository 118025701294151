import { FC } from 'react';
import { Stop } from '../../../../../types';
import { getTransCargoTypeLabel } from '../../../../../constants/constants';

interface DisplayStopCargoTypeProps {
    stop: Stop;
}

export const DisplayStopCargoType: FC<DisplayStopCargoTypeProps> = (props) => {
    const { stop } = props;

    if (stop.cargoTypeId) {
        return getTransCargoTypeLabel(stop.cargoTypeId);
    }
};
