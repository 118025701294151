import { StopType } from 'harmony-constants';
import { Load } from '../../../../../types';
import { L } from 'harmony-language';
import { stopHasCargo } from '../../../../../constants/constants';
import { areStopTypeWeightsEqual } from '../../../drafts/utils/validate-drafts';

const areStopQuantitiesValid = (editedLoad: Load) => {
    const val = editedLoad.stops.reduce((acc, cur) => {
        const quantity = cur.quantity || 0;
        const value = cur.type === StopType.Origin ? quantity : -1 * quantity;
        return acc + value;
    }, 0);

    return val === 0;
}

const isAllOrNoneQuantityValid = (editedLoad: Load) => {
    const anyHasVal = editedLoad.stops.some((s) => {
        return s.quantity && s.quantity > 0 ? true : false;
    });

    return anyHasVal ? editedLoad.stops.filter(stop => stopHasCargo(stop)).every(x => x.quantity) : true;
}

// const areStopWeightsValid = (editedLoad: Load, convertFromGrams: (g: number) => number, weightPrecision: number) => {
//     const sumTotal = editedLoad.stops.reduce((acc, cur) => {
//         const weight = convertFromGrams(cur.weight || 0);
//         const value = cur.type === StopType.Origin ? weight : -1 * weight;
//         return acc + value;
//     }, 0);

//     return Math.abs(Number(sumTotal.toFixed(weightPrecision))) === 0;
// }

const isAllOrNoneWeightValid = (editedLoad: Load) => {
    const anyHasVal = editedLoad.stops.some((s) => {
        return s.weight && s.weight > 0 ? true : false;
    });

    return anyHasVal ? editedLoad.stops.filter(stop => stopHasCargo(stop)).every(x => x.weight) : true;
}

const atLeastOneHasValue = (editedLoad: Load) => {
    return editedLoad.stops.some((s) => s.quantity || s.weight);
}

// because either quantity or weight may or may not be on the page
// need to run both quantity and weight validations on each type of input
export const getQuantityWeightValidations = (
    editedLoad: Load,
    convertFromGrams: (g: number) => number,
    weightPrecision: number,
) => {
    return [
        // quantity validations
        {
            isValid: () => areStopQuantitiesValid(editedLoad),
            message: L.loadInvalidQuantity(),
        },
        {
            isValid: () => isAllOrNoneQuantityValid(editedLoad),
            message: L.loadInvalidQuantityRequiredAllStops(),
        },

        // weight validations
        {
            isValid: () => areStopTypeWeightsEqual(editedLoad.stops, convertFromGrams, weightPrecision),
            message: L.loadInvalidWeight(),
        },
        {
            isValid: () => isAllOrNoneWeightValid(editedLoad),
            message: L.loadInvalidWeightRequiredAllStops(),
        },

        // both
        {
            isValid: () => atLeastOneHasValue(editedLoad),
            message: L.loadInvalidStopValue(),
        },
    ];
}
