import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { L } from 'harmony-language';
import { useSnackbar } from '../../../../components/notifications/use-snackbar';
import { Load } from '../../../../types';
import { useDraftsKey, useLoadsKey } from '../../../config-hooks';

const editLoad = async (options: { load: Load }) => {
    const { load } = options;
    return (await axios.put(`${API_BASE}/api/orders/${load.id}`, load)).data;
}

export const useEditLoadMutation = (isDrafts: boolean) => {
    const snackbar = useSnackbar();
    const queryClient = useQueryClient();
    const queryKey = isDrafts ? useDraftsKey() : useLoadsKey();

    const mutation = useMutation({
        mutationFn: editLoad,
        onMutate: async (variables) => {
            const { load } = variables;
            await queryClient.cancelQueries(queryKey);

            const previousCache = queryClient.getQueryData<Load[]>(queryKey);

            // Optimistically update to the new value
            if (previousCache) {
                const newLoads = previousCache.map((existingLoad) => {
                    if (existingLoad.id === load.id) {
                        return load;
                    }
                    return existingLoad;
                });

                queryClient.setQueryData<Load[]>(queryKey, newLoads);
            }

            return previousCache;
        },
        onError: (_1, _2, context) => {
            if (context) {
                queryClient.setQueryData<Load[]>(queryKey, context);
            }
        },
        onSuccess: () => {
            snackbar(L.loadEditedSuccessfully());
        },
        // onSettled: () => {
            // queryClient.invalidateQueries(queryKey);
        // },
    });

    return mutation;
};
