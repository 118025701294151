import React, { FC } from 'react';
import { Stop } from '../../../../../types';
import { IconButton } from '@mui/material';
import { L } from 'harmony-language';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { useDraftLoadContext } from '../../../hooks/draft-load-context';
import { getNewLoadDate } from '../../../utils/drafts-loads-utils';
import { useDraftLoadStopContext } from '../../../hooks/draft-load-stop-context';

interface EditableStopDeleteProps {
    stop: Stop;
    index: number;
}

export const EditableStopDelete: FC<EditableStopDeleteProps> = (props) => {
    const { stop, index } = props;
    const { editedLoad, onEditedLoadChanged, deleteOneUnitState } = useDraftLoadContext();
    const { isCarrier, isComplete } = useDraftLoadStopContext();

    const handleDeleteStop = () => {
        const newStops = editedLoad.stops.filter(s => s !== stop);
        const loadDate = getNewLoadDate(newStops, null) || editedLoad.date;

        onEditedLoadChanged({
            ...editedLoad,
            date: loadDate,
            stops: newStops,
        });

        deleteOneUnitState(index);
    };

    const splitDisabled = Boolean(editedLoad.stops.filter(s => s.splitId === stop.id).length);
    const isDisabled = splitDisabled || isCarrier || isComplete;

    return (
        <IconButton
            title={L.delete()}
            color='primary'
            disabled={isDisabled}
            onClick={handleDeleteStop}
            sx={{ padding: 0 }}
        >
            <DeleteOutlinedIcon color={isDisabled ? 'disabled' : 'primary'} />
        </IconButton>
    );
};
