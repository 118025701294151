import React, { FC } from 'react';
import { useOrganizationQuery } from '../../../../api/queries/use-organization-query';
import { Driver, Trailer } from '../../../../types';
import { DynamicQueryKeys, OrgQueryKeys } from '../../../../api/config';
import { useCompanyId } from '../../../../app/agistics-context';
import { useQuery } from '@tanstack/react-query';
import { localDateTimeDisplay } from '../../../../utils/date-time-utils';
import { styled } from '@mui/material';
import { L } from 'harmony-language';
import { useUser } from '../../../../api/queries/use-user';
import { LoadEditableItems } from '../../hooks/use-load-editable-items';
import { useDraftLoadContext } from '../../hooks/draft-load-context';
import { EditableLoadDriver } from '../../drafts-loads-table-cells/loads/editable/editable-load-driver';
import { EditableLoadTractor } from '../../drafts-loads-table-cells/loads/editable/editable-load-tractor';
import { EditableLoadTrailer } from '../../drafts-loads-table-cells/loads/editable/editable-load-trailer';
import { DraftsLoadsSaveButton } from '../../drafts-loads-table-cells/drafts-loads-save-button';

const ColHeader = styled('td')({
    fontWeight: 'bold',
});

interface SchedulingResourceInfo {
    driverTimestamps: {
        driverId: number;
        orderId: number;
        timestamp: string;
    }[];
    latestTrailerProductionPlan: {
        trailerId: number;
        orderId: number;
        productionPlans: string;
    }[];
}

interface ResourceSchedulingProps {
    loadEditableItems: LoadEditableItems;
    onClose?: () => void;
}

// this is wrote to only be on the drafts page.  Ignores carriers
// if needed to add to other screens where carriers need to be handled, will need some modifications
export const ResourceScheduling: FC<ResourceSchedulingProps> = (props) => {
    const { loadEditableItems, onClose } = props;
    const { editedLoad: load } = useDraftLoadContext();
    const { data: drivers = [] } = useOrganizationQuery<Driver[]>(OrgQueryKeys.drivers);
    const { data: trailers = [] } = useOrganizationQuery<Trailer[]>(OrgQueryKeys.trailers);
    const companyId = useCompanyId();
    const { user } = useUser();

    const filteredDrivers = React.useMemo(() => {
        const userPrefRemovedDrivers = user.preferences?.removedDrivers || [];

        const filteredOrgAndDisabledDrivers = drivers.filter(x => x.enabled && x.organizationId === companyId);
        const filteredRemovedDrivers = filteredOrgAndDisabledDrivers.filter(driver => !userPrefRemovedDrivers.includes(driver.id));
        return filteredRemovedDrivers;
    }, []);

    const filteredTrailers = React.useMemo(() => {
        const userPrefRemovedTrailers = user.preferences?.removedTrailers || [];

        const filteredOrgAndDisabledTrailers = trailers.filter(x => x.enabled && x.organizationId === companyId);
        const cargoTypeIdsOfStops = load.stops.map(stop => stop.cargoTypeId);
        const filteredCargoTrailers = filteredOrgAndDisabledTrailers.filter(trailer => {
            const cargoIdsOfTrailer = trailer.trailerCargoTypes.map(x => x.cargoTypeId);
            const has = cargoIdsOfTrailer.some(c => cargoTypeIdsOfStops.includes(c));
            return has;
        });
        const filteredRemovedTrailers = filteredCargoTrailers.filter(trailer => !userPrefRemovedTrailers.includes(trailer.id))
        return filteredRemovedTrailers;
    }, []);

    const key = DynamicQueryKeys.schedulingResourceInfo(filteredDrivers.map(x => x.id), filteredTrailers.map(x => x.id));
    const { data: schedulingResourceInfo } = useQuery<SchedulingResourceInfo>({
        queryKey: [key],
        staleTime: Infinity,
        cacheTime: 0,
    });

    return (
        <>
            <div style={{ minWidth: '40vw', display: 'grid', gridTemplateColumns: '1fr 1fr 1fr .5fr', columnGap: '10px', paddingBottom: '.5rem' }}>
                <EditableLoadDriver
                    drivers={drivers}
                    getDriverProperties={loadEditableItems.getDriverProperties}
                />
                <EditableLoadTractor getTractorProperties={loadEditableItems.getTractorProperties} />
                <EditableLoadTrailer getTrailerProperties={loadEditableItems.getTrailerProperties} />
                {onClose
                    ? <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}><DraftsLoadsSaveButton /></div>
                    : <div />
                }
            </div>
            <div
                style={{
                    display: 'flex',
                    alignContent: 'flex-start',
                    justifyContent: 'space-evenly',
                    alignItems: 'flex-start',
                    fontSize: '.875rem',
                    gap: '1rem',
                }}
            >
                <table style={{ border: '1px solid', padding: '5px' }}>
                    <tbody>
                        <tr><ColHeader>{L.driver()}</ColHeader><ColHeader>{L.time()}</ColHeader></tr>
                        {filteredDrivers.map((driver) => {
                            const driverTimestamp = schedulingResourceInfo?.driverTimestamps.find(x => x.driverId === driver.id);

                            return (
                                <tr key={driver.id}>
                                    <td
                                        title={driverTimestamp?.orderId.toString()}
                                        style={{ paddingRight: '10px' }}
                                    >
                                        {driver.name}
                                    </td>
                                    <td>{driverTimestamp ? localDateTimeDisplay(driverTimestamp.timestamp) : ''}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <table style={{ border: '1px solid', padding: '5px' }}>
                    <tbody>
                        <tr><ColHeader>{L.trailer()}</ColHeader><ColHeader>{L.productionPlan()}</ColHeader></tr>
                        {filteredTrailers.map((trailer) => {
                            const latestPlan = schedulingResourceInfo?.latestTrailerProductionPlan.find(x => x.trailerId === trailer.id);

                            return (
                                <tr key={trailer.id}>
                                    <td
                                        title={latestPlan?.orderId.toString()}
                                        style={{ paddingRight: '10px' }}
                                    >
                                        {trailer.userDisplayName}
                                    </td>
                                    <td>{latestPlan ? latestPlan.productionPlans : ''}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </>
    );
};
