import React, { FC, Fragment } from 'react';
import { Load } from '../../../../../types';
import { genOrderNumbers } from '../../../utils/drafts-loads-utils';
import { AgisticsTooltip } from '../../../../shared/agistics-tooltip';
import { L } from 'harmony-language';

interface LoadOrderNumbersProps {
    load: Load;
}

export const LoadOrderNumbers: FC<LoadOrderNumbersProps> = (props) => {
    const { load } = props;
    const orderNumbers = genOrderNumbers(load);

    if (orderNumbers.length) {
        const orderNumbersLineBreak = orderNumbers.map((orderNumber, i) => {
            return <div key={i}>{orderNumber}</div>;
        });

        const orderNumbersDisplay = orderNumbers.join(', ');

        const idDiv = <Fragment key={load.id}><div>&nbsp;</div><div>{L.id()} {load.id}</div></Fragment>;
        const numsWithId = orderNumbersLineBreak.concat(idDiv);

        return (
            <AgisticsTooltip
                tooltip={numsWithId}
            >
                {orderNumbersDisplay}
            </AgisticsTooltip>
        );
    } else {
        return <div>{load.id}</div>;
    }
}
