import React, { FC } from 'react';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import { Load, OrganizationLocation } from '../../../types';
import { L } from 'harmony-language';
import TableContainer from '@mui/material/TableContainer';
import { Paper } from '@mui/material';
import TableHead from '@mui/material/TableHead';
import Skeleton from "@mui/material/Skeleton";
import { filterStopByDestination, filterStopByOrigin } from '../../../utils/data-mapping-utils';
import { useOrganizationQuery } from '../../../api/queries/use-organization-query';
import { OrgQueryKeys } from '../../../api/config';
import { useWeights } from '../../shared/hooks/use-weights';
import { LoadStopTime } from '../../drafts-loads/drafts-loads-table-cells/loads/display/load-stop-time';
import { LoadStopLocation } from '../../drafts-loads/drafts-loads-table-cells/loads/display/load-stop-location';
import { LoadCargo } from '../../drafts-loads/drafts-loads-table-cells/loads/display/load-cargo';
import { LoadLotIds } from '../../drafts-loads/drafts-loads-table-cells/loads/display/load-lot-ids';
import { LoadOrderNumbers } from '../../drafts-loads/drafts-loads-table-cells/loads/display/load-order-numbers';

interface ProductionPreviewTableProps {
    drafts: Load[],
    isLoading: boolean,
}

const Header: React.FC = () => {
    return (
        <TableHead>
            <TableRow>
                <TableCell>{L.date()}</TableCell>
                <TableCell>{L.origin()}</TableCell>
                <TableCell>{L.destination()}</TableCell>
                <TableCell>{L.cargo()}</TableCell>
                <TableCell width={'10%'}>{L.lotId()}</TableCell>
                <TableCell width={'10%'}>{L.orderNumber()}</TableCell>
            </TableRow>
        </TableHead>
    );
};

interface RowProps {
    draft: Load;
}

const Row: FC<RowProps> = (props) => {
    const { draft } = props;
    const { data: organizationLocations = [] } = useOrganizationQuery<OrganizationLocation[]>(OrgQueryKeys.locations);
    const { convertFromGramsDisplay } = useWeights();

    const origins = draft.stops.filter(filterStopByOrigin);
    const originIds = origins.map(x => x.organizationLocationId);
    const uniqueOriginIds = [...new Set(originIds)];

    const destinations = draft.stops.filter(filterStopByDestination);
    const destinationIds = destinations.map(x => x.organizationLocationId);
    const uniqueDestinationIds = [...new Set(destinationIds)];

    return (
        <TableRow>
            <TableCell><LoadStopTime time={draft.date} /></TableCell>
            <TableCell>
                {uniqueOriginIds.map(uniqueOriginId => {
                    return (
                        <LoadStopLocation
                            key={uniqueOriginId}
                            organizationLocationId={uniqueOriginId}
                            organizationLocations={organizationLocations}
                            stops={origins}
                        />
                    );
                })}
            </TableCell>
            <TableCell>
                {uniqueDestinationIds.map(uniqueDestinationId => {
                    return (
                        <LoadStopLocation
                            key={uniqueDestinationId}
                            organizationLocationId={uniqueDestinationId}
                            organizationLocations={organizationLocations}
                            stops={destinations}
                        />
                    );
                })}
            </TableCell>
            <TableCell><LoadCargo load={draft} convertFromGramsDisplay={convertFromGramsDisplay} /></TableCell>
            <TableCell width='10%'><LoadLotIds load={draft} /></TableCell>
            <TableCell width='10%'>
                <LoadOrderNumbers load={draft} />
            </TableCell>
        </TableRow>
    );
};

const ProductionPreviewTable: React.FC<ProductionPreviewTableProps> = (props) => {
    const { drafts, isLoading } = props;
    return (
        <Paper style={{ width: '100%' }}>
            <TableContainer>
                <Table style={{ tableLayout: 'fixed' }}>
                    <Header/>
                    <TableBody>
                        {isLoading ?
                            Array.from(Array(10), (x, i) => i).map((x) => {
                                return (
                                    <TableRow key={x}>
                                        <TableCell colSpan={5}>
                                            <Skeleton width={'100%'} height={'1rem'}/>
                                        </TableCell>
                                    </TableRow>
                                );
                            }) : drafts.map((d) => {
                                return (
                                    <Row draft={d} />
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

export default ProductionPreviewTable;
