import React, { FC } from 'react';
import { StopNotesModal } from '../../../../shared/load-table/stop-notes-modal';
import { DynamicQueryKeys } from '../../../../../api/config';
import { Stop } from '../../../../../types';
import { ImagesModal } from '../../../../shared/images-modal';
import { L } from 'harmony-language';

interface DisplayStopNotesProps {
    stop: Stop;
}

export const DisplayStopNotes: FC<DisplayStopNotesProps> = (props) => {
    const { stop } = props;

    const key = DynamicQueryKeys.stopImages(stop.id);

    return (
        <div>
            <StopNotesModal stop={stop} stopEditDisabled={false} />
            {stop.imageIds?.length &&
                <ImagesModal imageLabel={L.stopPhoto()} modalLabel={L.stopPhotos()} queryKey={key} />
            }
        </div>
    );
};
