import io, { Socket } from 'socket.io-client';

let socket: Socket | null = null;

export const getSocket = () => {
    if (!socket || !socket.connected) {
        socket = io(API_BASE, { transports: ['websocket'] });
    }

    return socket;
};
