import React, { createContext, FC, ReactNode, useContext, useState } from 'react';

interface DraftsLoadsContext {
    globalIsDirty: number[];
    handleGlobalIsDirtyChange: (loadId: number, isDirty: boolean) => void;
    globalIsDirtyRemove: (loadId: number) => void;
}

const DraftsLoadsContext = createContext<DraftsLoadsContext | undefined>(undefined);

interface DraftsLoadsProviderProps {
    children: ReactNode;
}

export const DraftsLoadsProvider: FC<DraftsLoadsProviderProps> = (props) => {
    const { children } = props;
    const [globalIsDirty, setGlobalIsDirty] = useState<number[]>([]);

    const handleGlobalIsDirtyChange = (loadId: number, isDirty: boolean) => {
        if (isDirty && !globalIsDirty.includes(loadId)) {
            const newGloablIsDirty = [
                ...globalIsDirty,
                loadId,
            ];

            setGlobalIsDirty(newGloablIsDirty);
        } else if (!isDirty) {
            globalIsDirtyRemove(loadId);
        }
    }

    const globalIsDirtyRemove = (loadId: number) => {
        const newGloablIsDirty = globalIsDirty.filter(x => x !== loadId);
        setGlobalIsDirty(newGloablIsDirty);
    }

    return (
        <DraftsLoadsContext.Provider
            value={{
                globalIsDirty,
                handleGlobalIsDirtyChange,
                globalIsDirtyRemove,
            }}
        >
            {children}
        </DraftsLoadsContext.Provider>
    );
}

export const useDraftsLoadsContext = () => {
    const draftsLoadsContext = useContext(DraftsLoadsContext);

    if (draftsLoadsContext === undefined) {
        throw new Error('draftsLoadsContext must be used within a DraftsLoadsProvider');
    }

    return draftsLoadsContext;
}
