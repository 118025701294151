import { add, isValidDate, subtract, utc } from '../../../../utils/date-time-utils';
import { calculateStopArrivalTimes } from '../../../../utils/data-mapping-utils';
import { withThroughputs } from '../../../../utils/throughput-utils';
import { isCargoStop } from '../../../../constants/constants';

export const recalculateStopTimeDrafts = ({
                                              drafts,
                                              travelTimes,
                                              stopLocationBasis,
                                              intervalMinutes,
                                              isBackwards = false,
                                              startAtDate,
                                              throughputs,
                                              offsets = []
                                          }) => {
    if (stopLocationBasis && intervalMinutes && isValidDate(startAtDate)) {
        const method = isBackwards ? subtract : add;
        let date = startAtDate;

        // Handle backwards cascading by mapping in reverse
        const newDrafts = isBackwards ? drafts.slice().reverse() : drafts;

        const mapped = newDrafts.map((d, draftIndex) => {
            const stopBasisIndex = d.stops.findIndex(s => s.organizationLocationId === stopLocationBasis.id && isCargoStop(s));
            const gap = offsets[isBackwards ? (drafts.length - 1) - draftIndex : draftIndex] ?? 0;
            const arrivalTime = utc(add(gap, 'minutes', (draftIndex ? method(intervalMinutes, 'minutes', date) : date)))
            date = arrivalTime

            const newStops = [
                ...d.stops.slice(0, stopBasisIndex),
                {
                    ...d.stops[stopBasisIndex],
                    arrivalTime: arrivalTime
                },
                ...d.stops.slice(stopBasisIndex + 1)];
            const calculatedStops = calculateStopArrivalTimes(newStops.map(withThroughputs(throughputs)), newStops[stopBasisIndex].sequence, travelTimes);

            return {
                ...d,
                date: calculatedStops[0].arrivalTime,
                stops: calculatedStops
            };
        });

        // Reverse the array back
        if (isBackwards) {
            mapped.reverse()
        }

        return mapped;
    }
    return null;
};
