import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { Card, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { IncludedDates } from 'harmony-constants';
import { L } from 'harmony-language';
import React, { useState } from 'react';
import { QueryKeys } from '../../../api/config';
import { useAgisticsContext } from '../../../app/agistics-context';
import { DateFilter } from '../../../types';
import { getWorkingWeek, localNow } from '../../../utils/date-time-utils';
import { useUpdateUserPreferences } from '../hooks/use-update-user-preferences';
import { AgisticsDateRange } from '../inputs/agistics-date-range';
import { AgisticsMultiSelectWithPrefs } from '../multi-select/agistics-multi-select-with-prefs';
// import { useLoadOrderGroup } from './utils/use-load-order-group';
import moment from 'moment-timezone';
import { ConfirmationDialog } from '../confirmation-dialog';

interface DateFilteringProps {
    // onlyCustom?: boolean;
    tableKey: string;
    refetch: () => void;
    // production planning does not have the following
    resetTableState?: () => void;
    globalIsDirty?: number[];
}

export const DateFiltering = (props: DateFilteringProps) => {
    const { tableKey, refetch, resetTableState, globalIsDirty } = props;
    const { dateFilters, updateDateFilters } = useAgisticsContext();
    const dateFilter = dateFilters[tableKey];
    // const orderGroup = useLoadOrderGroup();
    const { preferences } = useUpdateUserPreferences();
    const [includedDates, setIncludedDates] = React.useState(preferences.includedDates || []);
    const [internalCustomFrom, setInternalCustomFrom] = useState(dateFilter.from);
    const [internalCustomTo, setInternalCustomTo] = useState(dateFilter.to);
    const [confirmRefreshOpen, setConfirmRefreshOpen] = useState(false);

    // const includedDatesVisable = !orderGroup.guid && tableKey !== QueryKeys.productionCardGroups;
    const includedDatesVisable = tableKey !== QueryKeys.productionCardGroups;
    const includedDatesMultiItems = Object.values(IncludedDates)
        .map(x => ({ id: x.id, value: L[x.name]() }))
        //completed at date filter should only be present on loads screen
        .filter(x => tableKey === '/self/orders' || x.id !== 4);

    const dateDisplay = dateFilter.from === dateFilter.to
        ? dateFilter.from.slice(0, 10)
        : `${dateFilter.from.slice(0, 10)} — ${dateFilter.to.slice(0, 10)}`
    const dateLabel = dateFilter.mode === 'day'
        ? dateFilter.offset < -1
            ? `${Math.abs(dateFilter.offset)} ${L.daysAgo()}`
            : dateFilter.offset === -1
            ? L.yesterday()
            : dateFilter.offset === 0
            ? L.today()
            : dateFilter.offset === 1
            ? L.tomorrow()
            : `${dateFilter.offset} ${L.daysAhead()}`
        : dateFilter.offset < -1
            ? `${Math.abs(dateFilter.offset)} ${L.weeksAgo()}`
            : dateFilter.offset === -1
            ? L.lastWeek()
            : dateFilter.offset === 0
            ? L.thisWeek()
            : dateFilter.offset === 1
            ? L.nextWeek()
            : `${dateFilter.offset} ${L.weeksAhead()}`;
    
    function setDateFilter(dateFilter: Partial<DateFilter>) {
        // if something is selected and attempt to change day/week/offset causes infinite loop see SelectionButtons for more info.
        // also probably expected behavior
        resetTableState && resetTableState();

        // orderGroup.remove();

        updateDateFilters(tableKey, dateFilter);
    }

    function handleDateOffset(incrementValue: number) {
        const newOffset = dateFilter.offset + incrementValue;

        setDateFilter({
            ...dateFilter,
            offset: newOffset,
            from: dateFilter.mode === 'week'
                ? getWorkingWeek(newOffset).sundayIso
                : localNow().startOf('day').add(newOffset, 'day').toISOString(),
            to: dateFilter.mode === 'week'
                ? getWorkingWeek(newOffset).saturdayIso
                : localNow().startOf('day').add(newOffset, 'day').toISOString()
        });
    }

    return (
        <>
            <Grid container spacing={2} alignItems='center' sx={{ minHeight: '64px' }}>
                <Grid item>
                    <Button
                        variant='contained'
                        color='inherit'
                        startIcon={<AutorenewIcon />}
                        onClick={() => {
                            if (globalIsDirty && globalIsDirty.length) {
                                setConfirmRefreshOpen(true);
                            } else {
                                refetch();
                            }
                        }}
                    >
                        {L.refresh()}
                    </Button>
                </Grid>

                <Grid item>
                    <ToggleButtonGroup value={dateFilter.mode} fullWidth>
                        <ToggleButton
                            value={'day'}
                            onClick={() => {
                                setDateFilter({
                                    mode: 'day',
                                    offset: 0,
                                    from: localNow().startOf('day').toISOString(),
                                    to: localNow().startOf('day').toISOString()
                                });
                            }}
                        >
                            {L.day()}
                        </ToggleButton>

                        <ToggleButton
                            value={'week'}
                            onClick={() => {
                                setDateFilter({
                                    mode: 'week',
                                    offset: 0,
                                    from: getWorkingWeek(0).sundayIso,
                                    to: getWorkingWeek(0).saturdayIso
                                });
                            }}
                        >
                            {L.week()}
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
                <Grid item>
                    <Stack direction='row' justifyContent='center'>
                        <Button
                            variant='contained'
                            color='inherit'
                            disabled={dateFilter.mode === 'custom'}
                            sx={{ padding: 0, minWidth: '32px', borderTopRightRadius: 0, borderBottomRightRadius: 0}}
                            onClick={() => handleDateOffset(-1)}
                        >
                            <ChevronLeft />
                        </Button>
                        <Card sx={{ minWidth: '13em', textAlign: 'center', borderRadius: 0, border: 1, borderColor: 'lightgray'}}>
                            <Typography>{dateDisplay}</Typography>
                            <Typography variant='body2'>{dateLabel}</Typography>
                        </Card>
                        <Button
                            variant='contained'
                            color='inherit'
                            disabled={dateFilter.mode === 'custom'}
                            sx={{ padding: 0, minWidth: '32px', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                            onClick={() => handleDateOffset(1)}
                        >
                            <ChevronRight />
                        </Button>
                    </Stack>
                </Grid>
                <Grid item>
                    <Button
                        variant='contained'
                        color={dateFilter.mode === 'custom' ? 'secondary' : 'inherit'}
                        onClick={() => setDateFilter({ mode: 'custom' })}
                    >
                        {L.custom()}
                    </Button>
                </Grid>
                {dateFilter.mode === 'custom' &&
                    <>
                        {includedDatesVisable && <Grid item xs={7} sm={6} md={4} lg={3} xl={1.5}>
                            <AgisticsMultiSelectWithPrefs
                                selectedIds={includedDates}
                                setSelectedIds={setIncludedDates}
                                items={includedDatesMultiItems}
                                label={L.includedDates()}
                                prefKey='includedDates'
                                removeMode={false}
                            />
                        </Grid>}
                        <Grid item xs={7} sm={6} md={4} lg={3} xl={3}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <AgisticsDateRange
                                    startDate={internalCustomFrom}
                                    endDate={internalCustomTo}
                                    setStartDate={(date) => {
                                        const newFromDate = moment(date).format('YYYY-MM-DD');
                                        setInternalCustomFrom(newFromDate);
                                    }}
                                    setEndDate={(date) => {
                                        const newToDate = moment(date).format('YYYY-MM-DD');
                                        setInternalCustomTo(newToDate);
                                    }}
                                />
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={() => {
                                        setDateFilter({
                                            ...dateFilter,
                                            from: internalCustomFrom,
                                            to: internalCustomTo,
                                        })
                                    }}
                                >
                                    {L.run()}
                                </Button>
                            </div>
                        </Grid>
                    </>
                }
            </Grid>
            <ConfirmationDialog
                open={confirmRefreshOpen}
                title={L.refreshConfirmationTitle()}
                message={L.refreshConfirmationMessage()}
                cancelText={L.cancel()}
                confirmText={L.refresh()}
                resultCallback={(confirmed) => {
                    if (confirmed) {
                        refetch();
                    }

                    setConfirmRefreshOpen(false);
                }}
            />
        </>
    );
};
