import { useQuery } from '@tanstack/react-query';
import { QueryKeys, typedFetchFunction } from '../config';
import { useDraftsKey, useLoadsKey } from '../config-hooks';
import { DateFilter, Load } from '../../types';
import { endOfDay, utcWithTz } from '../../utils/date-time-utils';
import { useAgisticsContext } from '../../app/agistics-context';
import { useUser } from './use-user';

// this needs to be reworked if we add this functionality back
// just copy/paste from old file that I have deleted
// const fetchLoadsWithGuid = async (queryKey: string, guid: string) => {
//     const fakeQueryContext = {
//         queryKey: [queryKey],
//     }

//     const loads = await defaultQueryOptions.queries.queryFn(fakeQueryContext, {
//         params: { orderGroupGuid: guid }
//     });

//     const range = ordersToDateTimeRange(loads);
//     const filter = {
//         preset: 'custom',
//         from: moment(range?.begin).format('YYYY-MM-DD'),
//         to: moment(range?.end).format('YYYY-MM-DD')
//     };

//     return {
//         loads: loads.map(x => ({ ...x, orderGroupGuid: x.orderGroup.guid })),
//         filter
//     };
// };

const fetchLoadsWithDateFilter = async (baseKey: string, dateFilter: DateFilter, includedDates?: number[]) => {
    const includedDatesFilter = (includedDates: number[]) => {
        // completedAt should only be available/used on the loads page
        if (baseKey !== QueryKeys.loads) {
            return includedDates.filter(x => x !== 4);
        }
        return includedDates;
    }

    const params = {
        startDate: utcWithTz(dateFilter.from),
        endDate: utcWithTz(endOfDay(dateFilter.to)),
        ...(dateFilter.mode === 'custom' && includedDates && { includedDates: includedDatesFilter(includedDates) }),
    }

    const apiLoads = (await typedFetchFunction<Load[]>(baseKey, params)).data;

    return apiLoads;
};

export const useDraftsLoads = (isDrafts: boolean) => {
    const { dateFilters, resetSocketStopIds } = useAgisticsContext();
    const { user } = useUser();

    const baseKey = isDrafts ? QueryKeys.drafts : QueryKeys.loads;
    const dateFilter = dateFilters[baseKey];
    const queryKey = isDrafts ? useDraftsKey() : useLoadsKey();

    const query = useQuery({
        queryKey: queryKey,
        queryFn: async () => {
            resetSocketStopIds();
            return await fetchLoadsWithDateFilter(baseKey, dateFilter, user.preferences.includedDates);
        },
        staleTime: 30 * 1000,
        cacheTime: 0,
        refetchOnWindowFocus: false,
    });

    return query;
}
