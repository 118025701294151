import React from 'react';
import { mergeDrafts } from './merge-utils';
import { useOrganizationQuery } from '../../../../api/queries/use-organization-query';
import { OrgQueryKeys } from '../../../../api/config';
import { Load } from '../../../../types';

export const useMergeDrafts = (drafts: Load[]) => {
    const { data: throughputs } = useOrganizationQuery(OrgQueryKeys.throughput);
    const mergedState = React.useMemo(() => mergeDrafts(drafts, throughputs), []);

    return mergedState;
};
