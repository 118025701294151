import React, { FC } from 'react';
import { Load } from '../../../../../types';
import { getDuration } from '../../../../../utils/date-time-utils';

interface ActualDurationProps {
    load: Load;
}

export const ActualDuration: FC<ActualDurationProps> = (props) => {
    const { load } = props;

    return (
        load.timeSeconds ? <div>{getDuration(load.timeSeconds * 1000)}</div> : <div />
    );
};
