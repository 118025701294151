import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { useUser } from '../../api/queries/use-user';
import { DeviceLocation } from '../../types';
import { convertSpeedToDisplay } from '../../utils/unit-conversion';
import { HereMapMarker } from './here-map-marker';

const getSpeedIcon = (speedDisplay: string) => {
    const elements = (
        <div id='speedIcon' style={{ position: 'relative', top: '8px', left: '-24px' }}>
            <svg width="120" height="120" xmlns="http://www.w3.org/2000/svg">
                <g>
                    <text x='0' y='16' fontSize='16' fill='yellow'>
                        {speedDisplay}
                    </ text>
                </g>
            </svg>
        </div>
    );

    return ReactDOMServer.renderToString(elements);
}

type HereMapSpeedProps = {
    location: DeviceLocation;
}

export const HereMapSpeed: React.FC<HereMapSpeedProps> = (props) => {
    const { location } = props;
    const { user } = useUser();
    const unitSystem = user.contact?.units || user.organization?.units;

    if (location.speed == null) return null;

    return (
        <HereMapMarker
            icon={getSpeedIcon(convertSpeedToDisplay(unitSystem)(location.speed))}
            latitude={location.latitude}
            longitude={location.longitude}
            visibility={false}
            type={'speed'}
        />
    );
}
