import React from 'react';
import { localDateTimeDisplay } from '../../../../../utils/date-time-utils';

interface LoadStopTimeProps {
    time: string | null;
}

export const LoadStopTime: React.FC<LoadStopTimeProps> = (props) => {
    const { time } = props;

    if (!time) {
        return null;
    }

    return localDateTimeDisplay(time);
};
