import React, { FC, useMemo } from 'react';
import { Load } from '../../../types';
import { OrgQueryKeys } from '../../../api/config';
import { useUser } from '../../../api/queries/use-user';
import { useQueryClient } from '@tanstack/react-query';
import { nonHookNonLoadTravelTime, nonHookTravelTime, TravelTimesCache } from '../../travel-times/use-travel-times';
import { useAddTravelTimes } from '../../../api/mutations/reworked/add/use-add-travel-times';
import { orderToLoadingDurationMinutes } from '../../../utils/data-mapping-utils';
import { getDuration } from '../../../utils/date-time-utils';
import { CircularProgress } from '@mui/material';

interface RunningTimeProps {
    selectedLoads: Load[];
}

export const RunningTime: FC<RunningTimeProps> = (props) => {
    const { selectedLoads } = props;
    const { organizationId } = useUser();
    const queryKey = OrgQueryKeys.resolve(organizationId, OrgQueryKeys.locationTravelTimes);
    const queryClient = useQueryClient();
    const travelTimesCache = queryClient.getQueryData<TravelTimesCache>([queryKey]);
    const { mutate: addTravelTimes, isLoading } = useAddTravelTimes(organizationId);

    const allTheTimes = useMemo(() => {
        return selectedLoads.map((load, i) => {
            let potentialBetweenLoadsTime = 0;

            const loadUnloadDuration = orderToLoadingDurationMinutes(load) as number;

            if (i !== 0) {
                // the previous load last stop organizationLocationId
                const stops1 = selectedLoads[i - 1].stops;
                const key1 = stops1[stops1.length - 1].organizationLocationId;

                // the 1st stop organizationLocationId
                const stops2 = load.stops;
                const key2 = stops2[0].organizationLocationId;
                const keys = [`${key1},${key2}`];

                if (key1 !== key2) {
                    potentialBetweenLoadsTime = nonHookNonLoadTravelTime(travelTimesCache, keys);

                    if (potentialBetweenLoadsTime === 0) {
                        if (key1 && key2) {
                            addTravelTimes([[key1, key2]]);
                        }
                    }
                }
            }

            const loadTravelTime = nonHookTravelTime(travelTimesCache, load);

            return loadTravelTime + potentialBetweenLoadsTime + loadUnloadDuration;
        });
    }, [selectedLoads, travelTimesCache]);

    return isLoading ? <CircularProgress size='1em' /> : <RunningTimeInternal allTheTimes={allTheTimes} />;
};

interface RunningTimeInternal {
    allTheTimes: number[];
}

const RunningTimeInternal: FC<RunningTimeInternal> = (props) => {
    const { allTheTimes } = props;

    const sumTimes = allTheTimes.reduce((prev, curr) => prev + curr, 0);
    const minuteMs = 1000 * 60;
    const duration = getDuration(sumTimes * minuteMs);

    return duration;
}
