import React, { CSSProperties, FC } from 'react';
import { Load } from '../../../../../types';
import { orderToLoadingDurationMinutes } from '../../../../../utils/data-mapping-utils';
import { useTravelTimesLoad } from '../../../../travel-times/use-travel-times';
import { getDuration } from '../../../../../utils/date-time-utils';
import { CircularProgress } from '@mui/material';
import { AgisticsTooltip } from '../../../../shared/agistics-tooltip';
import { NoMUITravelTimeTooltip } from '../../../../travel-times/travel-time-tooltip';

interface EstimatedTotalTripTimeProps {
    load: Load;
    style?: CSSProperties;
}

export const EstimatedTotalTripTime: FC<EstimatedTotalTripTimeProps> = (props) => {
    const { load, style } = props;
    const loadDurationMinutes = orderToLoadingDurationMinutes(load);
    const { travelTime, travelDistance, isLoading } = useTravelTimesLoad(load);
    const minuteMs = 1000 * 60;
    const roundedTotalTripMs = Math.ceil((travelTime + loadDurationMinutes) / 5) * 5 * minuteMs; // move to next 5 minute interval
    const durationText = roundedTotalTripMs > 0 ? getDuration(roundedTotalTripMs) : '';

    return (
        <div style={style}>
            {isLoading
                ? <CircularProgress size='1em' />
                : <AgisticsTooltip
                        tooltip={
                            <NoMUITravelTimeTooltip
                                loadDurationMinutes={loadDurationMinutes}
                                travelTimeMinutes={travelTime}
                                travelDistance={travelDistance}
                            />
                        }
                    >
                        {durationText}
                    </AgisticsTooltip>
            }
        </div>
    );
};
