import {
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    InputAdornment,
    Radio,
    RadioGroup,
    Switch,
    Tooltip,
    Typography
} from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { CustomerTier, Features } from 'harmony-constants';
import { getCurrentUnitSystem, L, LANGUAGE_CODES, UNITS } from 'harmony-language';
import PropTypes from 'prop-types';
import React from 'react';
import { VIEW_ALL_COMPANIES } from '../../../../permissions/permissions';
import '../../../../styles/one.css';
import { isIntegerAndMoreThanZeroAndLessThan600 } from '../../../../utils/validation-utils';
import { CircularLoading } from '../../../shared/circular-loading';
import { SelectLanguage } from '../../../shared/inputs/select-language';
import { SelectSiteIssueKeys } from '../../../shared/inputs/select-site-issue-keys';
import { SelectTier } from '../../../shared/inputs/select-tier';
import { SelectTimezone } from '../../../shared/inputs/select-timezone';
import { SelectUnits } from '../../../shared/inputs/select-units';
import { SelectUnitsWeight } from '../../../shared/inputs/select-units-weight';
import { ValidatedTextField } from '../../../shared/inputs/validated-text-field';
import { useFeatures, usePermissions } from '../../../user/selectors/use-permissions';
import { EditEntries } from '../../entries/edit-entries';
import { UnscheduledStopEntries } from '../../entries/unscheduled-stop-entries';
import { kmToMeters, metersToKm, metersToMiles, milesToMeters } from '../../../../utils/unit-conversion';

const classes = {
    input: 'EditCreateCompany-input'
};

const RADIO_OPTIONS = {
    YES: 'yes',
    NO: 'no',
    REQUIRED: 'required'
};

const isValidLatitude = (latitude) => {
    return latitude && (latitude >= -90 && latitude <= 90);
};

const isValidLongitude = (longitude) => {
    return longitude && (longitude >= -180 && longitude <= 180);
};

export const EditCreateCompany = (props) => {
    const { existingCompany, onSubmit, isLoading } = props;
    const [company, setCompany] = React.useState({
        tier: CustomerTier.Freemium,
        etaThresholdMinutes: 30,
        settings: {
            etaDelayThresholdMinutes: 60,
        }
    });
    const canViewCompanies = usePermissions(VIEW_ALL_COMPANIES);
    const hasMobileStopSplit = useFeatures(Features.MobileStopSplit);

    const isFreemium = company.tier === CustomerTier.Freemium;
    const isCarrier = company.isCarrier;
    const unitSystem = getCurrentUnitSystem();

    const unitSystemLabel = unitSystem === UNITS.Metric ? L.unitsMetricDistance() : L.unitsImperialDistance();

    const validLatitude = React.useCallback(() => isValidLatitude(company.latitude),
        [company.latitude]);
    const validLongitude = React.useCallback(() => isValidLongitude(company.longitude),
        [company.longitude]);
    const etaThresholdMinutesError = React.useMemo(() => !isIntegerAndMoreThanZeroAndLessThan600(company.etaThresholdMinutes),
        [company.etaThresholdMinutes]);
    const etaDelayThresholdMinutesError = React.useMemo(() => {
        if (company?.settings?.etaDelayThresholdMinutes || company?.settings?.etaDelayThresholdMinutes === 0) {
            return !isIntegerAndMoreThanZeroAndLessThan600(company.settings.etaDelayThresholdMinutes);
        } else {
            return false
        }
    }, [company?.settings?.etaDelayThresholdMinutes]);

    React.useEffect(() => {
        if (existingCompany) {
            setCompany(existingCompany);
        }
    }, [existingCompany, setCompany]);

    const submitForm = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        onSubmit(company);
    };

    const handleChangeFunc = (key, returnsEvent) => {
        return (x) => {
            const changed = {
                ...company,
                [key]: returnsEvent ? x.target.value : x,
                ...(key === 'tier' && x === CustomerTier.Freemium && { isCarrier: false }),
            }
            setCompany(changed);
        };
    };

    const handleSettingsChange = (key, returnsEvent) => {
        return (x) => {
            const settings = {
                ...company.settings,
                [key]: returnsEvent ? x.target.value : x,
            }
            if (key === 'avoidanceZoneAudioWarningDistanceMeters') {
                settings.avoidanceZoneAudioWarningDistanceMeters = Math.round(x)
            }
            if (key === 'avoidanceZoneAudioWarningResetDistanceMeters') {
                settings.avoidanceZoneAudioWarningResetDistanceMeters = Math.round(x)
            }
            const changed = {
                ...company,
                settings: settings,
            }
            setCompany(changed);
        }
    }

    const handleCheckedChange = (key) => {
        return (x) => {
            const settings = {
                ...company.settings,
                [key]: key === 'driverAssignCompartments'
                    ? x.target.checked ? RADIO_OPTIONS.YES : RADIO_OPTIONS.NO
                    : x.target.checked,
            }
            const changed = {
                ...company,
                settings: settings,
            }
            setCompany(changed);
        }
    }

    const radioGroupControl = (tooltip) => {
        return <Tooltip placement='right' title={tooltip}><Radio /></Tooltip>
    }

    const radioGroupYesNoRequired = () => {
        return <>
            <FormControlLabel value={RADIO_OPTIONS.NO} label={L.no()}
                control={radioGroupControl(L.tooltipDriverInputNotRequested())} />
            <FormControlLabel value={RADIO_OPTIONS.YES} label={L.yes()}
                control={radioGroupControl(L.tooltipDriverInputRequested())} />
            <FormControlLabel value={RADIO_OPTIONS.REQUIRED} label={L.required()}
                control={radioGroupControl(L.tooltipDriverInputRequired())} />
        </>
    }

    return (
        <div>
            {isLoading && <CircularLoading />}
            <form id='edit-create-company-form' name='edit-create-company-form' onSubmit={submitForm}>
                <TextField
                    variant='standard'
                    className={classes.input}
                    disabled={isLoading}
                    label={L.name()}
                    required={true}
                    value={company.name || ''}
                    onChange={handleChangeFunc('name', true)} />
                <SelectLanguage
                    className={classes.input}
                    disabled={isLoading}
                    language={company.language || LANGUAGE_CODES.ENGLISH_US}
                    onChange={handleChangeFunc('language')}
                />
                <SelectTimezone
                    className={classes.input}
                    disabled={isLoading}
                    timezone={company.timezone}
                    onChange={handleChangeFunc('timezone')}
                />
                <SelectUnits
                    className={classes.input}
                    disabled={isLoading}
                    units={company.units}
                    onChange={handleChangeFunc('units')}
                />
                <SelectUnitsWeight
                    className={classes.input}
                    disabled={isLoading}
                    unitsWeight={company.unitsWeight}
                    onChange={handleChangeFunc('unitsWeight')}
                />
                {canViewCompanies && <SelectTier
                    className={classes.input}
                    disabled={isLoading}
                    tier={company.tier}
                    onChange={handleChangeFunc('tier')}
                />
                }
                {canViewCompanies && !isFreemium && <FormControlLabel
                    sx={{ marginBottom: '1rem', justifyContent: 'flex-start' }}
                    label={L.carrier()}
                    control={<Checkbox
                        disabled={isLoading || (existingCompany && company.isCarrier)}
                        checked={company.isCarrier || false}
                        onChange={(x) => {
                            setCompany({ ...company, isCarrier: x.target.checked })
                        }} />
                    }
                />
                }
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <ValidatedTextField
                            fullWidth
                            className={classes.input}
                            disabled={isLoading}
                            label={L.latitude()}
                            required={true}
                            value={company?.latitude || ''}
                            onChange={handleChangeFunc('latitude', true)}
                            validations={[
                                {
                                    isValid: validLatitude,
                                    message: L.invalidLatitude(),
                                }
                            ]}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ValidatedTextField
                            fullWidth
                            className={classes.input}
                            disabled={isLoading}
                            label={L.longitude()}
                            required={true}
                            value={company?.longitude || ''}
                            onChange={handleChangeFunc('longitude', true)}
                            validations={[
                                {
                                    isValid: validLongitude,
                                    message: L.invalidLongitude(),
                                }
                            ]}
                        />
                    </Grid>
                </Grid>
                {!isFreemium && !isCarrier &&
                    <>
                        {existingCompany && <SelectSiteIssueKeys
                            className={classes.input}
                            disabled={isLoading}
                            siteIssueKeys={company.siteIssueKeys}
                            onChange={handleChangeFunc('siteIssueKeys')}
                            cargoTypes={company.cargoTypes}
                        />}
                        <EditEntries isLoading={isLoading}
                            throughputs={company?.throughputs}
                            cargoTypes={company?.cargoTypes}
                            onChange={handleChangeFunc('throughputs')}
                            etaThresholdMinutes={company?.etaThresholdMinutes}
                            etaDelayThresholdMinutes={company?.settings?.etaDelayThresholdMinutes}
                            onEtaThresholdChange={handleChangeFunc('etaThresholdMinutes')}
                            onEtaDelayThresholdChange={handleSettingsChange('etaDelayThresholdMinutes')}
                            etaThresholdMinutesError={etaThresholdMinutesError}
                            etaDelayThresholdMinutesError={etaDelayThresholdMinutesError} />
                        <UnscheduledStopEntries company={company} handleSettingsChange={handleSettingsChange} isLoading={isLoading} />
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography>{L.driverReporting()}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant='standard' component='fieldset'>
                                    <FormLabel component='legend'>{L.actualQuantityOrWeight()}</FormLabel>
                                    <RadioGroup aria-label='requestActualQuantity' name='requestActualQuantity'
                                        value={company?.settings?.driverReportStopActuals || RADIO_OPTIONS.NO}
                                        onChange={handleSettingsChange('driverReportStopActuals', true)}>
                                        {radioGroupYesNoRequired()}
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant='standard' component='fieldset'>
                                    <FormLabel component='legend'>{L.weighStopWeight()}</FormLabel>
                                    <RadioGroup aria-label='requestActualWeight' name='requestActualWeight'
                                        value={company?.settings?.driverReportWeighStopWeight || RADIO_OPTIONS.NO}
                                        onChange={handleSettingsChange('driverReportWeighStopWeight', true)}>
                                        {radioGroupYesNoRequired()}
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            {hasMobileStopSplit &&
                                <Grid item xs={12} sm={6}>
                                    <FormGroup component='fieldset'>
                                        <FormControlLabel component='legend' label={L.enforceSameCustomerSplitLocations()}
                                            control={<Switch
                                                aria-label='mobileSplitStopLocations'
                                                name='mobileSplitStopLocations'
                                                checked={company?.settings?.enforceSameCustomerSplitLocations || false}
                                                onChange={handleCheckedChange('enforceSameCustomerSplitLocations')} />} />
                                    </FormGroup>
                                </Grid>
                            }
                        </Grid>
                        <Divider sx={{ margin: '1rem 0' }} />
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography>{L.otherSettings()}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormGroup component='fieldset'>
                                    <FormControlLabel component='legend' label={L.driverCanStartTransportStopsOutOfSequence()}
                                        control={<Switch
                                            aria-label='driverAllowStopStartOutOfSequence'
                                            name='driverAllowStopStartOutOfSequence'
                                            checked={company?.settings?.driverAllowStopStartOutOfSequence || false}
                                            onChange={handleCheckedChange('driverAllowStopStartOutOfSequence')} />} />
                                </FormGroup>
                                <FormGroup component='fieldset'>
                                    <FormControlLabel component='legend' label={L.driverCanCompleteStopsOutOfSequence()}
                                        control={<Switch
                                            aria-label='driverAllowStopCompletionOutOfSequence'
                                            name='driverAllowStopCompletionOutOfSequence'
                                            checked={company?.settings?.driverAllowStopCompletionOutOfSequence || false}
                                            onChange={handleCheckedChange('driverAllowStopCompletionOutOfSequence')} />} />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormGroup component='fieldset'>
                                    <FormControlLabel component='legend'
                                        label={L.driverCanAssignCompartments()}
                                        control={<Switch
                                            aria-label='assignCompartments'
                                            name='assignCompartments'
                                            checked={company?.settings?.driverAssignCompartments === RADIO_OPTIONS.YES || company?.settings?.driverAssignCompartments === RADIO_OPTIONS.REQUIRED || false}
                                            onChange={handleCheckedChange('driverAssignCompartments')} />} />
                                </FormGroup>
                            </Grid>
                        </Grid>
                        <Divider sx={{ margin: '1rem 0' }} />
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography>{L.avoidanceZoneSettings()}</Typography>
                            </Grid>
                            <Grid item xs={8} sm={6}>
                                <FormGroup component='fieldset'>
                                    <FormControlLabel component='legend' label={L.avoidanceZoneAudioWarning()}
                                        control={<Switch
                                            aria-label='avoidanceZoneAudioWarningEnabled'
                                            name='avoidanceZoneAudioWarningEnabled'
                                            title={L.avoidanceZoneAudioWarningDescription()}
                                            checked={company?.settings?.avoidanceZoneAudioWarningEnabled || false}
                                            onChange={handleCheckedChange('avoidanceZoneAudioWarningEnabled')} />}
                                    />
                                    <Typography>{L.avoidanceZoneAudioWarningDistance()}</Typography>
                                    <TextField
                                        variant='standard'
                                        fullWidth
                                        disabled={isLoading || !company?.settings?.avoidanceZoneAudioWarningEnabled}
                                        id={`default-avoidance-zone-audio-warning-distance`}
                                        label={L.avoidanceZoneAudioWarningDistanceDescription()}
                                        title={L.avoidanceZoneAudioWarningDistanceDescription()}
                                        type='number'
                                        value={+(unitSystem === UNITS.Metric ? metersToKm(company.settings?.avoidanceZoneAudioWarningDistanceMeters) : metersToMiles(company.settings?.avoidanceZoneAudioWarningDistanceMeters)).toFixed(2)}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{
                                            style: {
                                                marginTop: '1.7em',
                                            },
                                            inputProps: { min: 0, step: '0.01' },
                                            endAdornment: <InputAdornment position='end'>{unitSystemLabel}</InputAdornment>
                                        }}
                                        onChange={(e) => {
                                            const val = Number(e.target.value);
                                            let converted = +(unitSystem === UNITS.Metric ? kmToMeters(val) : milesToMeters(val)).toFixed(2);
                                            handleSettingsChange('avoidanceZoneAudioWarningDistanceMeters', false)(converted);
                                        }} />
                                    <Typography>{L.avoidanceZoneAudioWarningResetDistance()}</Typography>
                                    <TextField
                                        variant='standard'
                                        fullWidth
                                        disabled={isLoading || !company?.settings?.avoidanceZoneAudioWarningEnabled}
                                        id={`default-avoidance-zone-audio-warning-reset-distance`}
                                        label={L.avoidanceZoneAudioWarningResetDistanceDescription()}
                                        title={L.avoidanceZoneAudioWarningResetDistanceDescription()}
                                        type='number'
                                        value={+(unitSystem === UNITS.Metric ? metersToKm(company.settings?.avoidanceZoneAudioWarningResetDistanceMeters) : metersToMiles(company.settings?.avoidanceZoneAudioWarningResetDistanceMeters)).toFixed(2)}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{
                                            style: {
                                                marginTop: '1.7em',
                                            },
                                            inputProps: { min: 0, step: '0.01' },
                                            endAdornment: <InputAdornment position='end'>{unitSystemLabel}</InputAdornment>
                                        }}
                                        onChange={(e) => {
                                            const val = Number(e.target.value);
                                            let converted = +(unitSystem === UNITS.Metric ? kmToMeters(val) : milesToMeters(val)).toFixed(2);
                                            handleSettingsChange('avoidanceZoneAudioWarningResetDistanceMeters', false)(converted);
                                        }} />
                                </FormGroup>
                            </Grid>


                        </Grid>
                        <Divider sx={{ margin: '1rem 0' }} />
                    </>
                }
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button type='submit'
                        disabled={isLoading || etaThresholdMinutesError || etaDelayThresholdMinutesError}
                        title='Save'
                        variant='contained'
                        color='primary'>{existingCompany ? L.updateCompany() : L.createCompany()}</Button>
                </div>
            </form>
        </div>
    );
};

EditCreateCompany.propTypes = {
    existingCompany: PropTypes.object,
    onSubmit: PropTypes.func,
    isLoading: PropTypes.bool
};
