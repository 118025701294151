import { UNITS } from 'harmony-language';

export const feetToMeters = (quantity: number) => quantity / 3.281;
export const metersToFeet = (quantity: number) => quantity * 3.281;

export const kgToPounds = (kg: number) => kg * 2.20462;
export const poundsToKg = (pounds: number) => pounds / 2.20462;
// export const weightConversion = (targetUnit, amount) => Math.round(pounds / 2.20462);

export const metersPerSecondToKMPH = (metersPerSecond: number) => Math.round(metersPerSecond * 3.6);
export const metersPerSecondToKmphDisplay = (metersPerSecond: number) => `${metersPerSecondToKMPH(metersPerSecond)} km/h`;
export const metersPerSecondToMPH = (metersPerSecond: number) => Math.round(metersPerSecond * 2.237);
export const metersPerSecondToMphDisplay = (metersPerSecond: number) => `${metersPerSecondToMPH(metersPerSecond)} mph`;
export const convertSpeedToDisplay = (unitSystem?: string) => unitSystem === UNITS.Metric ? metersPerSecondToKmphDisplay : metersPerSecondToMphDisplay;

export const metersToKm = (meters: number) => meters / 1000;
export const metersToMiles = (meters: number) => meters / 1609;

export const kmToMeters = (km: number) => km * 1000;
export const milesToMeters = (miles: number) => miles * 1609.3445;