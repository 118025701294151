import { Button } from '@mui/material';
import { ColumnSort } from '@tanstack/react-table';
import React from 'react';
import { localNow } from '../../../utils/date-time-utils';
import { ReportBase } from '../report-base';
import { ReportFilters } from '../report-filters';
import { ReportRunOptions, type OnTimeReportColumns, type ReportProps } from '../report-types';
import { getLateStopsUrlAndParams, getOnTimeUrlAndParams } from '../report-utils';
import { useReports } from '../use-reports';
import { useOnTimeColumns } from './on-time-columns';
import { OnTimeFilters } from './on-time-filters';
import { L } from 'harmony-language';

export enum OnTimeReportMode {
    'day' = 'day',
    'week' = 'week',
    'month' = 'month',
}

const defaultSort: ColumnSort = {
    id: 'customer_name',
    desc: false,
}

export type CargoFilterMode = 'cargoType' | 'cargoCategory';

export const OnTimeReport: React.FC<ReportProps> = (props) => {
    const { isAdmin, companyId } = props;
    const [organizationId, setOrganizationId] = React.useState<number>(isAdmin ? 0 : companyId);
    const [startDate, setStartDate] = React.useState<string>(localNow().weekday(0).startOf('day').toISOString());
    const [endDate, setEndDate] = React.useState<string>(localNow().weekday(6).endOf('day').toISOString());
    const [mode, setMode] = React.useState<OnTimeReportMode>(OnTimeReportMode.week);
    
    // Filter states
    const [stopTypes, setStopTypes] = React.useState<string[]>([]);
    const [customerIds, setCustomerIds] = React.useState<number[]>([]);
    const [cargoFilterMode, setCargoFilterMode] = React.useState<CargoFilterMode>('cargoType');
    const [cargoTypeIds, setCargoTypeIds] = React.useState<number[]>([]);
    const [cargoCategories, setCargoCategories] = React.useState<string[]>([]);

    const columns = useOnTimeColumns(isAdmin);
    const { reportUrl: onTimeUrl, reportParams: onTimeParams } = getOnTimeUrlAndParams(
        isAdmin, organizationId, startDate, endDate, mode, stopTypes, customerIds, cargoFilterMode, cargoTypeIds, cargoCategories
    );
    const { data, runReport: baseRunReport, table, isLoading } = useReports<OnTimeReportColumns>(defaultSort, columns, onTimeUrl, onTimeParams, false);

    const { reportUrl: lateStopsUrl, reportParams: lateStopsParams } = getLateStopsUrlAndParams(
        isAdmin, organizationId, startDate, endDate, stopTypes, customerIds, cargoTypeIds
    );

    function runReport(runOptions: ReportRunOptions) {
        baseRunReport(runOptions);
        if (cargoFilterMode === 'cargoCategory') {
            table.setColumnVisibility({ cargo_type_id: false, cargo_category: true });
        }
        else {
            table.setColumnVisibility({ cargo_type_id: true, cargo_category: false });
        }
    }

    return (
        <>
            <ReportFilters
                isAdmin={isAdmin}
                organizationId={organizationId}
                setOrganizationId={setOrganizationId}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                runReport={runReport}
                mode={mode}
                setMode={setMode}
            >
                <OnTimeFilters
                    isAdmin={isAdmin}
                    stopTypes={{ state: stopTypes, setState: setStopTypes }}
                    customerIds={{ state: customerIds, setState: setCustomerIds }}
                    cargoFilterMode={{ state: cargoFilterMode, setState: setCargoFilterMode }}
                    cargoTypeIds={{ state: cargoTypeIds, setState: setCargoTypeIds }}
                    cargoCategories={{ state: cargoCategories, setState: setCargoCategories }}
                />
            </ReportFilters>
            <ReportBase
                table={table}
                hasData={Boolean(data)}
                isLoading={isLoading}
                runReport={runReport}
                confined={true}
                additionalCsvButton={
                    <Button
                        size='small'
                        variant='outlined'
                        style={{ marginLeft: '10px' }}
                        onClick={() => {
                            window.location.href = `${lateStopsUrl.toString()}?${lateStopsParams.toString()}`;
                        }}
                        disabled={!table.getRowCount()}
                    >
                        {`${L.lateStops()} CSV`}
                    </Button>
                }
            />
        </>
    );
};
