import React from 'react';
import { getWorkingWeeks } from '../../../../../utils/date-time-utils';
import PropTypes from 'prop-types';
import { L } from 'harmony-language';
import { TextFieldWithValidation } from '../../../../shared/inputs/text-field-with-validation';

export const WeekOf = (props) => {
    const { week, setWeek } = props;

    const weekChange = (event) => {
        setWeek(event.target.value);
    };

    const menuItems = getWorkingWeeks(13).map(week => ({
        id: week.sundayIso,
        value: week.sundayIso,
        label: week.label
    }));

    return (
        <TextFieldWithValidation
            autoFocus={true}
            className='bulk-config-week'
            items={menuItems}
            label={L.bulkSelectWeekOf()}
            onChange={weekChange}
            required={true}
            value={week}/>
    );
};

WeekOf.propTypes = {
    week: PropTypes.string,
    setWeek: PropTypes.func,
};
