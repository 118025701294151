import React, { useState } from 'react';
import { Stop } from '../../../../types';
import { PerformanceExpander } from '../../drafts-loads-table-cells/performance-expander';
import { L } from 'harmony-language';
import { DraftLoadStopProvider } from '../../hooks/draft-load-stop-context';
import { EditableStopLocation } from '../../drafts-loads-table-cells/stops/editable/editable-stop-location';
import { EditableStopSublocations } from '../../drafts-loads-table-cells/stops/editable/editable-stop-sublocations';
import { EditableStopTime } from '../../drafts-loads-table-cells/stops/editable/editable-stop-time';
import { stopGridTemplateColumnsGenerator } from '../../utils/drafts-loads-utils';
import { EditableStopQuantity } from '../../drafts-loads-table-cells/stops/editable/editable-stop-quantity';
import { EditableStopWeight } from '../../drafts-loads-table-cells/stops/editable/editable-stop-weight';
import { EditableStopCargoType } from '../../drafts-loads-table-cells/stops/editable/editable-stop-cargo-type';
import { EditableStopText } from '../../drafts-loads-table-cells/stops/editable/editable-stop-text';
import { EditableStopAutoCalc } from '../../drafts-loads-table-cells/stops/editable/editable-stop-auto-calc';
import { EditableStopType } from '../../drafts-loads-table-cells/stops/editable/editable-stop-type';
import { EditableStopCompartments } from '../../drafts-loads-table-cells/stops/editable/editable-stop-compartments';
import { CompartmentsHeader } from '../../../shared/load-table/compartments-header';
import { EditableStopSplit } from '../../drafts-loads-table-cells/stops/editable/editable-stop-split';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { EditableStopDelete } from '../../drafts-loads-table-cells/stops/editable/editable-stop-delete';
import { EditableStopOrderNumber } from '../../drafts-loads-table-cells/stops/editable/editable-stop-order-number';
import { PerformanceDragIcon } from '../../drafts-loads-table-cells/performance-icons';
import { EditableStopArrivalTime } from '../../drafts-loads-table-cells/stops/editable/editable-stop-arrival-time';
import { EditableStopNotes } from '../../drafts-loads-table-cells/stops/editable/editable-stop-notes';
import { DisplayStopTime } from '../../drafts-loads-table-cells/stops/display/display-stop-time';
import { useCarrier } from '../../../user/selectors/use-permissions';
import { EditableUnitSwitcher } from '../../drafts-loads-table-cells/stops/unit-switcher';
import { CargoTypeMode } from './cargo-type-mode-proxy';
import { useDraftLoadContext } from '../../hooks/draft-load-context';

export const editableStopInputProps = { style: { fontSize: '.875rem' } };

interface EditableStopTableRowProps {
    isDrafts: boolean;
    stop: Stop;
    trailerId: number | null; // compartments needs this passed down
    index: number;
}

export const EditableStopTableRow: React.FC<EditableStopTableRowProps> = (props) => {
    const { isDrafts, stop, trailerId, index } = props;
    const { getUnitsState } = useDraftLoadContext();
    const [secondRowVis, setSecondRowVis] = useState(false);
    const isCarrier = useCarrier();

    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id: stop.sequence,
        disabled: isCarrier,
    });
    const style = {
        transform: CSS.Translate.toString(transform),
        transition: transition,
    };

    const isQuantity = getUnitsState(index) === CargoTypeMode.Quantity;

    return (
        <DraftLoadStopProvider inputStop={stop}>
            <div
                key={stop.id}
                ref={setNodeRef}
                // className='lt-row-stop lt-row-stop-item'
                className='lt-row-stop'
                style={{
                    ...style,
                    gridTemplateColumns: stopGridTemplateColumnsGenerator(isDrafts, true),
                }}
            >
                <PerformanceDragIcon
                    isDragging={isDragging}
                    listeners={listeners}
                    attributes={attributes}
                    isCarrier={isCarrier}
                />
                <div className='lt-cell lt-cell-sub'>
                    <EditableStopType stop={stop} />
                </div>
                <div className='lt-cell lt-cell-sub'>
                    <EditableStopCargoType stop={stop} index={index} />
                </div>
                <div className='lt-cell lt-cell-sub'>
                    <EditableStopLocation stop={stop} />
                </div>
                <div className='lt-cell lt-cell-sub'>
                    <EditableStopSublocations stop={stop} />
                </div>

                <div className='lt-cell lt-cell-sub'>
                    <EditableStopArrivalTime stop={stop} isDrafts={isDrafts} />
                </div>
                <div className='lt-cell lt-cell-sub'>
                    <EditableStopAutoCalc stop={stop} />
                </div>
                {isDrafts ? null : <div className='lt-cell lt-cell-sub'><DisplayStopTime dateTime={stop.completedAt} /></div>}

                {isQuantity ? (
                    <>
                        <div className='lt-cell lt-cell-sub'>
                            <EditableStopQuantity
                                stop={stop}
                                isValidated={!isDrafts}
                                property='quantity'
                            />
                        </div>
                        {isDrafts ? null : <div className='lt-cell lt-cell-sub'>
                            <EditableStopQuantity
                                stop={stop}
                                isValidated={false}
                                property='actualQuantity'
                            />
                        </div>}
                    </>
                ) : (
                    <>
                        <div className='lt-cell lt-cell-sub'>
                            <EditableStopWeight
                                stop={stop}
                                isValidated={!isDrafts}
                                property='weight'
                            />
                        </div>
                        {isDrafts ? null : <div className='lt-cell lt-cell-sub'>
                            <EditableStopWeight
                                stop={stop}
                                isValidated={false}
                                property='actualWeight'
                            />
                        </div>}
                    </>
                )}

                <div className='lt-cell lt-cell-sub'>
                    <EditableUnitSwitcher
                        stop={stop}
                        index={index}
                    />
                </div>

                <div className='lt-cell lt-cell-sub'>
                    <EditableStopSplit stop={stop} />
                </div>

                <div className='lt-cell lt-cell-sub'>
                    <EditableStopOrderNumber stop={stop} />
                </div>
                <div className='lt-cell lt-cell-sub'>
                    <EditableStopText stop={stop} property='productionPlan' />
                </div>

                <div className='lt-cell lt-cell-sub'>
                    <EditableStopNotes stop={stop} />
                </div>

                <PerformanceExpander
                    title={stop?.id?.toString()}
                    expanded={secondRowVis}
                    onChange={() => {
                        setSecondRowVis(!secondRowVis);
                    }}
                />
                <div className='lt-cell lt-cell-sub'>
                    <EditableStopDelete stop={stop} index={index} />
                </div>
            </div>
            {secondRowVis ? <>
                <div className='lt-row-stop lt-row-stop-hidden lt-row-stop-header'>
                    <div>{L.readyTime()}</div>
                    <div>{<CompartmentsHeader />}</div>
                    <div>{L.lotId()}</div>
                    <div>{L.requiredBegin()}</div>
                    <div>{L.requiredEnd()}</div>
                    <div>{L.customerOrderNumber()}</div>
                </div>
                <div className='lt-row-stop lt-row-stop-hidden'>
                    <div className='lt-cell lt-cell-sub'>
                        <EditableStopTime
                            stop={stop}
                            property='readyTime'
                        />
                    </div>
                    <div className='lt-cell lt-cell-sub'>
                        <EditableStopCompartments
                            stop={stop}
                            trailerId={trailerId}
                        />
                    </div>
                    <div className='lt-cell lt-cell-sub'>
                        <EditableStopText stop={stop} property='lotId' />
                    </div>
                    <div className='lt-cell lt-cell-sub'>
                        <EditableStopTime
                            stop={stop}
                            property='requiredBegin'
                        />
                    </div>
                    <div className='lt-cell lt-cell-sub'>
                        <EditableStopTime
                            stop={stop}
                            property='requiredEnd'
                        />
                    </div>
                    <div className='lt-cell lt-cell-sub' style={{ maxWidth: '30%' }}>
                        <EditableStopText stop={stop} property='customerOrderNumber' />
                    </div>
                </div>
            </> : null}
        </DraftLoadStopProvider>
    );
};
