import React, { useEffect, useRef } from 'react';

export const useDebounce = (value: string, delay = 500) => {
    const [debouncedValue, setDebouncedValue] = React.useState(value);
    const [isDebouncing, setIsDebouncing] = React.useState(false);
    const firstRender = useRef(true);

    useEffect(() => {
        if (!firstRender.current) {
            setIsDebouncing(true);
            const handler = setTimeout(() => {
                setDebouncedValue(value);
                setIsDebouncing(false);
            }, delay);

            return () => {
                clearTimeout(handler);
            };
        }
    }, [value, delay]);

    useEffect(() => {
        firstRender.current = false;
    }, []);

    return { debouncedValue, isDebouncing };
}
