import { autoUpdate, offset, Placement, useFloating, useHover, useInteractions } from '@floating-ui/react';
import React, { ReactNode, useState } from 'react';

interface AgisticsTooltipProps {
    tooltip: ReactNode;
    children: ReactNode;
    placement?: Placement;
}

// An attempt at a better performing tooltip.
// it still adds more performance overhead then I would have liked, but is better
// performing then MUI Tooltip
// https://floating-ui.com/docs/useFloating
export const AgisticsTooltip: React.FC<AgisticsTooltipProps> = (props) => {
    const { tooltip, children, placement = 'right' } = props;

    const [isOpen, setIsOpen] = useState(false);
    const { refs, floatingStyles, context } = useFloating({
        placement: placement,
        open: isOpen,
        onOpenChange: setIsOpen,
        whileElementsMounted: autoUpdate,
        middleware: [offset(6)],
    });

    const hover = useHover(context);

    const { getReferenceProps, getFloatingProps } = useInteractions([
        hover,
    ]);

    return (
        <>
            <div
                ref={refs.setReference}
                // make so tooltips show correctly on content that does not take up all the space
                // but also not display at end of long hidden content
                // might need to be optionally passed, not sure right now
                style={{
                    width: 'fit-content',
                    maxWidth: '100%',
                }}
                {...getReferenceProps()}
            >
                {children}
            </div>
            {isOpen && (
                <div
                    ref={refs.setFloating}
                    className='agistics-tooltip'
                    style={floatingStyles}
                    {...getFloatingProps()}
                >
                    {tooltip}
                </div>
            )}
        </>
    );
};
