import { flexRender, Table } from '@tanstack/react-table';
import React from 'react';
import { Load } from '../../../types';
import { STATUS_COLUMN } from '../hooks/use-draft-load-columns';
import { gridTemplateColumnsGenerator } from '../utils/drafts-loads-utils';

interface LoadTableHeaderProps {
    isDrafts: boolean;
    table: Table<Load>;
    showEstimatedTotalTripTime: boolean;
    showTractor: boolean;
    showTrailer: boolean;
}

export const LoadTableHeader: React.FC<LoadTableHeaderProps> = (props) => {
    const { isDrafts, table, showEstimatedTotalTripTime, showTractor, showTrailer } = props;

    return (
        <div className='lt-header'>
            {table.getHeaderGroups().map(headerGroup => (
                <div
                    key={headerGroup.id}
                    className='lt-row'
                    style={{ gridTemplateColumns: gridTemplateColumnsGenerator(isDrafts, showEstimatedTotalTripTime, showTractor, showTrailer) }}
                >
                    {headerGroup.headers.map(header => {
                        const isStatusColumn = header.id === STATUS_COLUMN;

                        return (
                            <div
                                key={header.id}
                                style={isStatusColumn ? { gridColumn: 'span 2' } : undefined}
                                className='lt-header-item'
                            >
                                {flexRender(header.column.columnDef.header, header.getContext())}
                            </div>
                        );
                    })}
                </div>
            ))}
        </div>
    );
};
