import React, { FC } from 'react';
import { useDraftLoadContext } from '../../hooks/draft-load-context';
import { L } from 'harmony-language';

export const OrderNumberHeader: FC = () => {
    const { changeOrderNumber, setChangeOrderNumber } = useDraftLoadContext();

    return (
        <div onClick={() => setChangeOrderNumber(changeOrderNumber + 1)}>
            {L.orderNumber()}
        </div>
    );
};
