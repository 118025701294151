import React from 'react';
import { HereMapRectangle } from './here-map-rectangle';
import { useOrgLocationColorProxy } from './use-org-location-color-proxy';
import { useOrganizationQuery } from '../../api/queries/use-organization-query';
import { OrganizationLocation } from '../../types';
import { OrgQueryKeys } from '../../api/config';

interface OrganizationLocationBoxProps {
    organizationLocation: OrganizationLocation;
}

const OrganizationLocationBox: React.FC<OrganizationLocationBoxProps> = (props) => {
    const { organizationLocation } = props;

    return (
        <HereMapRectangle
            key={organizationLocation.id}
            // @ts-ignore - this is changed to required in a branch that is not yet merged
            pointA={{ lat: organizationLocation.customGeofence.bbox.topLeft.latitude, lng: organizationLocation.customGeofence.bbox.topLeft.longitude }}
            // @ts-ignore - this is changed to required in a branch that is not yet merged
            pointB={{ lat: organizationLocation.customGeofence.bbox.bottomRight.latitude, lng: organizationLocation.customGeofence.bbox.bottomRight.longitude }}
            strokeColor={useOrgLocationColorProxy()[organizationLocation.orgLocationType.id].stroke}
            fillColor={useOrgLocationColorProxy()[organizationLocation.orgLocationType.id].fill}
            type='avoidance' // this should probably be renamed, type decides if when you hit the recenter map button if the rectangle should be included in the recenter math
            changeable={false}
        >
            <div>
                <span className='map-tooltip-label-name'>{organizationLocation.name}</span>
                {organizationLocation.description && <span className='map-tooltip-label-desc'>{organizationLocation.description}</span>}
            </div>
        </HereMapRectangle>
    )
}

interface OrganizationLocationBoxesProps {
    ids?: number[];
}

export const OrganizationLocationBoxes: React.FC<OrganizationLocationBoxesProps> = (props) => {
    const { ids  } = props;
    const organizationLocationsQuery = useOrganizationQuery<OrganizationLocation[]>(OrgQueryKeys.locations);

    if (!organizationLocationsQuery.isSuccess) {
        return null;
    } else {
        const organizationLocations = organizationLocationsQuery.data;

        if (ids) {
            const locationsToShow = organizationLocations.filter(x => ids.includes(x.id));

            return (
                locationsToShow.map(organizationLocation => {
                    return <OrganizationLocationBox
                        key={organizationLocation.id}
                        organizationLocation={organizationLocation}
                    />
                })
            );
        } else {
            return (
                organizationLocations.map(organizationLocation => {
                    return <OrganizationLocationBox
                        key={organizationLocation.id}
                        organizationLocation={organizationLocation}
                    />
                })
            );
        }
    }
};
