import React, { FC } from 'react';
import { Load } from '../../../../../types';
import { orderToLotIds } from '../../../../../utils/data-mapping-utils';

interface LoadLotIdsProps {
    load: Load;
}

export const LoadLotIds: FC<LoadLotIdsProps> = (props) => {
    const { load } = props;
    const lotIds = orderToLotIds(load);

    return (
        <div>
            {lotIds.map((lotId, i) => {
                return (
                    <div key={i}>
                        {lotId}
                    </div>
                );
            })}
        </div>
    );
};
