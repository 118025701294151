import { isAfter } from '../../../../utils/date-time-utils';
import { STOP_TYPES } from '../../../../constants/constants';
import { L } from 'harmony-language';

export const areStopTypeWeightsEqual = (stops, convertFromGrams, weightPrecision) => {
    const sumTotal = stops.sum((stop) => {
        const weight = convertFromGrams(stop.weight || 0);

        return stop.type === STOP_TYPES().Origin.key ? weight : -1 * weight;
        });
    // Math.abs then toFixed will account for floating point precision overflow errors
    return Math.abs(sumTotal.toFixed(weightPrecision)) === 0;
}

export const validateStopQuantitiesAndWeights = (stops, convertFromGrams, weightPrecision) => {
    const filteredStops = stops.filter(s => s.type === STOP_TYPES().Origin.key || s.type === STOP_TYPES().Destination.key);
    const errors = filteredStops.filter(s => !s.quantity && !s.weight).map(s => L.loadErrorMissingStopQuantityOrWeight(s.sequence));

    // quantities
    const values = filteredStops.reduce((counts, stop) => {
        return {
            ...counts,
            [stop.type]: (counts[stop.type] || 0) + stop.quantity
        };
    }, {
        [STOP_TYPES().Origin.key]: 0,
        [STOP_TYPES().Destination.key]: 0,
    });

    const allEqual = Object.values(values).every((v, i, arr) => {
        return v === arr[0];
    });

    if (!allEqual) {
        errors.push(L.loadErrorInvalidQuantities());
    }

    // weights
    const weightsAllEqual = areStopTypeWeightsEqual(filteredStops, convertFromGrams, weightPrecision);

    if (!weightsAllEqual) {
        errors.push(L.loadErrorInvalidWeights());
    }

    return errors;
}

export function validateStopTimes(stops) {
    const errors = stops.filter(s => !s.arrivalTime).map(s => L.loadErrorMissingStopTime(s.sequence));

    let i;

    for (i = 0; i < stops.length - 1; i++) {
        const stopTime = stops[i].arrivalTime;
        const nextStopTime = stops[i + 1].arrivalTime;

        if (stopTime && nextStopTime && isAfter(stopTime, nextStopTime)) {
            break;
        }
    }

    if (i !== stops.length - 1) {
        errors.push(L.loadErrorStopTimesAreOutOfOrderAt(stops[i].sequence));
    }
    return errors;
}

function validateStopOriginsAndDestinations(stops) {
    const errors = [];

    if (!stops.some(s => s.type === STOP_TYPES().Origin.key)) {
        errors.push(L.loadErrorMissingOrigin());
    }
    if (!stops.some(s => s.type === STOP_TYPES().Destination.key)) {
        errors.push(L.loadErrorMissingDestination());
    }

    const destinationIndex = stops.findIndex(s => s.type === STOP_TYPES().Destination.key);
    const originIndex = stops.findIndex(s => s.type === STOP_TYPES().Origin.key);

    if ((destinationIndex !== -1 && originIndex !== -1) && destinationIndex < originIndex) {
        errors.push(L.loadErrorOriginMustComeBeforeDestination());
    }

    return errors;
}

function validateStopLocations(stops) {
    return stops.filter(s => !s.organizationLocationId).map(s => L.loadErrorMissingStopLocation(s.sequence));
}

function validateStopTypes(stops) {
    return stops.filter(s => !s.type).map(s => L.loadErrorMissingStopType(s.sequence));
}

export const validateDraft = (draft, convertFromGrams, weightPrecision) => {
    const sorted = draft?.stops?.sortBy(s => s.sequence);

    if (!sorted?.length) {
        return []; 
    }
    return validateStopOriginsAndDestinations(sorted)
        .concat(validateStopLocations(sorted))
        .concat(validateStopTypes(sorted))
        .concat(validateStopQuantitiesAndWeights(sorted, convertFromGrams, weightPrecision))
        .concat(validateStopTimes(sorted));
};

export const isDraftValid = (draft, convertFromGrams, weightPrecision) => {
    if(!draft?.stops?.length){
        return false;
    }

    return validateDraft(draft, convertFromGrams, weightPrecision).length === 0;
};