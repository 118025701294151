import React, { useMemo } from 'react';
import { L } from 'harmony-language';
import { Grid } from '@mui/material';
import { AgisticsMultiSelectWithPrefs } from '../../shared/multi-select/agistics-multi-select-with-prefs';
import { useOrganizationQuery } from '../../../api/queries/use-organization-query';
import { OrganizationLocation } from '../../../types';
import { OrgQueryKeys } from '../../../api/config';
import { toSiteDisplayName } from '../../../utils/data-mapping';

interface SplitStopFiltersProps {
    isAdmin: boolean;
    locationIds: number[];
    setLocationIds: React.Dispatch<React.SetStateAction<number[]>>;
}

export const SplitStopFilters: React.FC<SplitStopFiltersProps> = (props) => {
    const { isAdmin, locationIds, setLocationIds } = props;
    const { data: organizationLocations = [] } = useOrganizationQuery<OrganizationLocation[]>(OrgQueryKeys.locations, { enabled: !isAdmin });

    const orgLocationsMultiItems = organizationLocations.map(x => ({
        id: x.id,
        value: toSiteDisplayName(x.name, x.description)
    }));

    return (
        !isAdmin && <Grid container sx={{ width: '20rem' }}>
            <Grid item xs={12}>
                <AgisticsMultiSelectWithPrefs
                    selectedIds={locationIds}
                    setSelectedIds={setLocationIds}
                    items={orgLocationsMultiItems}
                    label={L.locations()}
                    prefKey='splitStopLocations'
                    removeMode={true}
                />
            </Grid>
        </Grid>
    );
};
