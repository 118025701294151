import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { L } from 'harmony-language';
import { QueryKeys } from '../../config';
import { useSnackbar } from '../../../components/notifications/use-snackbar';
import { Load } from '../../../types';
import { useDraftsKey } from '../../config-hooks';

const publishDrafts = async (drafts: Load[]) => {
    return (await axios.put(`${API_BASE}/api/orders`, drafts.map(d => ({ ...d, isDraft: false })))).data;
}

export const usePublishDraftsMutation = () => {
    const snackbar = useSnackbar();
    const queryKey = useDraftsKey();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: publishDrafts,
        onMutate: async (drafts) => {
            await queryClient.cancelQueries(queryKey);
            const previousCache = queryClient.getQueryData<Load[]>(queryKey);

            if (previousCache) {
                const filteredDrafts = previousCache.filter(draft => !drafts.some(d => d.id === draft.id));
                queryClient.setQueryData(queryKey, filteredDrafts);
            }

            return previousCache;
        },
        onError: (error, drafts, context) => {
            if (context) {
                queryClient.setQueryData<Load[]>(queryKey, context);
            }
        },
        onSuccess: (data) => {
            snackbar(L.bulkScheduleSnackbarSuccess(data.length));
        },
        onSettled: () => {
            // queryClient.invalidateQueries(queryKey);
            queryClient.invalidateQueries([QueryKeys.productionCardGroups]);
        },
    });

    return mutation;
};
