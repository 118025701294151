import React, { FC } from 'react';
import { Select } from './select';
import { L } from 'harmony-language';

interface SelectDraftsLoadsDefaultSortProps {
    desc: boolean;
    onChange: (b: boolean) => void;
}

export const SelectDraftsLoadsDefaultSort: FC<SelectDraftsLoadsDefaultSortProps> = (props) => {
    const { desc, onChange } = props;

    const menuItems = [
        { id: 'asc', value: false, label: L.ascending() },
        { id: 'desc', value: true, label: L.descending() },
    ];

    const item = menuItems.find(i => i.value === desc);

    return (
        <Select
            label={L.defaultSort()}
            list={menuItems}
            item={item}
            onChange={(e) => {
                onChange(e.value);
            }}
        />
    );
};
