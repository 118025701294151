import React from 'react';
import { flexRender, Row } from '@tanstack/react-table';
import { FC } from 'react';
import { Load } from '../../../../types';
import { gridTemplateColumnsGenerator } from '../../utils/drafts-loads-utils';
import { useAgisticsContext } from '../../../../app/agistics-context';
import { AgisticsTooltip } from '../../../shared/agistics-tooltip';
import { L } from 'harmony-language';

interface LoadTableRowProps {
    isDrafts: boolean;
    showEstimatedTotalTripTime: boolean;
    showTractor: boolean;
    showTrailer: boolean;
    row: Row<Load>;
}

export const LoadTableRow: FC<LoadTableRowProps> = (props) => {
    const { isDrafts, showEstimatedTotalTripTime, showTractor, showTrailer, row } = props;
    const { socketStopIds } = useAgisticsContext();
    const rowStopIds = row.original.stops.map(x => x.id);
    const socketHasUpdatedData = rowStopIds.some(rowId => socketStopIds.includes(rowId));

    if (socketHasUpdatedData) {
        return (
            <AgisticsTooltip
                tooltip={L.socketRequestRefresh()}
                placement='top'
            >
                <LoadTableRowInternal
                    isDrafts={isDrafts}
                    showEstimatedTotalTripTime={showEstimatedTotalTripTime}
                    showTractor={showTractor}
                    showTrailer={showTrailer}
                    row={row}
                    socketHasUpdatedData={socketHasUpdatedData}
                />
            </AgisticsTooltip>
        );
    } else {
        return (
            <LoadTableRowInternal
                isDrafts={isDrafts}
                showEstimatedTotalTripTime={showEstimatedTotalTripTime}
                showTractor={showTractor}
                showTrailer={showTrailer}
                row={row}
                socketHasUpdatedData={socketHasUpdatedData}
            />
        );
    }
}

interface LoadTableRowInternalProps {
    isDrafts: boolean;
    showEstimatedTotalTripTime: boolean;
    showTractor: boolean;
    showTrailer: boolean;
    row: Row<Load>;
    socketHasUpdatedData: boolean;
}

const LoadTableRowInternal: FC<LoadTableRowInternalProps> = (props) => {
    const { isDrafts, showEstimatedTotalTripTime, showTractor, showTrailer, row, socketHasUpdatedData } = props;

    return (
        <div
            className='lt-row'
            style={{
                gridTemplateColumns: gridTemplateColumnsGenerator(isDrafts, showEstimatedTotalTripTime, showTractor, showTrailer),
                backgroundColor: socketHasUpdatedData ? '#d32f2f80' : undefined,
            }}
        >
            {row.getVisibleCells().map(cell => (
                <div
                    key={cell.id}
                    className='lt-cell lt-cell-sub'
                >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </div>
            ))}
        </div>
    );
}
