import React from 'react';
import { Load } from '../../../../types';
import { DisplayStopTableRow } from './display-stop-table-row';

interface DisplayLoadStopsProps {
    isDrafts: boolean;
    load: Load;
}

export const DisplayLoadStops: React.FC<DisplayLoadStopsProps> = (props) => {
    const { isDrafts, load } = props;

    return (
        <>
            {load.stops.map(stop => {
                return (
                    <DisplayStopTableRow
                        key={stop.id}
                        isDrafts={isDrafts}
                        stop={stop}
                        trailerId={load.trailerId}
                    />
                );
            })}
        </>
    );
};
