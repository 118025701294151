import { roundLegTimeMinutes } from './rounding-utils';
import { CargoTypeMode, cargoTypeModeProxyNullHandler } from '../components/drafts-loads/table-components/stops/cargo-type-mode-proxy';

export const selectApplicableThroughput = (stop, throughputData) => {
    return throughputData?.filter(t => stop.type === t.stopType)?.[0]; // TODO: become aware of multiple throughputs
};

export const applyThroughput = (stop, throughput) => {
    let durationMinutes = 0;

    if (stop.type === throughput?.stopType) {
        const baselineLoadingMinutes = throughput?.baselineLoadingMinutes || 0;
        const throughputMinutes = (throughput?.cargoUnitsPerMinute && Boolean(stop.quantity)) ? Math.ceil(stop.quantity / throughput.cargoUnitsPerMinute) : 0;
        const weightThroughputMinutes = (throughput?.weightPerMinute && Boolean(stop.weight)) ? Math.ceil(stop.weight / throughput.weightPerMinute) : 0;
        const cargoMode = cargoTypeModeProxyNullHandler(stop.cargoTypeId);
        const appliedMinutes = cargoMode === CargoTypeMode.Quantity ? throughputMinutes : weightThroughputMinutes;

        durationMinutes = roundLegTimeMinutes(baselineLoadingMinutes + appliedMinutes);
    }

    return { ...stop, durationMinutes };
};

export const withThroughputs = (throughputs) => (stop) => {
    if (!throughputs) {
        return stop;
    }
    //get applicable throughput from most specific to least.  This is convoluted
    //because now you can have either baselineLoadingMinutes or cargoUnitsPerMinute
    //being nullable, so a more specific throughput might be used for one, and then
    //have to fall back to a less specific throughput for the other property.
    const locCandidates = throughputs.filter(t => t.loadingEntityType === 'Location' &&
                                                  t.loadingEntityId === stop.organizationLocationId &&
                                                  t.stopType === stop.type);
    const orgCandidates = throughputs.filter(t => t.loadingEntityType === 'Organization' &&
                                                  t.stopType === stop.type);
    const locBaselineCandidates = locCandidates.filter(t => t.baseLineLoadingMinutes !== null);
    const orgBaselineCandidates = orgCandidates.filter(t => t.baseLineLoadingMinutes !== null);
    const locCargoUnitCandidates = locCandidates.filter(t => t.cargoUnitsPerMinute !== null);
    const orgCargoUnitCandidates = orgCandidates.filter(t => t.cargoUnitsPerMinute !== null);
    const locWeightCandidates = locCandidates.filter(t => t.weightPerMinute !== null);
    const orgWeightCandidates = orgCandidates.filter(t => t.weightPerMinute !== null);

    const applicableBaselineLoadingThroughput = (
        locBaselineCandidates.find(t => t.cargoTypeId === stop.cargoTypeId) ||
        locBaselineCandidates.find(t => t.cargoTypeId === null) ||
        orgBaselineCandidates.find(t => t.cargoTypeId === stop.cargoTypeId) ||
        orgBaselineCandidates.find(t => t.cargoTypeId === null)
    )

    const applicableCargoUnitsThroughput = (
        locCargoUnitCandidates.find(t => t.cargoTypeId === stop.cargoTypeId) ||
        locCargoUnitCandidates.find(t => t.cargoTypeId === null) ||
        orgCargoUnitCandidates.find(t => t.cargoTypeId === stop.cargoTypeId) ||
        orgCargoUnitCandidates.find(t => t.cargoTypeId === null)
    )

    const applicableWeightThroughput = (
        locWeightCandidates.find(t => t.cargoTypeId === stop.cargoTypeId) ||
        locWeightCandidates.find(t => t.cargoTypeId === null) ||
        orgWeightCandidates.find(t => t.cargoTypeId === stop.cargoTypeId) ||
        orgWeightCandidates.find(t => t.cargoTypeId === null)
    )

    //construct a throughput that takes both "most applicable" properties
    const throughput = {
        stopType: stop.type,
        baselineLoadingMinutes: applicableBaselineLoadingThroughput?.baselineLoadingMinutes,
        cargoUnitsPerMinute: applicableCargoUnitsThroughput?.cargoUnitsPerMinute,
        weightPerMinute: applicableWeightThroughput?.weightPerMinute,
    }

    return applyThroughput(stop, throughput);
};