import React from 'react';
import { Row } from '@tanstack/react-table';
import { Load } from '../../../../types';
import { FC } from 'react';
import { DraftLoadProvider } from '../../hooks/draft-load-context';
import { LoadTableRow } from './load-table-row';
import { LoadTableStops } from '../stops/load-table-stops';
import { EditableLoadTableRow } from './editable-load-table-row';
import { useDraftsLoadsContext } from '../../drafts-loads-context';

interface ConditionalLoadTableRowProps {
    isDrafts: boolean;
    showEstimatedTotalTripTime: boolean;
    showTractor: boolean;
    showTrailer: boolean;
    isRowExpanded: boolean;
    isRowEditable: boolean;
    row: Row<Load>;
}

// the idea behind this is only loading up DraftLoadProvider when necessary
// which is when the load is editable, mainly for performance reasons
export const ConditionalLoadTableRow: FC<ConditionalLoadTableRowProps> = (props) => {
    const { isDrafts, showEstimatedTotalTripTime, showTractor, showTrailer, isRowExpanded, isRowEditable, row } = props;

    if (isRowEditable) {
        return (
            <EditableLoadTableRowWithProvider
                isDrafts={isDrafts}
                showEstimatedTotalTripTime={showEstimatedTotalTripTime}
                showTractor={showTractor}
                showTrailer={showTrailer}
                isRowExpanded={isRowExpanded}
                row={row}
            />
        );
    }

    return (
        <>
            <LoadTableRow
                isDrafts={isDrafts}
                showEstimatedTotalTripTime={showEstimatedTotalTripTime}
                showTractor={showTractor}
                showTrailer={showTrailer}
                row={row}
            />
            {isRowExpanded && (
                <LoadTableStops
                    isDrafts={isDrafts}
                    load={row.original}
                />
            )}
        </>
    );
}

interface EditableLoadTableRowWithProviderProps {
    isDrafts: boolean;
    showEstimatedTotalTripTime: boolean;
    showTractor: boolean;
    showTrailer: boolean;
    isRowExpanded: boolean;
    row: Row<Load>;
}

const EditableLoadTableRowWithProvider: FC<EditableLoadTableRowWithProviderProps> = (props) => {
    const { isDrafts, showEstimatedTotalTripTime, showTractor, showTrailer, isRowExpanded, row } = props;
    const { handleGlobalIsDirtyChange } = useDraftsLoadsContext();

    return (
        <DraftLoadProvider
            inputLoad={row.original}
            handleGlobalIsDirtyChange={handleGlobalIsDirtyChange}
        >
            <EditableLoadTableRow
                isDrafts={isDrafts}
                showEstimatedTotalTripTime={showEstimatedTotalTripTime}
                showTractor={showTractor}
                showTrailer={showTrailer}
                isRowExpanded={isRowExpanded}
                row={row}
            />
        </DraftLoadProvider>
    );
}
