import React from 'react';
import { styled } from '@mui/material/styles';

const Root = styled('div')({
    padding: '1rem 1rem 1rem 1rem',
});

interface ContentProps {
    children: React.ReactNode;
    style?: React.CSSProperties;
}

export const Content: React.FC<ContentProps> = (props) => {
    const { children, style } = props;

    return (
        <Root style={style}>
            {children}
        </Root>
    );
};
