import React from 'react';
import { useDraftLoadContext } from '../../../hooks/draft-load-context';
import { AgisticsSelect } from '../../../../shared/agistics-select';
import { L } from 'harmony-language';
import { Load } from '../../../../../types';
import { LoadEditableProperties } from '../../../hooks/use-load-editable-items';

interface EditableLoadTrailerProps {
    getTrailerProperties: (l: Load) => LoadEditableProperties;
    showLabel?: boolean;
    customPopper?: boolean;
}

export const EditableLoadTrailer: React.FC<EditableLoadTrailerProps> = (props) => {
    const { getTrailerProperties, showLabel = true, customPopper } = props;
    const { editedLoad, onEditedLoadChanged } = useDraftLoadContext();
    const trailerProperties = getTrailerProperties(editedLoad);

    return (
        <AgisticsSelect
            item={editedLoad.trailerId}
            items={trailerProperties.items}
            disabled={trailerProperties.isDisabled}
            showNone={true}
            onChange={(e) => {
                onEditedLoadChanged({ trailerId: e === -1 ? null : e });
            }}
            label={showLabel ? L.trailer() : undefined}
            confinedSpace={true}
            customPopper={customPopper}
        />
    );
};
