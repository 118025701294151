import React, { FC } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

interface ConfirmationDialogProps {
    open: boolean;
    title: string;
    message: string;
    cancelText: string;
    confirmText: string;
    resultCallback: (b: boolean) => void;
}

export const ConfirmationDialog: FC<ConfirmationDialogProps> = (props) => {
    const { open, title, message, cancelText, confirmText, resultCallback } = props;

    return (
        <Dialog
            data-testid='confirmation-dialog'
            open={open}
            onClose={() => resultCallback(false)}
        >
            <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => resultCallback(false)} color='primary' autoFocus>
                    {cancelText}
                </Button>
                <Button onClick={() => resultCallback(true)} color='primary' autoFocus>
                    {confirmText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
