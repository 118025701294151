import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { mutationOptions, QueryKeys } from '../../config';
import { useSnackbar } from '../../../components/notifications/use-snackbar';
import { ProductionCardState } from '../../../components/production-planning/create-production-card';
import { ProductionCardType } from '../../../components/production-planning/generation/production-card';
import { utc } from '../../../utils/date-time-utils';
import { getCurrentUnitSystem, L, UNITS } from 'harmony-language';
import { poundsToKg } from '../../../utils/unit-conversion';

export const useAddProductionCardMutation = () => {
    const snackbar = useSnackbar();
    const isMetric = getCurrentUnitSystem() === UNITS.Metric;
    const queryClient = useQueryClient();

    return useMutation(async function (productionCard: ProductionCardState) {
        const trimmedLotId = productionCard.lotId ? productionCard.lotId.trim() : null;

        const card: Omit<ProductionCardType, 'id' | 'guid'> = {
            cargoTypeId: productionCard.cargoTypeId,
            quantity: productionCard.quantity,
            targetDate: utc(productionCard.targetDate),
            lotId: trimmedLotId,
            orderNumber: productionCard.orderNumber,
            productionPlan: productionCard.productionPlan,
            stops: [
                {
                    type: 'Origin',
                    organizationLocationId: productionCard.origin.organizationLocationId as number,
                    organizationSubLocationIds: productionCard.origin.organizationSubLocationIds
                },
                {
                    type: 'Destination',
                    organizationLocationId: productionCard.destination.organizationLocationId as number,
                    organizationSubLocationIds: productionCard.destination.organizationSubLocationIds
                }
            ],
            metadata: {
                cargo: {
                }
            }
        }

        if (productionCard.numberOfLoads) {
            card.metadata.cargo.numberOfLoads = productionCard.numberOfLoads;
        } else if (productionCard.averageWeight) {
            card.metadata.cargo.averageWeight = isMetric ? productionCard.averageWeight : poundsToKg(productionCard.averageWeight);
        }

        if (productionCard.weight) {
            card.metadata.cargo.weight = productionCard.weight;
        }

        return (await axios.post(`${API_BASE}/api/production-cards`, card)).data;
    }, {
        ...mutationOptions([QueryKeys.productionCards], queryClient),
        onSuccess: () => {
            snackbar(L.productionCardCreateSuccess());
        },
    });
};
