import React, { FC, ReactNode } from 'react';
import { OrganizationLocation, Stop } from '../../../../../types';
import { toSiteDisplayNameFromOrganizationLocation, toSublocationDisplay } from '../../../../../utils/data-mapping';
import { AgisticsTooltip } from '../../../../shared/agistics-tooltip';
import { L } from 'harmony-language';

interface AddressNotesTooltipContentProps {
    organizationLocation: OrganizationLocation;
    sublocationDisplay: string | null;
    stopsNotes: ReactNode[];
}

const AddressNotesTooltipContent: React.FC<AddressNotesTooltipContentProps> = (props) => {
    const { organizationLocation, sublocationDisplay, stopsNotes } = props;

    return (
        <div>
            <div>{L.address()}</div>
            <div>{L.localeAddressLine1(organizationLocation)}</div>
            {organizationLocation.address2 && organizationLocation.address2.trim().length ? <div>{L.localeAddressLine2(organizationLocation)}</div> : <></>}
            <div>{L.localeAddressLine3(organizationLocation)}</div>
            {sublocationDisplay
                ? <>
                    <hr />
                    <div>{L.subLocations()}</div>
                    <div>{sublocationDisplay}</div>
                </>
                : <></>
            }
            {stopsNotes.length
                ? <><hr /><div>{stopsNotes}</div></>
                : <><hr />{L.noNotes()}</>
            }
        </div>
    );
};

interface LoadStopLocationProps {
    organizationLocationId: number;
    organizationLocations: OrganizationLocation[];
    stops: Stop[];
}

export const LoadStopLocation: FC<LoadStopLocationProps> = (props) => {
    const { organizationLocationId, organizationLocations, stops } = props;

    const organizationLocation = organizationLocations.find(x => x.id === organizationLocationId);

    if (organizationLocation) {
        const siteDisplayName = toSiteDisplayNameFromOrganizationLocation(organizationLocation);

        const stopsWithSublocations = stops.filter(stop => stop.organizationSubLocationIds && stop.organizationSubLocationIds.length);
        const sublocationDisplay = toSublocationDisplay(stopsWithSublocations, organizationLocation);

        const stopsNotes = stops.map(stop => {
            if (stop.notes) {
                return <div key={stop.id}>{stop.notes}</div>
            }
        }).filter(x => !!x);

        return (
            <div className='lt-cell-sub'>
                <AgisticsTooltip
                    tooltip={
                        <AddressNotesTooltipContent
                            organizationLocation={organizationLocation}
                            sublocationDisplay={sublocationDisplay}
                            stopsNotes={stopsNotes}
                        />
                    }
                >
                    {siteDisplayName}
                </AgisticsTooltip>
            </div>
        );
    }
}
