import { Table } from '@tanstack/react-table';
import React, { useState } from 'react';
import { Customer, Load, OrganizationLocation } from '../../../../types';
import { useOrganizationQuery } from '../../../../api/queries/use-organization-query';
import { OrgQueryKeys } from '../../../../api/config';
import { useDraftsLoads } from '../../../../api/queries/use-drafts-loads';
import { AgisticsMultiSelect } from '../../../shared/multi-select/agistics-multi-select';
import { L } from 'harmony-language';
import { GlobalFilterValue } from './use-loads-global-filter';
import { globalFilterRemover } from '../../utils/drafts-loads-utils';

interface CustomerFilterProps {
    isDrafts: boolean;
    table: Table<Load>;
}

export const CustomerFilter: React.FC<CustomerFilterProps> = (props) => {
    const { isDrafts, table } = props;
    const { data: loads = [] } = useDraftsLoads(isDrafts);
    const { data: organizationLocations = [] } = useOrganizationQuery<OrganizationLocation[]>(OrgQueryKeys.locations);
    const { data: customers = [] } = useOrganizationQuery<Customer[]>(OrgQueryKeys.customers);

    const [selectedCustomerIds, setSelectedCustomerIds] = useState<number[]>([]);

    const customerIds = loads.map(load => {
        const customerIdsOnStops = load.stops.map(stop => {
            const foundOrganizationLocation = organizationLocations.find(organizationLocation => organizationLocation.id === stop.organizationLocationId);
            if (foundOrganizationLocation) {
                const customerId = foundOrganizationLocation.customerId;
                return customerId;
            }
            return null;
        })
        return customerIdsOnStops;
    }).flat().filter(x => x !== null);

    const uniqueCustomerIds = [...new Set(customerIds)];

    const facetedCustomers = uniqueCustomerIds.map(id => {
        const customer = customers.find(customer => customer.id === id);
        return customer;
    }).filter(x => !!x);

    const handleCustomerChange = (customerIds: number[]) => {
        setSelectedCustomerIds(customerIds);

        const currentGlobalFilter = table.getState().globalFilter;

        if (customerIds.length) {
            const filterOrganizationLocationIds = organizationLocations.filter(x => {
                return x.customerId ? customerIds.includes(x.customerId) : false;
            }).map(x => x.id);

            const newFilter: GlobalFilterValue = {
                ...currentGlobalFilter,
                customer: filterOrganizationLocationIds,
            }

            table.setGlobalFilter(newFilter);
        } else {
            const newFilter = globalFilterRemover(currentGlobalFilter, 'customer');
            table.setGlobalFilter(newFilter);
        }
    }

    return (
        <>
            <AgisticsMultiSelect
                selectedIds={selectedCustomerIds}
                setSelectedIds={handleCustomerChange}
                items={facetedCustomers.map(x => ({ id: x.id, value: x.customerName }))}
                label={L.customers()}
            />
        </>
    );
};
