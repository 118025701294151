import React from 'react';
import { Grid, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { L } from 'harmony-language';
import { STOP_TYPES, getTransLocationType } from '../../../constants/constants';
import { AgisticsMultiSelect } from '../../shared/multi-select/agistics-multi-select';
import { useLocationTypes } from '../../../api/queries/use-location-types';
import { useOrganizationQuery } from '../../../api/queries/use-organization-query';
import { OrganizationLocation } from '../../../types';
import { OrgQueryKeys } from '../../../api/config';
import { toSiteDisplayName } from '../../../utils/data-mapping';

interface DecisionFiltersProps {
    isAdmin: boolean;
    siteModes: string[];
    setSiteModes: React.Dispatch<React.SetStateAction<string[]>>;
    siteTypeIds: number[];
    setSiteTypeIds: React.Dispatch<React.SetStateAction<number[]>>;
    siteIds: number[];
    setSiteIds: React.Dispatch<React.SetStateAction<number[]>>;
}

export const DecisionFilters: React.FC<DecisionFiltersProps> = (props) => {
    const { isAdmin, siteModes, setSiteModes, siteTypeIds, setSiteTypeIds, siteIds, setSiteIds } = props;
    const { data: locationTypes = [] } = useLocationTypes();
    const { data: organizationLocations = [] } = useOrganizationQuery<OrganizationLocation[]>(OrgQueryKeys.locations, { enabled: !isAdmin });

    const filteredOrgLocations = organizationLocations.length
        ? siteTypeIds.length
            ? organizationLocations.filter(x => siteTypeIds.includes(x.organizationLocationTypeId))
            : organizationLocations
        : [];
        
    const stopTypesMultiItems = Object.values(STOP_TYPES()).filter(t => t.key !== 'Wash').map(type => ({
        id: type.key,
        value: type.label,
    }));
    const locationTypesMultiItems = locationTypes.map((locationType: any) => ({
        id: locationType.id,
        value: getTransLocationType(locationType).label
    }));
    const orgLocationsMultiItems = filteredOrgLocations.map(x => ({
        id: x.id,
        value: toSiteDisplayName(x.name, x.description),
    }));

    return (
        <Grid container sx={{ width: '20rem' }}>
            <Grid item xs={12}>
                <AgisticsMultiSelect
                    selectedIds={siteModes}
                    setSelectedIds={setSiteModes}
                    items={stopTypesMultiItems}
                    label={L.stopTypes()}
                />
            </Grid>
            <Grid item xs={12}>
                <AgisticsMultiSelect
                    selectedIds={siteTypeIds}
                    setSelectedIds={(selectedIds: number[]) => {
                        setSiteTypeIds(selectedIds);
                        // if location types changes, reset locations input
                        selectedIds.length && setSiteIds([]);
                    }}
                    items={locationTypesMultiItems}
                    label={L.locationTypes()}
                />
            </Grid>
            {!isAdmin &&
                <Grid item xs={11}>
                    <AgisticsMultiSelect
                        selectedIds={siteIds}
                        setSelectedIds={setSiteIds}
                        items={orgLocationsMultiItems}
                        label={L.locations()}
                    />
                </Grid>}
            {!isAdmin &&
                <Grid item xs={1} sx={{ display: 'flex' }}>
                    <Tooltip
                        arrow
                        sx={{ display: 'inline-block', alignSelf: 'flex-end' }}
                        placement={'right'}
                        title={<span style={{ fontSize: '.875rem' }}>{'Changing Location Types will clear this input.'}</span>}>
                            <InfoOutlinedIcon />
                    </Tooltip>
                </Grid>}
        </Grid>
    );
};
