import { Box, Typography } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { PermissionType } from 'harmony-constants';
import { L } from 'harmony-language';
import React from 'react';
import { OrgQueryKeys } from '../../api/config';
import { useOrganizationQuery } from '../../api/queries/use-organization-query';
import { useCompanyId } from '../../app/agistics-context';
import { OrganizationLocation, SiteIssue } from '../../types';
import { localDateTimeDisplay } from '../../utils/date-time-utils';
import { ImagesModal } from '../shared/images-modal';
import { ActionIcon } from '../shared/table/components/action-icon';
import { usePermissions } from '../user/selectors/use-permissions';

export const useSiteIssueColumns = (handleModalOpen: (s: SiteIssue) => void) => {
    const {
        data: users = [],
        isLoading: isLoadingUsers
    } = useOrganizationQuery(OrgQueryKeys.usersCarriers) as { data?: { id: number, name: string }[], isLoading: boolean };
    const {
        data: organizationLocations = [],
        isLoading: isLoadingLocations
    } = useOrganizationQuery(OrgQueryKeys.locations) as { data?: OrganizationLocation[], isLoading: boolean };
    const companyId = useCompanyId();

    const isLoading = isLoadingUsers || isLoadingLocations;
    const canResolve = usePermissions(PermissionType.ResolveSiteIssues);
    const columnHelper = createColumnHelper<SiteIssue>();

    const columns = React.useMemo(() => {
        const displayUser = (field: keyof SiteIssue, row: SiteIssue) => {
            const user = users?.find(x => x.id === row[field]);
            return user ? user?.name : ''
        }

        const c = [
            columnHelper.accessor(siteIssue => {
                const organizationLocation = organizationLocations.find(x => x.id === siteIssue.organizationLocationId);
                return organizationLocation?.name;
            }, {
                header: L.site(),
                cell: (info) => info.getValue(),
                enableColumnFilter: false,
            }),
            columnHelper.accessor('issueLanguageKey', {
                header: L.issue(),
                cell: (info) => L[info.row.original.issueLanguageKey](),
                enableColumnFilter: false,
            }),
            columnHelper.accessor('notes', {
                header: L.notes(),
                cell: (info) => {
                    const row = info.row.original;
                    return (
                        <Box sx={{ display: 'block'}}>
                            <Typography sx={{ fontSize: '0.875rem' }}>{row.notes}</Typography>
                            {row.resolutionNotes && <Typography sx={{ fontSize: '0.875rem' }}>{row.resolutionNotes}</Typography>}
                        </Box>
                    );
                },
                enableColumnFilter: false,
                enableSorting: false,
            }),
            columnHelper.accessor('createdAt', {
                header: L.createdAt(),
                cell: (info) => localDateTimeDisplay(info.row.original.createdAt),
                enableColumnFilter: false,
            }),
            columnHelper.accessor('createdByUserId', {
                header: L.createdBy(),
                cell: (info) => displayUser('createdByUserId', info.row.original),
                enableColumnFilter: false,
            }),
            columnHelper.accessor('resolvedAt', {
                header: L.resolvedAt(),
                cell: (info) => info.row.original.resolvedAt ? localDateTimeDisplay(info.row.original.resolvedAt) : null,
                enableColumnFilter: false,
                sortingFn: (rowA, rowB) => {
                    // Even though "desc" is supplied, you're not supposed to invert the
                    // sort order based on it.  React Table reverses the items for you.
                    // https://github.com/TanStack/table/discussions/2778
                    if (!rowA.original.resolvedAt)
                        return 1;
                    if (!rowB.original.resolvedAt)
                        return -1;
                    return rowA.original?.resolvedAt > rowB.original?.resolvedAt ? 1 : -1
                },
            }),
            columnHelper.accessor('resolvedByUserId', {
                header: L.resolvedBy(),
                cell: (info) => displayUser('resolvedByUserId', info.row.original),
                enableColumnFilter: false,
            }),
        ];

        const resolveCol = () => {
            return [
                // @ts-ignore
                columnHelper.accessor('actions', {
                    header: () => L.actions(),
                    cell: (info) => {
                        const row = info.row.original;
                        const imageQueryKey = `/organizations/${companyId}/locations/${row.organizationLocationId}/site-issues/${row.id}/images`

                        return (
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                {row.imageIds?.length && 
                                    <ImagesModal imageLabel='Image' modalLabel='siteIssueImages' queryKey={imageQueryKey} />
                                }
                                <ActionIcon
                                    key={'edit'}
                                    action={() => handleModalOpen(row)}
                                    icon={'edit'}
                                    tooltip={L.edit()}
                                />
                            </div>
                        );
                    },
                    enableColumnFilter: false,
                    enableSorting: false,
                }),
            ]
        }

        const actions = canResolve ? resolveCol() : [];

        return [...c, ...actions];

    }, [[organizationLocations,canResolve, users]]);

    return {columns, isLoading};
};
