import React from 'react';
import { Stop } from '../../../../../types';
import { useDraftLoadStopContext } from '../../../hooks/draft-load-stop-context';
import { TextField } from '@mui/material';
import { isCargoStop } from '../../../../../constants/constants';
import { editableStopInputProps } from '../../../table-components/stops/editable-stop-table-row';

interface EditableStopTextProps {
    stop: Stop;
    property: 'productionPlan' | 'lotId' | 'customerOrderNumber';
}

export const EditableStopText: React.FC<EditableStopTextProps> = (props) => {
    const { stop, property } = props;
    const { onEditedStopChanged, isCarrier, isComplete } = useDraftLoadStopContext();
    const text = stop[property];

    if (!isCargoStop(stop)) {
        return null;
    }

    return (
        <TextField
            variant='standard'
            value={text || ''}
            inputProps={editableStopInputProps}
            disabled={isCarrier || isComplete}
            fullWidth
            onChange={(e) => {
                const newVal = e.target.value ? e.target.value : null;
                onEditedStopChanged({ [property]: newVal });
            }}
        />
    );
};
