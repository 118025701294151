import React from 'react';
import { styled } from '@mui/material/styles';
import { Button, FormControlLabel, Tooltip } from '@mui/material';
import DragHandle from '@mui/icons-material/DragHandle';
import { AgisticsDateTimePicker } from '../../../shared/inputs/agistics-date-time-picker';
import { Select } from '../../../shared/inputs/select';
import { L } from 'harmony-language';
import { useDraftsMutation } from '../../../../api/mutations/reworked/use-drafts-mutation';
import { useOrganizationQuery } from '../../../../api/queries/use-organization-query';
import {
    filterStopByDestination,
    filterStopByOrigin,
    ordersToCommonStopLocationIds,
    orderToFirstOrigin,
    orderToLastDestination,
    orderToProductionPlans,
    orderToSalmonellaResult,
    toIdLabelTuple
} from '../../../../utils/data-mapping-utils';
import { OrgQueryKeys } from '../../../../api/config';
import { isValidDate } from '../../../../utils/date-time-utils';
import { STOP_TYPES } from '../../../../constants/constants';
import { CircularLoading } from '../../../shared/circular-loading';
import { SalmonellaMeter } from '../../../shared/salmonella-meter';
import { DetailsTooltip } from './details-tooltip';
import { StopItem } from './stop-item';
import { useCascadeStops } from './use-cascade-stops';
import Switch from '@mui/material/Switch';
import { CascadeOffset } from './cascade-offset';
import { SelectCascadeInterval } from '../../../shared/inputs/select-cascade-interval';
import { ModalFooter } from '../../../shared/containers/modal-footer';
import { DndContext } from '@dnd-kit/core';
import { Load, OrganizationLocation } from '../../../../types';
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';

const InputContainer = styled('div')({
    paddingRight: '0.5rem',
    display: 'flex',
    flex: 2,
    flexDirection: 'column',
    '& > *': {
        marginBottom: '1.5rem'
    }
});

const DraftsContainer = styled('div')({
    paddingLeft: '0.5rem',
    flex: 7,
    fontSize: '0.9rem',
});

const DraftsContainerPlaceholder = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 7,
    paddingLeft: '0.5rem',
});

const DraftsContainerRows = styled('div')({
    maxHeight: '50vh',
    overflowY: 'auto',
    overflowX: 'hidden',
});

const DraftsRow = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0.1rem 0',
    padding: '0.1rem 0',
    border: '1px solid transparent',
    width: '100%',
    boxSizing: 'border-box',
    '&:hover': {
        backgroundColor: '#fafafa',
        border: '1px solid #e7e7e7',
    }
});

const DraftsHeader = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0.1rem 0',
    padding: '0.1rem 0',
    border: '1px solid transparent',
    width: '100%',
    boxSizing: 'border-box',
    borderBottom: '1px solid #dedede',
    paddingBottom: '0.2rem',
    marginBottom: 0,
});

const DragHandleColumn = styled('div')({
    color: 'rgba(0,0,0,0.40)',
    width: '1.4rem'
});

const LoadColumn = styled('div')({
    flex: 1,
    alignItems: 'center',
    paddingLeft: '0.2rem',
});

const LocationColumn = styled('div')({
    display: 'flex',
    overflow: 'hidden',
    flex: 7,
    paddingRight: '1rem',
});

const ExtraColumn = styled('div')({
    flex: 3,
    alignItems: 'center',
});

const OffsetColumn = styled('div')({
    flex: 2,
    alignItems: 'center',
});

const multiStopGlyph = '**'; //dagger was... problematic

interface DraggableCascadeRowProps {
    draft: Load;
    stopBasis: any;
    offsets: any;
    i: number;
    startAtDate: any;
    organizationLocations: OrganizationLocation[];
    hasProductionPlan: boolean;
    hasSalmonella: boolean;
    changeOffset: any;
}

const DraggableCascadeRow: React.FC<DraggableCascadeRowProps> = (props) => {
    const { draft, stopBasis, offsets, i, startAtDate, organizationLocations, hasProductionPlan, hasSalmonella, changeOffset } = props;

    const basisStop = draft.stops.find(s => s.organizationLocationId === stopBasis?.id);
    // added this for ts conversion, I think it's okay?
    if (!basisStop) {
        return;
    }
    const originStop = (basisStop.type === STOP_TYPES().Origin.key) ? basisStop : orderToFirstOrigin(draft);
    const destinationStop = (basisStop.type === STOP_TYPES().Destination.key) ? basisStop : orderToLastDestination(draft);
    const hasMultiOrigin = draft.stops.filter(filterStopByOrigin).length > 1;
    const hasMultiDestination = draft.stops.filter(filterStopByDestination).length > 1;
    const offset = offsets[i];

    const { attributes, listeners, setNodeRef, transform } = useSortable({
        id: draft.id,
        transition: null,
        disabled: !isValidDate(startAtDate),
    });
    const style = {
        transform: CSS.Translate.toString(transform),
    };

    return (
        <>
            <div
                ref={setNodeRef}
                style={style}
            >
                <Tooltip
                    componentsProps={{
                        tooltip: {
                            sx: (theme) => ({
                                width: '20vw',
                                backgroundColor: theme.palette.secondary.main,
                                color: theme.palette.secondary.contrastText,
                                maxWidth: 'unset',
                            })
                        },
                        arrow: {
                            sx: (theme) => ({
                                color: theme.palette.secondary.main,
                            })
                        }
                    }}
                    arrow={true}
                    placement={'left'}
                    title={<DetailsTooltip draft={draft}
                                            organizationLocations={organizationLocations}
                                            basisStopId={basisStop.id}/>}
                >
                    <DraftsRow>
                        <DragHandle
                            style={{ color: 'rgba(0,0,0,0.40)', width: '1.4rem' }}
                            visibility={isValidDate(startAtDate) ? 'visible' : 'hidden'}
                            {...listeners}
                            {...attributes}
                        />
                        <LoadColumn>
                            {draft.id}
                        </LoadColumn>
                        <LocationColumn>
                            <StopItem stop={originStop}
                                        organizationLocations={organizationLocations}
                                        isBasis={basisStop.id === originStop?.id}
                                        textAdornment={(hasMultiOrigin ?
                                            <b>{multiStopGlyph}</b> : null)}/>
                        </LocationColumn>
                        <LocationColumn>
                            <StopItem stop={destinationStop}
                                        organizationLocations={organizationLocations}
                                        isBasis={basisStop.id === destinationStop?.id}
                                        textAdornment={(hasMultiDestination ?
                                            <b>{multiStopGlyph}</b> : null)}/>
                        </LocationColumn>
                        {hasProductionPlan &&
                            <ExtraColumn>
                                {orderToProductionPlans(draft).join(', ')}
                            </ExtraColumn>}
                        {hasSalmonella &&
                            <ExtraColumn>
                                <SalmonellaMeter
                                    severity={orderToSalmonellaResult(draft)}
                                    disableLabel/>
                            </ExtraColumn>}
                        <OffsetColumn>
                            <CascadeOffset offset={offset} changeOffset={(value) => changeOffset(i, value)}/>
                        </OffsetColumn>
                    </DraftsRow>
                </Tooltip>
            </div>
        </>
    );
}

interface CascadeStopTimesProps {
    drafts: Load[];
    onSubmit: () => void;
}

export const CascadeStopTimes: React.FC<CascadeStopTimesProps> = (props) => {
    const { drafts, onSubmit } = props;

    const { data: organizationLocations = [] } = useOrganizationQuery<OrganizationLocation[]>(OrgQueryKeys.locations);
    const { mutateAsync: saveDrafts, isLoading } = useDraftsMutation();

    const stopBasii = React.useMemo(() => {
        if (!organizationLocations) {
            return [];
        }
        return ordersToCommonStopLocationIds(drafts).map(x => organizationLocations?.find(ol => ol.id === x)).map(toIdLabelTuple('name'));
    }, [drafts, organizationLocations]);

    const {
        isBackwards,
        modifiedDrafts,
        interval,
        stopBasis,
        startAtDate,
        offsets,
        changeBasis,
        changeInterval,
        changeDate,
        changeOffset,
        toggleDirection,
        handleOnDragEnd,
        hasMultiStop,
        hasProductionPlan,
        hasSalmonella
    } = useCascadeStops(drafts, stopBasii);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        await saveDrafts({drafts: modifiedDrafts});
        onSubmit();
    };

    return (
        <div style={{ width: '80vw' }} data-testid='cascade-stop-times'>
            <form onSubmit={handleSubmit}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {isLoading && <CircularLoading/>}
                    <InputContainer>
                        <FormControlLabel
                            control={<Switch
                                color='primary'
                                checked={isBackwards}
                                onChange={toggleDirection}
                                name={' switch'}
                            />}
                            label={L.cascadeDirectionLabel()}
                            labelPlacement="end"
                        />
                        <Select required disabled={isLoading} label={L.cascadeStopLocationBasis()} list={stopBasii}
                                item={stopBasii.find(x => x.id === stopBasis?.id)} onChange={changeBasis}/>
                        <AgisticsDateTimePicker disabled={isLoading || !stopBasis} label={L.cascadeStartAt()}
                                        required
                                        value={startAtDate}
                                        onChange={changeDate}/>
                        <SelectCascadeInterval
                            isLoading={isLoading}
                            item={interval}
                            onChange={changeInterval}
                        />
                    </InputContainer>
                    {stopBasis ?
                        <DraftsContainer>
                            <DraftsHeader>
                                <DragHandleColumn />
                                <LoadColumn>{L.load()}</LoadColumn>
                                <LocationColumn>{STOP_TYPES().Origin.label}</LocationColumn>
                                <LocationColumn>{STOP_TYPES().Destination.label}</LocationColumn>
                                {hasProductionPlan && <ExtraColumn>{L.productionPlan()}</ExtraColumn>}
                                {hasSalmonella && <ExtraColumn>{L.salmonella()}</ExtraColumn>}
                                <OffsetColumn>{L.cascadeOffset()}</OffsetColumn>
                            </DraftsHeader>
                            <DndContext
                                onDragEnd={handleOnDragEnd}
                                modifiers={[restrictToVerticalAxis, restrictToParentElement]}
                            >
                                <SortableContext
                                    items={modifiedDrafts.map((draft: Load) => draft.id)}
                                    strategy={verticalListSortingStrategy}
                                >
                                    <DraftsContainerRows>
                                        {modifiedDrafts?.map((draft: Load, i: number) => {
                                            return (
                                                <DraggableCascadeRow
                                                    draft={draft}
                                                    stopBasis={stopBasis}
                                                    offsets={offsets}
                                                    i={i}
                                                    startAtDate={startAtDate}
                                                    organizationLocations={organizationLocations}
                                                    hasProductionPlan={hasProductionPlan}
                                                    hasSalmonella={hasSalmonella}
                                                    changeOffset={changeOffset}
                                                />
                                            )
                                        })}
                                    </DraftsContainerRows>
                                </SortableContext>
                            </DndContext>
                        </DraftsContainer> :
                        <DraftsContainerPlaceholder data-testid='cascade-stop-times-placeholder'>
                            {L.cascadeStopTimesPlaceholder()}
                        </DraftsContainerPlaceholder>}
                </div>
                <ModalFooter>
                    <div style={{visibility: hasMultiStop ? 'visible' : 'hidden'}}>
                        <b>{multiStopGlyph}</b> {L.cascadeDraftsMultiStopExplanation()}</div>
                    <Button disabled={isLoading} type='submit' variant={'contained'}
                            color={'primary'}>{L.cascadeSubmit()}</Button>
                </ModalFooter>
            </form>
        </div>
    );
};
