import React from 'react';
import { useCargoTypes } from '../../../../../api/queries/use-cargo-types';
import { getPairedStop, stopCargoEligibleForCompartments } from '../../../../../utils/data-mapping-utils';
import { Stop } from '../../../../../types';
import { CompartmentDiagram } from '../../../../shared/compartment-diagram';
import { useDraftLoadContext } from '../../../hooks/draft-load-context';

interface EditableStopCompartmentsProps {
    stop: Stop;
    trailerId: number | null;
}

export const EditableStopCompartments: React.FC<EditableStopCompartmentsProps> = (props) => {
    const { stop, trailerId } = props;
    const { editedLoad, onEditedLoadChanged, compartmentLinkingEnabled } = useDraftLoadContext();
    const { data: cargoTypes, isLoading: isLoadingCargoTypes } = useCargoTypes();

    if (isLoadingCargoTypes || !stopCargoEligibleForCompartments(cargoTypes, stop)) {
        return null;
    }

    const compartmentChanged = (compartmentNumber: number) => {
        let pairedStop: Stop;
        if (compartmentLinkingEnabled) {
            pairedStop = getPairedStop(editedLoad, stop);
        }

        const enabledCompartments = stop?.compartments ? [...stop.compartments] : [];
        const matchIndex = enabledCompartments.indexOf(compartmentNumber);
        if (matchIndex > -1) {
            enabledCompartments.splice(matchIndex, 1);
        } else {
            enabledCompartments.push(compartmentNumber);
        }

        onEditedLoadChanged({
            ...editedLoad,
            stops: editedLoad.stops.map(x => {
                if (x.sequence === stop.sequence || x.sequence === pairedStop?.sequence) {
                    return { ...x, compartments: enabledCompartments }
                } else {
                    return x;
                }
            })
        });
    };

    return (
        <CompartmentDiagram
            onChange={compartmentChanged}
            trailerId={trailerId}
            cargoTypeId={stop.cargoTypeId}
            compartments={stop.compartments || []}
            hideLabel={true}
        />
    );
};
