import React, { FC } from 'react';

interface DisplayStopTextProps {
    text: string | null;
}

export const DisplayStopText: FC<DisplayStopTextProps> = (props) => {
    const { text } = props;

    return text;
};
