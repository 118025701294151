import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { OrgQueryKeys } from '../../../config';
import { useSnackbar } from '../../../../components/notifications/use-snackbar';
import { L } from 'harmony-language';
import { SiteIssue } from '../../../../types';
import { useCompanyId } from '../../../../app/agistics-context';

const resolveSiteIssue = (companyId: number) => async (siteIssue: SiteIssue) => {
    const { id, organizationLocationId } = siteIssue;

    return (await axios.put(`${API_BASE}/api/organizations/${companyId}/locations/${organizationLocationId}/site-issues/${id}/resolve`, siteIssue)).data;
}

export const useResolveSiteIssueMutation = () => {
    const snackbar = useSnackbar();
    const companyId = useCompanyId();
    const key = OrgQueryKeys.resolve(companyId, OrgQueryKeys.siteIssues);
    const queryKey = [key];
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: resolveSiteIssue(companyId),
        onMutate: async (siteIssue) => {
            await queryClient.cancelQueries(queryKey);
            const previousCache = queryClient.getQueryData<SiteIssue[]>(queryKey);

            if (previousCache) {
                const updatedIssues = previousCache.map((issue) => {
                    if (issue.id === siteIssue.id) {
                        return {
                            ...siteIssue,
                        };
                    }
                    return issue;
                });

                queryClient.setQueryData(queryKey, updatedIssues);
            }

            return previousCache;
        },
        onSuccess: () => {
            snackbar(L.siteIssueUpdated());
        },
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });

    return mutation;
};
