import React, { FC } from 'react';
import { Save } from '@mui/icons-material';
import { L } from 'harmony-language';
import { useDraftLoadContext } from '../hooks/draft-load-context';
import LoadingButton from '../../shared/loading-button';
import { useIsMutating } from '@tanstack/react-query';

export const DraftsLoadsSaveButton: FC = () => {
    const { isDirty } = useDraftLoadContext();
    // tried to parse this down with mutationKey, but could not get it to work
    const isMutating = useIsMutating();

    return (
        <LoadingButton
            type='submit'
            variant='contained'
            isLoading={isMutating > 0}
            disabled={!isDirty}
            icon={<Save />}
            size='small'
        >
            {L.save()}
        </LoadingButton>
    );
};
