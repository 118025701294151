import React from 'react';
import { Select } from '../../../shared/inputs/select';
import { Button, TextField } from '@mui/material';
import { L } from 'harmony-language';
import { useWeights } from '../../../shared/hooks/use-weights';
import { Table } from '@tanstack/react-table';
import { Load } from '../../../../types';
import { GlobalFilterValue } from './use-loads-global-filter';
import { globalFilterRemover } from '../../utils/drafts-loads-utils';

export interface ComparisonFilter {
    category: string;
    value: number;
}

export const Comparison = {
    LessThan: 'less-than',
    LessThanEqual: 'less-than-equal',
    MoreThan: 'more-than',
    MoreThanEqual: 'more-than-equal',
    Equal: 'equal',
};

const COMPARISON_OPERATORS = [
    { id: Comparison.LessThan, label: '<' },
    { id: Comparison.LessThanEqual, label: '<=' },
    { id: Comparison.MoreThan, label: '>' },
    { id: Comparison.MoreThanEqual, label: '>=' },
    { id: Comparison.Equal, label: '=' },
];

interface ComparisonFilterProps {
    table: Table<Load>;
}

export const ComparisonFilter: React.FC<ComparisonFilterProps> = (props) => {
    const { table } = props;
    const [item, setItem] = React.useState(COMPARISON_OPERATORS[0]);
    const [weight, setWeight] = React.useState<string | number>('');
    const { weightAbbreviation } = useWeights();

    const internalClear = () => {
        const newFilter = globalFilterRemover(table.getState().globalFilter, 'comparison');
        table.setGlobalFilter(newFilter);
    }

    const handleApply = () => {
        internalClear();

        if (typeof weight === 'number') {
            const newFilter: GlobalFilterValue = {
                ...table.getState().globalFilter,
                comparison: {
                    category: item.id,
                    value: weight,
                }
            }

            table.setGlobalFilter(newFilter);
        }
    }

    return (
        <div style={{ display: 'flex', columnGap: '15px', alignItems: 'flex-end' }}>
            <Select
                label='Operator'
                list={COMPARISON_OPERATORS}
                item={item}
                style={{ width: '5rem' }}
                onChange={(item) => {
                    setItem(item);
                }}
            />
            <TextField
                variant='standard'
                value={weight}
                type='number'
                label={`${L.weight()} (${weightAbbreviation})`}
                onChange={(e) => {
                    const inputNum = Number(e.target.value);
                    setWeight(inputNum ? inputNum : '');
                }}
            />
            <Button
                variant='contained'
                onClick={handleApply}
                disabled={typeof weight !== 'number'}
            >
                {L.apply()}
            </Button>
            <Button
                variant='outlined'
                onClick={() => {
                    setWeight('');
                    internalClear();
                }}
            >
                {L.clear()}
            </Button>
        </div>
    );
};
