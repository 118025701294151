import React from 'react';
import { ReportFilters } from '../report-filters';
import { localNow } from '../../../utils/date-time-utils';
import { ColumnSort } from '@tanstack/react-table';
import { useDecisionColumns } from './decision-columns';
import { ReportBase } from '../report-base';
import { getDecisionUrlAndParams } from '../report-utils';
import { useReports } from '../use-reports';
import { DecisionReportColumns } from '../report-types';
import { DecisionFilters } from './decision-filters';

const defaultSort: ColumnSort = {
    id: 'loads',
    desc: true,
}

interface DecisionReportProps {
    isAdmin: boolean;
    companyId: number;
}

export const DecisionReport: React.FC<DecisionReportProps> = (props) => {
    const { isAdmin, companyId } = props;
    const [organizationId, setOrganizationId] = React.useState<number>(isAdmin ? 0 : companyId);
    const [startDate, setStartDate] = React.useState<string>(localNow().subtract(1, 'day').startOf('day').toISOString());
    const [endDate, setEndDate] = React.useState<string>(localNow().subtract(1, 'day').endOf('day').toISOString());
    const [siteModes, setSiteModes] = React.useState<string[]>([]);
    const [siteTypeIds, setSiteTypeIds] = React.useState<number[]>([]);
    const [siteIds, setSiteIds] = React.useState<number[]>([]);
    const columns = useDecisionColumns(isAdmin);
    const { reportUrl, reportParams } = getDecisionUrlAndParams(isAdmin, organizationId, startDate, endDate, siteModes, siteTypeIds, siteIds);
    const { data, runReport, table, isLoading } = useReports<DecisionReportColumns>(defaultSort, columns, reportUrl, reportParams, true);

    return (
        <>
            <ReportFilters
                isAdmin={isAdmin}
                organizationId={organizationId}
                setOrganizationId={setOrganizationId}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                runReport={runReport}>
                    <DecisionFilters
                        isAdmin={isAdmin}
                        siteModes={siteModes}
                        setSiteModes={setSiteModes}
                        siteTypeIds={siteTypeIds}
                        setSiteTypeIds={setSiteTypeIds}
                        siteIds={siteIds}
                        setSiteIds={setSiteIds}
                    />
            </ReportFilters>
            <ReportBase
                table={table}
                hasData={Boolean(data)}
                isLoading={isLoading}
                runReport={runReport}
            />
        </>
    );
};
