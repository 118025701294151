import React from 'react';
import { useOrganizationQuery } from '../../../api/queries/use-organization-query';
import { OrgQueryKeys } from '../../../api/config';
import { Driver, OrganizationLocation } from '../../../types';
import { Stack } from '@mui/material';
import { L } from 'harmony-language';
import { AgisticsMultiSelectWithPrefs } from '../../shared/multi-select/agistics-multi-select-with-prefs';
import { AgisticsMultiSelect } from '../../shared/multi-select/agistics-multi-select';
import { toSiteDisplayName } from '../../../utils/data-mapping';

interface DriverEfficiencyFiltersProps {
    isAdmin: boolean;
    driverIds: number[];
    setDriverIds: React.Dispatch<React.SetStateAction<number[]>>;
    originSiteIds: number[];
    setOriginSiteIds: React.Dispatch<React.SetStateAction<number[]>>;
    employmentType: number[];
    setEmploymentType: React.Dispatch<React.SetStateAction<number[]>>;
}

export const DriverEfficiencyFilters: React.FC<DriverEfficiencyFiltersProps> = (props) => {
    const { isAdmin, driverIds, setDriverIds, originSiteIds, setOriginSiteIds, employmentType, setEmploymentType } = props;
    const { data: drivers = [] } = useOrganizationQuery<Driver[]>(OrgQueryKeys.drivers, { enabled: !isAdmin });
    const { data: organizationLocations = [] } = useOrganizationQuery<OrganizationLocation[]>(OrgQueryKeys.locations, { enabled: !isAdmin });

    const driverMultiItems = drivers.map(driver => ({
        id: driver.id,
        value: driver.name
    }));
    const orgLocationsMultiItems = organizationLocations.map(x => ({
        id: x.id,
        value: toSiteDisplayName(x.name, x.description),
    }));
    const employmentMultiItems = [{
        id: 1,
        value: L.contract()
    }, {
        id: 2,
        value: L.employee()
    }];

    return (
        <Stack sx={{ width: '20rem' }}>
            <AgisticsMultiSelect
                selectedIds={employmentType}
                setSelectedIds={setEmploymentType}
                items={employmentMultiItems}
                label={L.employment()}
            />
            {!isAdmin &&
                <>
                    <AgisticsMultiSelectWithPrefs
                        selectedIds={driverIds}
                        setSelectedIds={setDriverIds}
                        items={driverMultiItems}
                        label={L.drivers()}
                        prefKey='removedDrivers'
                        removeMode={true}
                    />
                    <AgisticsMultiSelect
                        selectedIds={originSiteIds}
                        setSelectedIds={setOriginSiteIds}
                        items={orgLocationsMultiItems}
                        label={L.originLocations()}
                    />
                </>
            }
        </Stack>
    );
};
