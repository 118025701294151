import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { L } from 'harmony-language';
import { useSnackbar } from '../../../components/notifications/use-snackbar';
import { useDraftsKey } from '../../config-hooks';

async function generateProjections(options: any) {
    const projectionConfigs = options.config;
    return (await axios.post(`${API_BASE}/api/orders/create-projections`, projectionConfigs)).data;
}

export const useGenerateProjections = () => {
    const snackbar = useSnackbar();
    const queryKey = useDraftsKey();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: generateProjections,
        onSuccess: (data) => {
            snackbar(L.bulkSnackbarSuccess(data.length));
        },
        onSettled: () => {
            queryClient.invalidateQueries(queryKey);
        },
    });

    return mutation;
};
