import { Table } from '@tanstack/react-table';
import React from 'react';
import { Load } from '../../../types';
import { LoadTableHeader } from './load-table-header';
import { LoadTableBody } from './load-table-body';
import { AgisticsTablePaging } from '../../shared/table/components/agistics-table-paging';
import { CircularLoading } from '../../shared/circular-loading';
import { useUpdateUserPreferences } from '../../shared/hooks/use-update-user-preferences';
import { getShowEstimatedTotalTripTime, getShowTractor, getShowTrailer } from '../utils/drafts-loads-utils';

interface DraftLoadTableProps {
    isDrafts: boolean;
    isLoading: boolean;
    isFetching: boolean;
    table: Table<Load>;
}

export const DraftLoadTable: React.FC<DraftLoadTableProps> = (props) => {
    const { isDrafts, isLoading, isFetching, table } = props;
    const { preferences, updatePreferences } = useUpdateUserPreferences();
    const showEstimatedTotalTripTime = getShowEstimatedTotalTripTime(preferences);
    const showTractor = getShowTractor(preferences);
    const showTrailer = getShowTrailer(preferences);

    if (isLoading || isFetching) {
        return <CircularLoading />
    }

    const setPageSizePreferences = (pageSize: number) => {
        const preferencesKey = isDrafts ? 'drafts' : 'loads';

        const newPreferences = {
            ...preferences,
            tables: {
                ...preferences.tables,
                [preferencesKey]: {
                    pageSize: pageSize,
                }
            }
        }

        updatePreferences(newPreferences);
    }

    return (
        <>
            <AgisticsTablePaging
                table={table}
                setPageSizePreferences={setPageSizePreferences}
            />
            <div className='lt-base'>
                <LoadTableHeader
                    isDrafts={isDrafts}
                    table={table}
                    showEstimatedTotalTripTime={showEstimatedTotalTripTime}
                    showTractor={showTractor}
                    showTrailer={showTrailer}
                />
                <LoadTableBody
                    isDrafts={isDrafts}
                    table={table}
                    showEstimatedTotalTripTime={showEstimatedTotalTripTime}
                    showTractor={showTractor}
                    showTrailer={showTrailer}
                />
            </div>
        </>
    );
};
