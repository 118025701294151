import React from 'react';
import { AvoidanceZone, OrganizationLocation, Stop, TripEvent } from '../../../../types';
import { localDateTimeDisplay } from '../../../../utils/date-time-utils';
import { L } from 'harmony-language';
import { toSiteDisplayName } from '../../../../utils/data-mapping';
import './delivery-log.css';
import { AgisticsWarningIcon } from '../../../shared/agistics-warning-icon';
import { TripEventMethod, TripEventType } from 'harmony-constants';
import { findKnownLocationOrLatLng } from './delivery-log-utils';
import { useStaticQuery } from '../../../../api/queries/use-static-query';
import { QueryKeys } from '../../../../api/config';

export const translatedTypeString = (
    event: TripEvent,
    locations: OrganizationLocation[],
    avoidanceZones?: AvoidanceZone[],
    stops?: Stop[]
) => {
    let translatedTypeString = L[`tripEventType${event.type}`]();
    if (event.type === TripEventType.SiteArrived || event.type === TripEventType.SiteLeft) {
        // mobile does not use the concept of initiatingId / reporting multiple orgLocations on events
        const searcherId = event.userId ? event.organizationLocationId : event.initiatingId;
        const site = locations.find(x => x.id === searcherId);
        translatedTypeString += ' ' + (site ? toSiteDisplayName(site.name, site.description) : '');
    } else if (event.type === TripEventType.SublocationArrived || event.type === TripEventType.SublocationLeft) {
        const orgSublocation = locations.find(location => {
            const sub = location.orgSubLocations.find(sublocation => sublocation.id === event.initiatingId);
            return sub;
        })?.orgSubLocations.find(sublocation => sublocation.id === event.initiatingId);

        if (orgSublocation) {
            translatedTypeString += ' (' + orgSublocation.name + ')';
        }
    } else if (event.type === TripEventType.TaskCompleted) {
        // resource log wont have this
        if (stops) {
            const stop = stops.find(x => x.id === event.stopId);
            if (stop) {
                translatedTypeString += ' (' + stop.sequence + ')';
            }
        }
        const isMobileManual = event.metadata.method === TripEventMethod.Manual;
        translatedTypeString += isMobileManual ? ` - ${L.manual()}` : '';
    } else if (event.type === TripEventType.StopStarted) {
        // using initiatingId so we can see this on resource log
        const site = locations.find(x => x.id === event.initiatingId);
        translatedTypeString += ' ' + (site ? toSiteDisplayName(site.name, site.description) : '');
    } else if (event.type === TripEventType.UnscheduledStop) {
        translatedTypeString += findKnownLocationOrLatLng(event, locations);
    } else if (event.type === TripEventType.ArrivalOverridden) {
        translatedTypeString += findKnownLocationOrLatLng(event, locations);
    } else if (event.type === TripEventType.AvoidanceZoneEntered && avoidanceZones) {
        const zone = avoidanceZones.find(x => x.id === event.initiatingId);
        if (zone) {
            translatedTypeString += ' - ' + zone.name;
        }
    } else if (event.type === TripEventType.TripResumed) {
        const duration = event.metadata.duration;
        if (duration) {
            translatedTypeString += ' - ' + duration.toFixed(1) + 'm';
        }
    }

    return translatedTypeString;
}

interface DeliveryLogEventRowProps {
    event: TripEvent;
    i: number;
    locations: OrganizationLocation[];
    moveMap: (lat: number, lng: number) => void;
    stops?: Stop[];
}

export const DeliveryLogEventRow: React.FC<DeliveryLogEventRowProps> = (props) => {
    const { event, i, locations, moveMap, stops } = props;
    const { data: avoidanceZones = [] } = useStaticQuery<AvoidanceZone[]>(QueryKeys.avoidanceZones);

    const hasMetadataComment = Boolean(event.metadata?.comment);

    return (
        <div key={i} className='sharedGridRow eventsGridRow' onClick={() => moveMap(event.latitude, event.longitude)}>
            <span className='sharedGridItem' title={event.timestamp.toString()}>{localDateTimeDisplay(event.timestamp)}</span>
            <span className='sharedGridItem'>
                {event.accuracy === 0 && <AgisticsWarningIcon message={L.eventDataAccuracy()} />}
                {translatedTypeString(event, locations, avoidanceZones, stops)}
                {hasMetadataComment ? ` - ${event.metadata?.comment}` : ''}
            </span>
        </div>
    );
};

interface SyntheticDeliveryLogEventRowProps {
    manuallyCompletedStops: Stop[];
}

export const SyntheticDeliveryLogEventRow: React.FC<SyntheticDeliveryLogEventRowProps> = (props) => {
    const { manuallyCompletedStops } = props;

    return (
        manuallyCompletedStops.map((manuallyCompletedStop, i) => {
            return (
                <div key={'synthetic' + i} className='sharedGridRow eventsGridRow noHover'>
                    <span className='sharedGridItem' title={manuallyCompletedStop.completedAt?.toString()}>
                        {localDateTimeDisplay(manuallyCompletedStop.completedAt)}
                    </span>
                    <span className='sharedGridItem'>
                        {L.tripEventTypeTaskCompleted() + ` (${manuallyCompletedStop.sequence}) - Web Manual`}
                    </span>
                </div>
            )
        })
    )
}
