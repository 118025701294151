import React, { useMemo } from 'react';
import { useOrganizationQuery } from '../../../../../api/queries/use-organization-query';
import { OrganizationLocation, Stop } from '../../../../../types';
import { OrgQueryKeys } from '../../../../../api/config';
import { toSiteDisplayNameFromOrganizationLocation } from '../../../../../utils/data-mapping';
import { useDraftLoadStopContext } from '../../../hooks/draft-load-stop-context';
import { AgisticsSelect } from '../../../../shared/agistics-select';
import { L } from 'harmony-language';
import { isValidStopLocation } from '../../../../../utils/validation-utils';
import { useEditableStopLocationTravelTimes } from './use-editable-stop-location-travel-times';

interface EditableStopLocationProps {
    stop: Stop;
}

export const EditableStopLocation: React.FC<EditableStopLocationProps> = (props) => {
    const { stop } = props;
    const { onEditedStopChanged, isCarrier, isComplete } = useDraftLoadStopContext();
    const { data: organizationLocations = [] } = useOrganizationQuery<OrganizationLocation[]>(OrgQueryKeys.locations);
    useEditableStopLocationTravelTimes(stop);

    const organizationLocationItems = useMemo(() => {
        return organizationLocations.map(organizationLocation => ({
            id: organizationLocation.id,
            value: toSiteDisplayNameFromOrganizationLocation(organizationLocation),
        }));
    }, [organizationLocations]);

    return (
        <AgisticsSelect
            item={stop.organizationLocationId}
            items={organizationLocationItems}
            onChange={(organizationLocationId) => {
                onEditedStopChanged({ organizationLocationId: organizationLocationId });
            }}
            // add / spit stop unless we default that differently
            showNone={true}
            confinedSpace={true}
            customPopper={true}
            disabled={isCarrier || isComplete}
            validations={[{
                isValid: () => isValidStopLocation(stop.organizationLocationId),
                message: L.invalidLocation(),
            }]}
        />
    );
};
