export const AgisticsColors = {
    black: '#000000',
    navy: '#1f223a',

    lightGray: '#e4e4e4',
    lightGray2: '#d4d4d4',
    gray: '#747474',
    darkGray: '#333333',
    red: '#a81e14',
    green: '#29a84a',
    blue: '#2831A8',
    white: '#ffffff',
    white2: '#f0f0f0',
    orange: '#e57200'
};
