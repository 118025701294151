import React, { FC } from 'react';
import { styled } from '@mui/material/styles';
import { getCurrentUnitSystem, L, UNITS } from 'harmony-language';
import { getDuration } from '../../utils/date-time-utils';
import { metersToKm, metersToMiles } from '../../utils/unit-conversion';

const Unavailable = styled('span')(({ theme }) => ({
    color: theme.palette.error.main,
}));

interface NoMUITravelTimeTooltipProps {
    loadDurationMinutes: number;
    travelTimeMinutes: number;
    travelDistance: number;
}

export const NoMUITravelTimeTooltip: FC<NoMUITravelTimeTooltipProps> = (props) => {
    const { loadDurationMinutes, travelTimeMinutes, travelDistance } = props;
    const minuteMs = 1000 * 60;
    const loadingDurationMs = loadDurationMinutes * minuteMs;

    const isMetric = getCurrentUnitSystem() === UNITS.Metric;
    const convertedDistance = travelDistance ? isMetric ? metersToKm(travelDistance) : metersToMiles(travelDistance) : 0;

    return (
        <div>
            <div style={{ fontWeight: 'bold' }}>{L.estimatedTotalTripTime()}</div>
            <div>{L.loadUnloadDuration()}: {getDuration(loadingDurationMs)}</div>
            <div>{L.travelTime()}: {(travelTimeMinutes || travelTimeMinutes === 0) ?
                getDuration(travelTimeMinutes * minuteMs) :
                <Unavailable>{L.travelTimeUnavailable()}</Unavailable>}
            </div>
            <div>{L.distance()}: {convertedDistance.toFixed(1)} {isMetric ? L.unitsMetricDistanceAbbr() : L.unitsImperialDistanceAbbr()}</div>
        </div>
    );
}
