import React from 'react';
import { useDraftLoadContext } from '../../../hooks/draft-load-context';
import { AgisticsSelect } from '../../../../shared/agistics-select';
import { L } from 'harmony-language';
import { LoadEditableProperties } from '../../../hooks/use-load-editable-items';
import { Load } from '../../../../../types';

interface EditableLoadTractorProps {
    getTractorProperties: (l: Load) => LoadEditableProperties;
    showLabel?: boolean;
    customPopper?: boolean;
}

export const EditableLoadTractor: React.FC<EditableLoadTractorProps> = (props) => {
    const { getTractorProperties, showLabel = true, customPopper } = props;
    const { editedLoad, onEditedLoadChanged } = useDraftLoadContext();
    const tractorProperties = getTractorProperties(editedLoad);

    return (
        <AgisticsSelect
            item={editedLoad.tractorId}
            items={tractorProperties.items}
            disabled={tractorProperties.isDisabled}
            showNone={true}
            onChange={(e) => {
                onEditedLoadChanged({ tractorId: e === -1 ? null : e });
            }}
            label={showLabel ? L.tractor() : undefined}
            confinedSpace={true}
            customPopper={customPopper}
        />
    );
};
