import { OrderStatus, StopType } from 'harmony-constants';
import { Load } from '../../../types';
import { typedCreateStop } from './typed-create-stop';
import moment from 'moment-timezone';

// type Load/Stop not perfect here
// mapping undef on stop.orderId is handled api side
export const typedCreateLoad = (organizationId: number): Load => {
    return {
        date: moment().toISOString(),
        organizationId: organizationId,
        transportingOrganizationId: organizationId,
        transportedByUserId: null,
        status: OrderStatus.Open,
        tractorId: null,
        trailerId: null,
        stops: [
            // @ts-ignore
            typedCreateStop(undefined, { type: StopType.Origin, sequence: 1 }),
            // @ts-ignore
            typedCreateStop(undefined, { type: StopType.Destination, sequence: 2 }),
        ],
        mergedFromOrderIds: null,
        timeSeconds: null,
        distanceMeters: null,
    };
}
