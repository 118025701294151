import React from 'react';
import { L, getCurrentLanguage } from 'harmony-language';
import { OrderGroup } from './use-order-group-table';
import { localDateShort } from '../../../utils/date-time-utils';
import { useOrganizationQuery } from '../../../api/queries/use-organization-query';
import { OrgQueryKeys } from '../../../api/config';
import { OrganizationLocation } from '../../../types';
import { getTransCargoTypeLabel } from '../../../constants/constants';
import { OrderGroupUnlinkAction } from './order-group-unlink-action';
import { toSiteDisplayName } from '../../../utils/data-mapping';
import { useWeights } from '../../shared/hooks/use-weights';
import { createColumnHelper } from '@tanstack/react-table';

const ArrayCell: React.FC<{ value?: string[] }> = (props) => {
    return <div>{props.value?.map(x => <div key={x}>{x}</div>)}</div>;
};

export const useOrderGroupColumns = () => {
    const { data: locations }: { data?: OrganizationLocation[] } = useOrganizationQuery(OrgQueryKeys.locations);
    const languageCode = getCurrentLanguage()
    const { weightSystem, convertFromGramsDisplay } = useWeights();
    const columnHelper = createColumnHelper<OrderGroup>();

    const columns = React.useMemo(() => {
        const c = [
            columnHelper.accessor((orderGroup) => {
                return [...new Set(orderGroup.dateRange.map(localDateShort))].join(' - ');
            }, {
                id: 'dateRange',
                header: L.dateRange(),
                cell: info => info.getValue(),
                enableColumnFilter: false,
            }),
            columnHelper.accessor((orderGroup) => {
                const locs = orderGroup.pickups.map(id => {
                    const location = locations?.find(l => l.id === id);
                    return location ? toSiteDisplayName(location.name, location.description) : '';
                });
                return locs;
            }, {
                id: 'pickups',
                header: L.origin(),
                cell: (info) => <ArrayCell value={info.getValue()} />,
                enableColumnFilter: false,
                size: 200,
            }),
            columnHelper.accessor((orderGroup) => {
                const locs = orderGroup.dropoffs.map(id => {
                    const location = locations?.find(l => l.id === id);
                    return location ? toSiteDisplayName(location.name, location.description) : '';
                });
                return locs;
            }, {
                id: 'dropoffs',
                header: L.destination(),
                cell: (info) => <ArrayCell value={info.getValue()} />,
                enableColumnFilter: false,
                size: 200,
            }),
            columnHelper.accessor((orderGroup) => {
                const cargos = orderGroup.cargo.map(c => {
                    const qtyOrWeight = c.quantity ? new Intl.NumberFormat(languageCode).format(c.quantity) : convertFromGramsDisplay(c.weight || 0);
                    return `${qtyOrWeight} - ${c.productionPlan ? c.productionPlan : getTransCargoTypeLabel(c.cargoTypeId)}`;
                });
                return cargos;
            }, {
                id: 'cargo',
                header: L.cargo(),
                cell: (info) => <ArrayCell value={info.getValue()} />,
                enableColumnFilter: false,
                enableSorting: false,
                size: 200,
            }),
            columnHelper.accessor('orderNumbers', {
                header: L.orderNumber(),
                cell: (info) => <ArrayCell value={info.getValue()} />,
                enableColumnFilter: false,
            }),
            columnHelper.accessor('lotIds', {
                header: L.lotId(),
                cell: (info) => <ArrayCell value={info.getValue()} />,
                enableColumnFilter: false,
            }),
            columnHelper.accessor('numberOfDraftLoads', {
                header: L.drafts(),
                cell: (info) => info.getValue(),
                enableColumnFilter: false,
                size: 100,
            }),
            columnHelper.accessor('numberOfScheduledLoads', {
                header: L.scheduled(),
                cell: (info) => info.getValue(),
                enableColumnFilter: false,
                size: 100,
            }),
            columnHelper.accessor('numberOfHistoricLoads', {
                header: L.delivered(),
                cell: (info) => info.getValue(),
                enableColumnFilter: false,
                size: 100,
            }),
            // @ts-ignore
            columnHelper.accessor('actions', {
                header: L.actions(),
                cell: (info) => <OrderGroupUnlinkAction orderGroup={info.row.original} />,
                enableColumnFilter: false,
                enableSorting: false,
            })
        ];
        return c;
    }, [locations, weightSystem]);

    return columns;
}
