import React from 'react';
import PropTypes from 'prop-types';
import PickUpIcon from '@mui/icons-material/ArrowUpwardOutlined';
import DropOffIcon from '@mui/icons-material/ArrowDownwardOutlined';
import LocationOnIcon from '@mui/icons-material/LocationOnOutlined';
import WeighIcon from './weigh-kg.svg';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { STOP_TYPES } from '../../../../../constants/constants';

export const StopIcon = (props) => {
    const { type, fontSize, ...rest } = props;

    switch (type) {
    case STOP_TYPES().Origin.key:
        return <PickUpIcon fontSize={fontSize} {...rest} />;
    case STOP_TYPES().Destination.key:
        return <DropOffIcon fontSize={fontSize} {...rest} />;
    case STOP_TYPES().Weigh.key:
        return (
            <img src={WeighIcon} width={14} height={14} {...rest} />
        );
    case STOP_TYPES().Waypoint.key:
        return <LocationOnIcon fontSize={fontSize} {...rest} />;
    default:
        return <HourglassEmptyIcon fontSize={fontSize} {...rest} />;
    }
};

StopIcon.propTypes = {
    type: PropTypes.oneOf(Object.keys(STOP_TYPES())),
    fontSize: PropTypes.string
};
