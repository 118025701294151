import React, { FC } from 'react';
import AddBoxOutlined from '@mui/icons-material/AddBoxOutlined';
import { IconButton } from '@mui/material';
import { L } from 'harmony-language';
import { useDraftLoadContext } from '../../../hooks/draft-load-context';
import { useOrganizationQuery } from '../../../../../api/queries/use-organization-query';
import { OrgQueryKeys } from '../../../../../api/config';
import { orderToDefaultCargoType, orderToDefaultCompartments, orderToMissingStopQuantity, orderToMissingStopType, orderToMissingStopWeight, orderToNextStopSequence } from '../../../../../utils/data-mapping-utils';
import { typedCreateStop } from '../../../utils/typed-create-stop';
import { withThroughputs } from '../../../../../utils/throughput-utils';
import { useCarrier } from '../../../../user/selectors/use-permissions';

export const EditableStopAdd: FC = () => {
    const { editedLoad, onEditedLoadChanged, addOneUnitState } = useDraftLoadContext();
    const isCarrier = useCarrier();
    const { data: throughputs } = useOrganizationQuery(OrgQueryKeys.throughput);

    const handleAddStop = () => {
        const nextSequence = orderToNextStopSequence({ stops: editedLoad.stops });
        const stopType = orderToMissingStopType({ stops: editedLoad.stops });
        const cargoTypeId = orderToDefaultCargoType({ stops: editedLoad.stops });
        const compartments = orderToDefaultCompartments({ stops: editedLoad.stops });
        const quantity = orderToMissingStopQuantity({ stops: editedLoad.stops });
        const weight = orderToMissingStopWeight({ stops: editedLoad.stops });

        const newStop = typedCreateStop(editedLoad.id, {
            sequence: nextSequence,
            type: stopType,
            cargoTypeId: cargoTypeId,
            compartments: compartments,
            quantity: quantity,
            weight: weight,
        });

        const newStops = [
            ...editedLoad.stops,
            newStop,
        ];

        const sortedStops = newStops.toSorted((a, b) => a.sequence - b.sequence);

        onEditedLoadChanged({
            ...editedLoad,
            stops: sortedStops.map(withThroughputs(throughputs)),
        });

        addOneUnitState(cargoTypeId);
    }

    return (
        <IconButton
            title={L.add()}
            size='small'
            color='primary'
            disabled={isCarrier}
            onClick={handleAddStop}
        >
            <AddBoxOutlined />
        </IconButton>
    );
};
