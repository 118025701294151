import React from 'react';
import { AgisticsMultiSelect } from '../../../../shared/multi-select/agistics-multi-select';
import { useOrganizationQuery } from '../../../../../api/queries/use-organization-query';
import { OrganizationLocation, Stop } from '../../../../../types';
import { OrgQueryKeys } from '../../../../../api/config';
import { orgSubLocationToItems } from '../../../../../utils/data-mapping';
import { useDraftLoadStopContext } from '../../../hooks/draft-load-stop-context';

interface EditableStopSublocationsProps {
    stop: Stop;
}

export const EditableStopSublocations: React.FC<EditableStopSublocationsProps> = (props) => {
    const { stop } = props;
    const { onEditedStopChanged, isCarrier, isComplete } = useDraftLoadStopContext();
    const { data: locations = [] } = useOrganizationQuery<OrganizationLocation[]>(OrgQueryKeys.locations);
    const subLocationList = orgSubLocationToItems(locations, stop.organizationLocationId);

    return (
        <AgisticsMultiSelect
            selectedIds={stop.organizationSubLocationIds || []}
            setSelectedIds={(organizationSubLocationIds) => {
                const newVal = organizationSubLocationIds.length ? organizationSubLocationIds : null;
                onEditedStopChanged({ organizationSubLocationIds: newVal });
            }}
            items={subLocationList}
            confinedWidth={true}
            smallText={true}
            customPopper={true}
            disableClearable={true}
            disabled={isCarrier || isComplete}
        />
    );
};
