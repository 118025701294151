import React from 'react';
import { Stop } from '../../../../../types';
import { ValidatedTextField } from '../../../../shared/inputs/validated-text-field';
import { useWeights } from '../../../../shared/hooks/use-weights';
import { editableStopInputProps } from '../../../table-components/stops/editable-stop-table-row';
import { InputAdornment } from '@mui/material';
import { useDraftLoadStopContext } from '../../../hooks/draft-load-stop-context';
import { useDraftLoadContext } from '../../../hooks/draft-load-context';
import { stopHasCargo } from '../../../../../constants/constants';
import { getQuantityWeightValidations } from './get-quantity-weight-validations';

interface EditableStopWeightProps {
    stop: Stop;
    isValidated: boolean;
    property: 'weight' | 'actualWeight';
}

export const EditableStopWeight: React.FC<EditableStopWeightProps> = (props) => {
    const { stop, isValidated, property } = props;
    const { editedLoad } = useDraftLoadContext();
    const { onEditedStopChanged, isCarrier, isComplete } = useDraftLoadStopContext();
    const { weightAbbreviation, convertFromGrams, convertToGrams, isDecimalWeight, weightPrecision } = useWeights();

    if (!stopHasCargo(stop)) {
        return null;
    }

    const validations = isValidated
        ? getQuantityWeightValidations(editedLoad, convertFromGrams, weightPrecision)
        : undefined;

    return (
        <ValidatedTextField
            type='number'
            disabled={isCarrier || isComplete}
            value={convertFromGrams(stop[property] || 0) || ''}
            inputProps={{ min: 0, step: isDecimalWeight ? .01 : 1, ...editableStopInputProps }}
            fullWidth
            onChange={(e) => {
                const changedWeight = Number(e.target.value);
                const grams = convertToGrams(changedWeight);
                onEditedStopChanged({ [property]: grams || null });
            }}
            InputProps={{
                endAdornment: <InputAdornment position='end'>{weightAbbreviation}</InputAdornment>,
            }}
            validations={validations}
        />
    );
};
