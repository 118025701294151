import React from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { OrderStatus } from 'harmony-constants';
import { Button } from '@mui/material';
import { L } from 'harmony-language';
import axios from 'axios';
import { useDraftsKey } from '../../../../api/config-hooks';
import { QueryKeys, typedFetchFunction } from '../../../../api/config';
import { Load } from '../../../../types';
import { DraftLoadDisplayHeader, DraftLoadDisplayRow } from '../../load-display-row';
import { CircularLoading } from '../../../shared/circular-loading';

interface UnmergeDraftsProps {
    loadId: number;
    mergedFromOrderIds: number[];
    unmergeCloseDialog: () => void;
}

export const UnmergeDrafts: React.FC<UnmergeDraftsProps> = (props) => {
    const { loadId, mergedFromOrderIds, unmergeCloseDialog } = props;
    const draftsKey = useDraftsKey();

    const unmergeQuery = useQuery({
        queryKey: [QueryKeys.drafts, mergedFromOrderIds],
        queryFn: async () => {
            const params = {
                status: OrderStatus.Merged,
                mergedFromOrderIds: mergedFromOrderIds,
            }
            return (await typedFetchFunction<Load[]>(QueryKeys.drafts, params)).data;
        },
        staleTime: 5 * 60 * 1000, // 5 mins
        cacheTime: 0,
    });
    const queryClient = useQueryClient();

    const unmerge = async () => {
        await axios.post(`${API_BASE}/api/orders/unmerge?orderIds=[${loadId}]`, {
            mergedFromOrderIds: mergedFromOrderIds,
        });
        queryClient.invalidateQueries(draftsKey);
        unmergeCloseDialog();
    };

    if (unmergeQuery.isSuccess) {
        return (
            <>
                <DraftLoadDisplayHeader />
                {unmergeQuery.data.map(load => {
                    return (
                        <DraftLoadDisplayRow load={load} />
                    );
                })}
                <div style={{ paddingTop: '1rem', display: 'flex', justifyContent: 'flex-end' }}>
                    <Button variant='contained' color='primary' onClick={unmerge}>{L.unmerge()}</Button>
                </div>
            </>
        );
    } else {
        return <div style={{ width: '40vw', height: '20vh' }}><CircularLoading /></div>;
    }
};
