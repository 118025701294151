import React from 'react';
import PropTypes from 'prop-types';
import { STOP_TYPES } from '../../../constants/constants';
import { L } from 'harmony-language';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { useWeights } from '../../shared/hooks/use-weights';
import { CargoTypeMode, cargoTypeModeProxyNullHandler } from '../../drafts-loads/table-components/stops/cargo-type-mode-proxy';

const ThroughputEntry = ({ throughput, isLoading, onChange }) => {
    const { stringAbbreviationDisplay, weightPrecision, convertFromGrams, convertToGrams } = useWeights();
    // displayWeight is string so inputting 0 doesn't null textfield
    const [displayWeight, setDisplayWeight] = React.useState(String(convertFromGrams(throughput.weightPerMinute || 0)));

    React.useEffect(() => {
        setDisplayWeight(String(convertFromGrams(throughput.weightPerMinute || 0)));
    }, [convertFromGrams]);

    let baselineLabel, throughputLabel, throughputWeightLabel, label;

    switch (throughput.stopType) {
        case STOP_TYPES().Destination.key:
            baselineLabel = L.defaultUnloadingTimePerStop();
            throughputLabel = L.defaultUnloadingThroughput();
            throughputWeightLabel = L.unloadingWeightThroughput();
            label = L.unloading();
            break;
        case STOP_TYPES().Origin.key:
            baselineLabel = L.defaultLoadingTimePerStop();
            throughputLabel = L.defaultLoadingThroughput();
            throughputWeightLabel = L.loadingWeightThroughput();
            label = L.loading();
            break;
        case STOP_TYPES().Weigh.key:
        default:
            baselineLabel = L.defaultWeighingTimePerStop();
            label = L.weighing();
    }

    const isDefault = throughput.cargoTypeId === null ? true : false;
    const cargoMode = isDefault ? null : cargoTypeModeProxyNullHandler(throughput.cargoTypeId);
    const boolDisplayQuantity = cargoMode === null ? true : cargoMode === CargoTypeMode.Quantity ? true : false;
    const boolDisplayWeight = cargoMode === null ? true : cargoMode === CargoTypeMode.Weight ? true : false;

    return (
        <Grid item xs={12} sm={6}>
            <Typography>{label}</Typography>
            <TextField
                variant='standard'
                fullWidth
                disabled={isLoading}
                id={`default-baseline-loading-minutes-${throughput.stopType}`}
                label={baselineLabel}
                type='number'
                value={throughput?.baselineLoadingMinutes || ''}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                    inputProps: { min: 1, max: 600 },
                    endAdornment: <InputAdornment position='end'>{L.mins()}</InputAdornment>
                }}
                onChange={(e) => {
                    onChange('baselineLoadingMinutes', Number(e.target.value));
                }} />
            {throughputLabel && boolDisplayQuantity && <TextField
                variant='standard'
                style={{ marginTop: '0.5rem' }}
                fullWidth
                disabled={isLoading}
                id={`default-cargo-units-per-minute-${throughput.stopType}`}
                label={throughputLabel}
                type='number'
                value={throughput?.cargoUnitsPerMinute || ''}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                    inputProps: { min: 1, max: 100000 },
                    endAdornment: <InputAdornment position='end'>{L.unitsPerMin()}</InputAdornment>
                }}
                onChange={(e) => {
                    onChange('cargoUnitsPerMinute', Number(e.target.value));
                }} />
            }
            {throughputWeightLabel && boolDisplayWeight && <TextField
                variant='standard'
                style={{ marginTop: '0.5rem' }}
                fullWidth
                disabled={isLoading}
                id={`default-weight-per-minute-${throughput.stopType}`}
                label={throughputWeightLabel}
                type='number'
                value={displayWeight || ''}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                    inputProps: { min: weightPrecision === 2 ? 0 : 1, max: 100000, step: weightPrecision === 2 ? 0.01 : 0 },
                    endAdornment: <InputAdornment position='end'>{stringAbbreviationDisplay}</InputAdornment>
                }}
                onChange={(e) => {
                    const changedWeight = e.target.value;
                    const grams = convertToGrams(Number(changedWeight));
                    setDisplayWeight(changedWeight);
                    onChange('weightPerMinute', grams);
                }} />
            }
        </Grid>
    );
};

ThroughputEntry.propTypes = {
    isLoading: PropTypes.bool,
    throughput: PropTypes.object,
    onChange: PropTypes.func,
};

export default ThroughputEntry;
