import React, { FC } from 'react';
import { Load } from '../../../types';
import { AgisticsTooltip } from '../../shared/agistics-tooltip';
import { PerformanceIcon } from './performance-icons';
import { validateDraft } from '../drafts/utils/validate-drafts';
import { useWeights } from '../../shared/hooks/use-weights';

interface DraftConflictIconProps {
    draft: Load;
}

// the performance on this is not great an icon with a tooltip :(
// done everything I can think of, to limit the amount of times it's actually rendered
export const DraftConflictIcon: FC<DraftConflictIconProps> = (props) => {
    const { draft } = props;
    const { convertFromGrams, weightPrecision } = useWeights();

    const draftConflicts = validateDraft(draft, convertFromGrams, weightPrecision);

    if (!draftConflicts.length) {
        return null;
    }

    const confictItems = draftConflicts.map(e => <div key={e}>{e}</div>);

    return (
        <AgisticsTooltip
            tooltip={confictItems}
            placement='left'
        >
            <PerformanceIcon icon='warning_amber' />
        </AgisticsTooltip>
    )
}
