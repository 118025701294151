import { Stack } from '@mui/material';
import { L } from 'harmony-language';
import React from 'react';
import { OrgQueryKeys, QueryKeys } from '../../../api/config';
import { useOrganizationQuery } from '../../../api/queries/use-organization-query';
import { type OrganizationLocation, type SiteIssueType } from '../../../types';
import { toSiteDisplayName } from '../../../utils/data-mapping';
import { AgisticsMultiSelect } from '../../shared/multi-select/agistics-multi-select';
import { useQuery } from '@tanstack/react-query';

interface SiteIssuesFiltersProps {
    isAdmin: boolean;
    siteIds: number[];
    setSiteIds: React.Dispatch<React.SetStateAction<number[]>>;
    siteIssueTypeIds: number[];
    setSiteIssueTypeIds: React.Dispatch<React.SetStateAction<number[]>>;
    isResolved: number[];
    setIsResolved: React.Dispatch<React.SetStateAction<number[]>>;
}

export const SiteIssuesFilters: React.FC<SiteIssuesFiltersProps> = (props) => {
    const { isAdmin, siteIds, setSiteIds, siteIssueTypeIds, setSiteIssueTypeIds, isResolved, setIsResolved } = props;

    const { data: organizationLocations = [] } = useOrganizationQuery<OrganizationLocation[]>(OrgQueryKeys.locations);
    const { data: siteIssueTypes = [] } = useQuery<SiteIssueType[]>([QueryKeys.siteIssueTypes]);

    const orgLocationMultiItems = organizationLocations.map(orgLocation => ({
        id: orgLocation.id,
        value: toSiteDisplayName(orgLocation.name, orgLocation.description)
    }));
    const siteIssueTypeMultiItems = siteIssueTypes.map(siteIssueType => ({
        id: siteIssueType.id,
        // @ts-ignore
        value: L[siteIssueType.key]()
    }));
    const isResolvedMultiItems = [{
        id: 1,
        value: L.resolved()
    }, {
        id: 2,
        value: L.unresolved()
    }];

    return (
        !isAdmin && <Stack sx={{ width: '20rem' }}>
            <AgisticsMultiSelect
                selectedIds={siteIds}
                setSelectedIds={setSiteIds}
                items={orgLocationMultiItems}
                label={L.sites()}
            />
            <AgisticsMultiSelect
                selectedIds={siteIssueTypeIds}
                setSelectedIds={setSiteIssueTypeIds}
                items={siteIssueTypeMultiItems}
                label={L.siteIssuesIssueType()}
            />
            <AgisticsMultiSelect
                selectedIds={isResolved}
                setSelectedIds={setIsResolved}
                items={isResolvedMultiItems}
                label={L.resolutionStatus()}
            />
        </Stack>
    )
}