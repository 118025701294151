import React, { FC, FormEvent } from 'react';
import { Load } from '../../../../types';
import { DraftLoadProvider, useDraftLoadContext } from '../../hooks/draft-load-context';
import { LoadEditableItems } from '../../hooks/use-load-editable-items';
import { ResourceScheduling } from './resource-scheduling';
import { useEditLoadMutation } from '../../../../api/mutations/reworked/edit/use-edit-load-mutation';

interface ConditionalResourceSchedulingProps {
    loadEditableItems: LoadEditableItems;
    load: Load;
    onClose: () => void;
}

// DraftLoadProvider is not loaded on every row at inital start
// when modal opens and row is not editable, need to load the provider
// mainly for performance reasons
export const ConditionalResourceScheduling: FC<ConditionalResourceSchedulingProps> = (props) => {
    const { loadEditableItems, load, onClose } = props;

    return (
        <DraftLoadProvider inputLoad={load}>
            <ResourceSchedulingForm
                loadEditableItems={loadEditableItems}
                onClose={onClose}
            />
        </DraftLoadProvider>
    );
}

interface ResourceSchedulingForm {
    loadEditableItems: LoadEditableItems;
    onClose: () => void;
}

const ResourceSchedulingForm: FC<ResourceSchedulingForm> = (props) => {
    const { loadEditableItems, onClose } = props;
    const { editedLoad } = useDraftLoadContext();
    const { mutate: editLoad } = useEditLoadMutation(editedLoad.isDraft);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        editLoad({ load: editedLoad });
        onClose();
    };

    return (
        <form onSubmit={handleSubmit}>
            <ResourceScheduling
                loadEditableItems={loadEditableItems}
                onClose={onClose}
            />
        </form>
    )
}
