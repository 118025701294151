import { ColumnDef, ExpandedState, getCoreRowModel, getExpandedRowModel, getFacetedUniqueValues, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, PaginationState, RowSelectionState, Updater, useReactTable } from '@tanstack/react-table';
import { Load } from '../../../types';
import { FIRST_STOP_COLUMN } from './use-draft-load-columns';
import { useState } from 'react';
import { useUser } from '../../../api/queries/use-user';
import { getDraftsLoadsDefaultSort, getPageSize } from '../utils/drafts-loads-utils';
import { useLoadsGlobalFilter } from '../table-components/filters/use-loads-global-filter';

// getSortedRowModel - https://github.com/TanStack/table/issues/4888
// provided getSortedRowModel function from @tanstack/react-table causes 2 renders when sorting
// spent quite a lot of time trying to write our own, but determined probably more work then it's worth at this time

export const useDraftLoadTable = (
    isDrafts: boolean,
    columns: ColumnDef<Load, any>[],
    loads: Load[],
) => {
    const { user } = useUser();
    const [expanded, setExpanded] = useState<ExpandedState>({});
    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
    const [rowSelectionOrder, setRowSelectionOrder] = useState<number[]>([]);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: getPageSize(isDrafts, user),
    });

    const loadsGlobalFilter = useLoadsGlobalFilter();

    const onPaginationChange = (updater: Updater<PaginationState>) => {
        if (typeof updater === 'function') {
            const newPagination = updater(pagination);
            setPagination(newPagination);
        }
    }

    const onSelectionChange = (updater: Updater<RowSelectionState>) => {
        if (typeof updater === 'function') {
            const newSelection = updater(rowSelection);
            setRowSelection(newSelection);

            const newSelectionKeys = Object.keys(newSelection);

            if (newSelectionKeys.length) {
                const shouldRemove = newSelectionKeys.every(x => rowSelectionOrder.includes(parseInt(x)));

                if (shouldRemove) {
                    const newRowSelectionOrder = rowSelectionOrder.filter(x => newSelectionKeys.includes(x.toString()));

                    setRowSelectionOrder(newRowSelectionOrder);
                } else {
                    const added = newSelectionKeys.filter(x => !rowSelectionOrder.includes(parseInt(x)));
                    const mappedAdded = added.map(x => parseInt(x));
                    const newRowSelectionOrder = [
                        ...rowSelectionOrder,
                        ...mappedAdded,
                    ];

                    setRowSelectionOrder(newRowSelectionOrder);
                }
            } else {
                setRowSelectionOrder([]);
            }
        }
    }

    const table = useReactTable({
        data: loads,
        columns: columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(), // this enables client side pagination
        // read above for performance info on getSortedRowModel
        getSortedRowModel: getSortedRowModel(),

        // FILTERS
        // these do not work like you would expect.  A gloabl filter from what I can tell in react-table means
        // run this filter on each COLUMN you tell me to. While what we are after is, here is a list of rules
        // for each row, check each row ONCE for all these things, if any exists, return true for that row, no need
        // to run the logic on each column (ignoring the fact that there are some crazy rules determining 'can-filter' based on data-type of the data)
        // https://tanstack.com/table/latest/docs/api/features/global-filtering#can-filter
        // because of that all columns are set enableGlobalFilter: false. Except STATUS_COLUMN which will fire off the global filter
        // how I would execpt a gloabl filter to run once per row.
        getFilteredRowModel: getFilteredRowModel(),
        globalFilterFn: loadsGlobalFilter,

        getFacetedUniqueValues: getFacetedUniqueValues(),
        getExpandedRowModel: getExpandedRowModel(),
        onRowSelectionChange: onSelectionChange,
        // getRowId - used to keep track of load ids when selecting loads and new loads get added to the page (refresh)
        getRowId: (row) => row.id.toString(),
        initialState: {
            sorting: [{
                id: FIRST_STOP_COLUMN,
                desc: getDraftsLoadsDefaultSort(user.preferences),
            }],
            rowSelection: rowSelection,
        },
        rowCount: loads.length,
        enableSortingRemoval: false,
        // if this is not set, it must use datatype of accessor to determine default sort?
        // might be asc or desc depending
        sortDescFirst: false,
        state: {
            pagination: pagination,
            rowSelection: rowSelection,
            expanded: expanded,
        },
        onExpandedChange: setExpanded,
        onPaginationChange: onPaginationChange,
    });

    return {
        table: table,
        rowSelectionOrder: rowSelectionOrder,
    };
}
