import React from 'react';
import { Load, OrganizationLocation } from '../../../../types';
import { Select } from '../../../shared/inputs/select';
import { L } from 'harmony-language';
import { StopType } from 'harmony-constants';
import { AgisticsDateTimePicker } from '../../../shared/inputs/agistics-date-time-picker';
import { localDateTimeDisplay, localNow } from '../../../../utils/date-time-utils';
import { Button, FormControlLabel, styled, Switch } from '@mui/material';
import { SelectCascadeInterval } from '../../../shared/inputs/select-cascade-interval';
import { CascadeIntervals } from '../cascade/use-cascade-stops';
import moment from 'moment-timezone';
import { toSiteDisplayName } from '../../../../utils/data-mapping';
import { useOrganizationQuery } from '../../../../api/queries/use-organization-query';
import { OrgQueryKeys } from '../../../../api/config';
import { useDraftsMutation } from '../../../../api/mutations/reworked/use-drafts-mutation';
import { ModalFooter } from '../../../shared/containers/modal-footer';

const BulkModifyContainer = styled('div')({
    display: 'flex',
    columnGap: '10px',
    marginTop: '10px',
    maxWidth: '30rem',
});

const BulkModifyRow = styled('div')({
    display: 'grid',
    gridTemplateColumns: '.5fr .5fr 2fr repeat(2, 1fr)',
    gridColumnGap: '1rem',
});

const stopTypeItems = [
    {
        id: StopType.Origin,
        label: StopType.Origin,
    },
    {
        id: StopType.Destination,
        label: StopType.Destination,
    },
];

interface BulkModifyProps {
    drafts: Load[];
    onSubmit: () => void;
}

// this is a lot like cascade, but reason it's not reusing
// might be a (different) feature?
// cascade does not have an origin/destination mode
export const BulkModify: React.FC<BulkModifyProps> = (props) => {
    const { drafts, onSubmit } = props;
    const [mode, setMode] = React.useState(stopTypeItems[1]);
    const [modifiedDrafts, setModifiedDrafts] = React.useState(drafts);
    const [requiredBegin, setRequiredBegin] = React.useState<string | null>(localNow().startOf('hour').toISOString());
    const [requiredEnd, setRequiredEnd] = React.useState<string | null>(localNow().add(1, 'hour').startOf('hour').toISOString());
    const [checked, setChecked] = React.useState<boolean>(false);
    const [interval, setInterval] = React.useState<typeof CascadeIntervals[0]>(CascadeIntervals[3]);
    const { data: locations = [] } = useOrganizationQuery<OrganizationLocation[]>(OrgQueryKeys.locations);
    const { mutate: saveDrafts } = useDraftsMutation();
    const allLoadsHaveAtLeastOneStopOfModeType = drafts.map(x => x.stops.filter(x => x.type === mode.id).length).every(x => Boolean(x));

    const applyModifications = () => {
        const newModifiedDrafts = modifiedDrafts.map((modifiedDraft, i) => {
            const modRequiredBegin = checked ? moment(requiredBegin).add(i * interval.id, 'minutes').toISOString() : requiredBegin;
            const modRequiredEnd = checked ? moment(requiredEnd).add(i * interval.id, 'minutes').toISOString() : requiredEnd;

            return {
                ...modifiedDraft,
                stops: modifiedDraft.stops.map(stop => {
                    if (stop.type === mode.id) {
                        return {
                            ...stop,
                            requiredBegin: modRequiredBegin,
                            requiredEnd: modRequiredEnd,
                        }
                    } else {
                        return stop;
                    }
                })
            }
        });

        setModifiedDrafts(newModifiedDrafts);
    }

    const handleSubmit = () => {
        saveDrafts({ drafts: modifiedDrafts });
        onSubmit();
    };

    return (
        <div style={{ width: '50vw' }}>
            <Select
                label={L.stopType()}
                list={stopTypeItems}
                item={mode}
                style={{ width: '20%' }}
                onChange={(item) => {
                    // reset internal state
                    setModifiedDrafts(drafts);
                    setMode(item);
                }}
            />
            <div>
                <BulkModifyContainer>
                    <AgisticsDateTimePicker
                        label={L.requiredBegin()}
                        value={requiredBegin}
                        onChange={(date) => setRequiredBegin(date)}
                    />
                    <AgisticsDateTimePicker
                        label={L.requiredEnd()}
                        value={requiredEnd}
                        onChange={(date) => setRequiredEnd(date)}
                    />
                </BulkModifyContainer>
                <BulkModifyContainer>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={checked}
                                onChange={() => setChecked(!checked)}
                            />
                        }
                        label={L.cascade()}
                    />
                    {checked && <SelectCascadeInterval
                        item={interval}
                        onChange={setInterval}
                        style={{ width: '20%' }}
                    />}
                </BulkModifyContainer>
            </div>
            <div style={{ margin: '1rem 0 1rem 0' }}>
                <BulkModifyRow>
                    <span>{L.load()}</span>
                    <span>{L.type()}</span>
                    <span>{L.site()}</span>
                    <span>{L.requiredBegin()}</span>
                    <span>{L.requiredEnd()}</span>
                </BulkModifyRow>
                {modifiedDrafts.map(draft => {
                    return draft.stops.filter(stop => stop.type === mode.id).map(stop => {
                        const location = locations.find(x => x.id === stop.organizationLocationId);

                        return (
                            <BulkModifyRow key={stop.id}>
                                <span>{draft.id}</span>
                                <span>{stop.type}</span>
                                <span>{location ? toSiteDisplayName(location.name, location.description) : ''}</span>
                                <span>{stop.requiredBegin && localDateTimeDisplay(stop.requiredBegin)}</span>
                                <span>{stop.requiredEnd && localDateTimeDisplay(stop.requiredEnd)}</span>
                            </BulkModifyRow>
                        );
                    });
                })}
            </div>
            <ModalFooter>
                <div style={{ visibility: !allLoadsHaveAtLeastOneStopOfModeType && checked ? 'visible' : 'hidden', color: '#F44336' }}>
                    {L.bulkModifyErrorExplanation()}
                </div>
                <div>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={applyModifications}
                        style={{ marginRight: '1rem' }}
                    >
                        {L.apply()}
                    </Button>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={handleSubmit}
                    >
                        {L.save()}
                    </Button>
                </div>
            </ModalFooter>
        </div>
    );
};
