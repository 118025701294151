import React, { FC, useState } from 'react';
import { genDestinationIds, genOriginIds } from './utils/drafts-loads-utils';
import { Driver, Load, OrganizationLocation } from '../../types';
import { LoadOrderNumbers } from './drafts-loads-table-cells/loads/display/load-order-numbers';
import { filterStopByDestination, filterStopByOrigin, loadToFirstStop, loadToLastStop, toDriverDisplayName, toTractorDisplayName, toTrailerDisplayName } from '../../utils/data-mapping-utils';
import { LoadStopLocation } from './drafts-loads-table-cells/loads/display/load-stop-location';
import { useOrganizationQuery } from '../../api/queries/use-organization-query';
import { OrgQueryKeys } from '../../api/config';
import { LoadStopTime } from './drafts-loads-table-cells/loads/display/load-stop-time';
import { LoadCargo } from './drafts-loads-table-cells/loads/display/load-cargo';
import { useWeights } from '../shared/hooks/use-weights';
import { useTractorTrailer } from '../../api/queries/use-tractor-trailer';
import { ORDER_STATUSES } from '../../constants/constants';
import { L } from 'harmony-language';
import { PerformanceExpander } from './drafts-loads-table-cells/performance-expander';
import { LoadTableStops } from './table-components/stops/load-table-stops';

const templateString = '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr .5fr';

export const DraftLoadDisplayHeader: FC = () => {
    return (
        <div style={{ display: 'grid', gridTemplateColumns: templateString, fontWeight: 'bold' }}>
            <span>{L.orderNumber()}</span>
            <span>{L.origin()}</span>
            <span>{L.destination()}</span>
            <span>{L.firstStop()}</span>
            <span>{L.lastStop()}</span>
            <span>{L.cargo()}</span>
            <span>{L.driver()}</span>
            <span>{L.tractor()}</span>
            <span>{L.trailer()}</span>
            <span>{L.status()}</span>
            <span></span>
        </div>
    )
}

interface DraftLoadDisplayRowProps {
    load: Load;
}

// this can probably be improved, but just needed something quick
// for LoadDisplay, currently only used on unmerge
export const DraftLoadDisplayRow: FC<DraftLoadDisplayRowProps> = (props) => {
    const { load } = props;
    const { data: organizationLocations = [] } = useOrganizationQuery<OrganizationLocation[]>(OrgQueryKeys.locations);
    const { data: drivers = [] } = useOrganizationQuery<Driver[]>(OrgQueryKeys.drivers);
    const { tractors = [], trailers = [] } = useTractorTrailer();
    const [rowExpanded, setRowExpanded] = useState(false);

    const { convertFromGramsDisplay } = useWeights();

    const originIds = genOriginIds(load);
    const uniqueOriginIds = [...new Set(originIds)];
    const origins = load.stops.filter(filterStopByOrigin);

    const destinationIds = genDestinationIds(load);
    const uniqueDestinationIds = [...new Set(destinationIds)];
    const destinations = load.stops.filter(filterStopByDestination);

    const firstStop = loadToFirstStop(load);
    const lastStop = loadToLastStop(load);

    return (
        <>
            <div
                className='lt-row-parent'
                style={{
                    display: 'grid',
                    gridTemplateColumns: templateString,
                    columnGap: '5px',
                    alignItems: 'center',
                    padding: '1rem 0'
                }}
            >
                <div>
                    <LoadOrderNumbers load={load} />
                </div>
                <div>
                    {uniqueOriginIds.map(uniqueOriginId => {
                        return (
                            <LoadStopLocation
                                key={uniqueOriginId}
                                organizationLocationId={uniqueOriginId}
                                organizationLocations={organizationLocations}
                                stops={origins}
                            />
                        );
                    })}
                </div>
                <div>
                    {uniqueDestinationIds.map(uniqueDestinationId => {
                        return (
                            <LoadStopLocation
                                key={uniqueDestinationId}
                                organizationLocationId={uniqueDestinationId}
                                organizationLocations={organizationLocations}
                                stops={destinations}
                            />
                        );
                    })}
                </div>
                <div>
                    <LoadStopTime time={firstStop.arrivalTime} />
                </div>
                <div>
                    <LoadStopTime time={lastStop.arrivalTime} />
                </div>
                <div>
                    <LoadCargo load={load} convertFromGramsDisplay={convertFromGramsDisplay} />
                </div>
                <div>
                    {toDriverDisplayName(drivers)(load)}
                </div>
                <div>
                    {toTractorDisplayName(tractors)(load)}
                </div>
                <div>
                    {toTrailerDisplayName(trailers)(load)}
                </div>
                <div>
                    {ORDER_STATUSES()[load.status].label}
                </div>
                <div>
                    <PerformanceExpander
                        expanded={rowExpanded}
                        onChange={() => setRowExpanded(!rowExpanded)}
                    />
                </div>
            </div>
            {rowExpanded && (
                <LoadTableStops
                    isDrafts={true}
                    load={load}
                />
            )}
        </>
    );
};
