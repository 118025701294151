export enum CargoTypeMode {
    Quantity = 'quantity',
    Weight = 'weight',
}

const CargoTypeModes = {
    1: CargoTypeMode.Quantity,
    2: CargoTypeMode.Weight,
    3: CargoTypeMode.Quantity,
    4: CargoTypeMode.Quantity,
    // 5:
    6: CargoTypeMode.Quantity,
    7: CargoTypeMode.Quantity,
    // 8:
    9: CargoTypeMode.Weight,
    10: CargoTypeMode.Weight,
    11: CargoTypeMode.Weight,
    12: CargoTypeMode.Quantity,
    13: CargoTypeMode.Quantity,
    14: CargoTypeMode.Quantity,
    15: CargoTypeMode.Quantity,
    16: CargoTypeMode.Quantity,
    17: CargoTypeMode.Quantity,
    18: CargoTypeMode.Quantity,
    19: CargoTypeMode.Quantity,
    20: CargoTypeMode.Quantity,
    // 21: other - not supported
    22: CargoTypeMode.Weight,
    23: CargoTypeMode.Weight,
    24: CargoTypeMode.Weight,
    25: CargoTypeMode.Weight,
    26: CargoTypeMode.Weight,
    27: CargoTypeMode.Weight,
    28: CargoTypeMode.Weight,
    29: CargoTypeMode.Weight,
    30: CargoTypeMode.Weight,
    31: CargoTypeMode.Weight,
    32: CargoTypeMode.Weight,
    33: CargoTypeMode.Weight,
    34: CargoTypeMode.Weight,
    35: CargoTypeMode.Weight,
    36: CargoTypeMode.Quantity,
};

interface ModeInterface {
    [key: number]: CargoTypeMode;
}

const createCargoTypeModeProxy = (target: ModeInterface) => {
    const handler = {
        get(target: typeof CargoTypeModes, cargoTypeId: PropertyKey) {
            if (!Reflect.has(target, cargoTypeId)) {
                return CargoTypeMode.Quantity;
            }

            return Reflect.get(target, cargoTypeId);
        },
    };

    return new Proxy(target, handler);
}

// I plan on moving this to harmony-db project at some point
// waiting on results of volumetric / bushel discussion

// TS does not like when you try and access null property on object even when handled by proxy
export const cargoTypeModeProxyNullHandler = (cargoTypeId: number | null) => {
    if (!cargoTypeId) {
        return CargoTypeMode.Quantity;
    }

    const cargoTypeModeProxy = createCargoTypeModeProxy(CargoTypeModes);

    return cargoTypeModeProxy[cargoTypeId];
}
