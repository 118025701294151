import { Checkbox, InputAdornment, ListItem, ListItemText, styled, TextField } from '@mui/material';
import { StopStatus } from 'harmony-constants';
import { getCurrentUnitSystem, L, UNITS } from 'harmony-language';
import React, { ChangeEvent } from 'react';
import { OrgQueryKeys } from '../../../../api/config';
import { useOrganizationQuery } from '../../../../api/queries/use-organization-query';
import { STOP_STATUSES, STOP_TYPES } from '../../../../constants/constants';
import { type OrganizationLocation, type Stop } from '../../../../types';
import { toSiteDisplayName } from '../../../../utils/data-mapping';
import { kmToMeters, metersToKm, metersToMiles, milesToMeters } from '../../../../utils/unit-conversion';
import { AgisticsDateTimePicker } from '../../../shared/inputs/agistics-date-time-picker';
import { useTravelTimesQuery } from '../../../travel-times/use-travel-times';

export const StopRow = styled(ListItem)({
    display: 'grid',
    alignItems: 'center',
    borderTop: '1px solid #e0e0e0',
    gridAutoColumns: '3vw 7vw 7vw 10vw 6vw 9vw 14vw 3vw',
    gridAutoFlow: 'column'
});

interface ManuallyCompleteStopItemProps {
    stop: Stop;
    travelTimeKey: string | undefined;
    handleChange: (id: number, s: Stop) => void;
}

export const ManuallyCompleteStopItem: React.FC<ManuallyCompleteStopItemProps> = (props) => {
    const { stop, travelTimeKey, handleChange } = props;
    const { data: locations = [] } = useOrganizationQuery<OrganizationLocation[]>(OrgQueryKeys.locations);
    const { data: travelTimeCache } = useTravelTimesQuery(false);

    const stopIsComplete = React.useMemo(() => stop.status === StopStatus.Complete, [stop]);
    const site = locations.find(e => e.id === stop.organizationLocationId);
    const estimatedDistance = travelTimeCache && travelTimeKey && travelTimeCache[travelTimeKey].distanceMeters || 0;
    const distanceValue = stop.distanceMeters !== null ? stop.distanceMeters : estimatedDistance;
    const isMetric = getCurrentUnitSystem() === UNITS.Metric;
    const convertedDistance = isMetric ? metersToKm(distanceValue) : metersToMiles(distanceValue);

    const handleDistanceChange = (newDistance: number) => {
        const convertedDistance = isMetric ? kmToMeters(newDistance) : milesToMeters(newDistance);
        const updatedStop = {
            ...stop,
            distanceMeters: convertedDistance
        }
        handleChange(stop.id, updatedStop);
    }

    const handleDateTimeChange = (dateTime: string) => {
        const updatedCompleted = {
            ...stop,
            completedAt: dateTime
        }
        handleChange(stop.id, updatedCompleted);
    }

    const handleNotifyChange = () => {
        const updatedNotify = {
            ...stop,
            metadata: {
                ...stop.metadata,
                suppressDeliveryNotification: !stop.metadata?.suppressDeliveryNotification,
            },
        }
        handleChange(stop.id, updatedNotify);
    }

    return (
        <>
            <StopRow>
                <ListItemText primary={stop.id} />
                <ListItemText primary={STOP_TYPES()[stop.type].label} />
                <ListItemText primary={STOP_STATUSES()[stop.status].label} />
                <ListItemText primary={site ? toSiteDisplayName(site.name, site.description) : ''} />
                <TextField
                    variant='standard'
                    type='number'
                    value={Number(convertedDistance.toFixed())}
                    disabled={stopIsComplete}
                    onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleDistanceChange(Number(e.target.value))}
                    inputProps={{ min: 0 }}
                    InputProps={{
                        endAdornment: <InputAdornment position='end'>{isMetric ? L.unitsMetricDistanceAbbr() : L.unitsImperialDistanceAbbr()}</InputAdornment>,
                    }}
                    style={{ width: '5vw' }}
                />
                <ListItemText primary={stop.orderNumber} />
                <AgisticsDateTimePicker
                    value={stop.completedAt}
                    onChange={(date: string | null) => {
                        if (date) {
                            handleDateTimeChange(date)
                        }
                    }}
                    disabled={stopIsComplete}
                />
                {!stopIsComplete && <Checkbox checked={!stop.metadata?.suppressDeliveryNotification} onChange={handleNotifyChange} />}
            </StopRow>
        </>
    );
}