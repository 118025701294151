import React, { CSSProperties } from 'react';
import { Select } from './select';
import { CascadeIntervals } from '../../drafts-loads/drafts/cascade/use-cascade-stops';
import { L } from 'harmony-language';

interface SelectCascadeIntervalProps {
    isLoading?: boolean;
    item: typeof CascadeIntervals[0];
    onChange: (a: any) => void;
    style?: CSSProperties
}

export const SelectCascadeInterval: React.FC<SelectCascadeIntervalProps> = (props) => {
    const { isLoading, item, onChange, style } = props;

    return (
        <Select
            disabled={isLoading}
            label={L.cascadeInterval()}
            list={CascadeIntervals}
            item={item}
            onChange={onChange}
            style={style}
        />
    );
};
