import React, { FC, useMemo } from 'react';
import { useOrganizationQuery } from '../../../../../api/queries/use-organization-query';
import { OrganizationLocation, Stop } from '../../../../../types';
import { OrgQueryKeys } from '../../../../../api/config';

interface DisplayStopSublocationsProps {
    stop: Stop;
}

export const DisplayStopSublocations: FC<DisplayStopSublocationsProps> = (props) => {
    const { stop } = props;
    const { data: organizationLocations = [] } = useOrganizationQuery<OrganizationLocation[]>(OrgQueryKeys.locations);

    const sublocationNames = useMemo(() => {
        const organizationLocation = organizationLocations.find(x => x.id === stop.organizationLocationId);
        if (organizationLocation) {
            const names = organizationLocation?.orgSubLocations?.filter(x => stop.organizationSubLocationIds?.includes(x.id)).map(x => x.name).join(', ');
            return names;
        }
    }, [stop]);

    return <span title={sublocationNames}>{sublocationNames}</span>;
};
