import React, { FC, useEffect, useState } from 'react';
import { ContentHeader } from '../shared/containers/content-header';
import { Button, Paper, Typography } from '@mui/material';
import Add from '@mui/icons-material/Add';
import { Content } from '../shared/containers/content';
import { useCarrier, usePermissions } from '../user/selectors/use-permissions';
import { PermissionType } from 'harmony-constants';
import { useDraftLoadColumns } from './hooks/use-draft-load-columns';
import { DraftLoadTable } from './table-components/draft-load-table';
import { useTravelTimesQuery } from '../travel-times/use-travel-times';
import { LoadTableToolbar } from './load-table-toolbar';
import { useDraftLoadTable } from './hooks/use-draft-load-table';
import { Load } from '../../types';
import { useDraftsLoads } from '../../api/queries/use-drafts-loads';
import { DraftLoadModals } from './draft-load-modals';
import { L } from 'harmony-language';
import { useUser } from '../../api/queries/use-user';
import { getPageSize } from './utils/drafts-loads-utils';
import { SelectionButtons } from './selection-buttons';
import { DraftsLoadsProvider } from './drafts-loads-context';

interface DraftsLoadsProps {
    isDrafts: boolean;
}

export const DraftsLoads: FC<DraftsLoadsProps> = (props) => {
    const { isDrafts } = props;

    const draftsLoadsQuery = useDraftsLoads(isDrafts);
    useTravelTimesQuery(draftsLoadsQuery.isFetching);

    const canCreateOrder = usePermissions(PermissionType.CreateOrder);
    const isCarrier = useCarrier();

    const [dialogOpen, setDialogOpen] = useState(false);
    const [bulkDraftsOpen, setBulkDraftsOpen] = useState(false);

    const [editableLoad, setEditableLoad] = useState<Load | null>(null);
    const [delieryLogLoad, setDeliveryLogLoad] = useState<Load | undefined>(undefined);
    const [unmergeLoad, setUnmergeLoad] = useState<Load | null>(null);

    const [completableLoads, setCompletableLoads] = useState<Load[]>([]);
    const [assignableLoads, setAssignableLoads] = useState<Load[]>([]);
    const [carrierLoads, setCarrierLoads] = useState<Load[]>([]);
    const [deletableLoads, setDeletableLoads] = useState<Load[]>([]);
    const [bulkModifyRecords, setBulkModifyRecords] = useState<Load[]>([]);
    const [mergeRecords, setMergeRecords] = useState<Load[]>([]);
    const [cascadeRecords, setCascadeRecords] = useState<Load[]>([]);
    const [publishRecords, setPublishRecords] = useState<Load[]>([]);

    // I don't like this, but I can't figure out a better way of doing it.  Problem I have is default
    // preset should only apply on 1st page load and when switching from one page to the other.
    // Can't do this logic in MultiFilter because it's re-rendered on date change which at that time
    // default presets should not be applied.  Can't apply the filter in the parent because facetedKeys are not known
    // and user may have a default preset that may or may not have matching data in the result set.
    const [applyDefaultPreset, setApplyDefaultPreset] = useState(true);

    const handleEditableLoadOpen = (load: Load) => {
        setEditableLoad(load);
    }

    const handleDeliveryLogOpen = (load: Load) => {
        setDeliveryLogLoad(load);
    }

    const handleUnmergeOpen = (load: Load) => {
        setUnmergeLoad(load)
    }

    const { columns, isLoadingColumns } = useDraftLoadColumns(isDrafts, handleEditableLoadOpen, handleDeliveryLogOpen, handleUnmergeOpen, applyDefaultPreset, setApplyDefaultPreset);
    const { table, rowSelectionOrder } = useDraftLoadTable(isDrafts, columns, draftsLoadsQuery.data || []);

    const { user } = useUser();

    // resetColumnFilters is special
    // rule is it only gets reset when switching pages, but not when using date filters.
    // Once reset in useEffect on isDrafts change. The applyDefaultPrest logic will reload
    // the proper default presets (if any)
    const resetTableState = () => {
        table.setPagination({
            pageIndex: 0,
            pageSize: getPageSize(isDrafts, user),
        });
        table.resetRowSelection(true);
        table.resetExpanded(true);
    }

    useEffect(() => {
        resetTableState();
        table.resetColumnFilters(true);
        setApplyDefaultPreset(true);
    }, [isDrafts]);

    const isLoading = draftsLoadsQuery.isLoading || isLoadingColumns;
    const isFetching = draftsLoadsQuery.isFetching;

    return (
        <>
            <ContentHeader>
                <div className='lt-header-container'>
                    <Typography variant='h5'>{isDrafts ? L.drafts() : L.loads()} - {user.organization.name}</Typography>
                    {!isDrafts && canCreateOrder && !isCarrier &&
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={() => setDialogOpen(true)}
                        >
                            <Add />{L.createLoad()}
                        </Button>
                    }
                    {isDrafts && canCreateOrder && !isCarrier &&
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={() => setBulkDraftsOpen(true)}
                        >
                            <Add />{L.createDrafts()}
                        </Button>
                    }
                    <SelectionButtons
                        isDrafts={isDrafts}
                        isLoading={isLoading}
                        table={table}
                        setCompletableLoads={setCompletableLoads}
                        setCarrierLoads={setCarrierLoads}
                        setAssignableLoads={setAssignableLoads}
                        setDeletableLoads={setDeletableLoads}
                        setBulkModifyRecords={setBulkModifyRecords}
                        setMergeRecords={setMergeRecords}
                        setCascadeRecords={setCascadeRecords}
                        setPublishRecords={setPublishRecords}
                        rowSelectionOrder={rowSelectionOrder}
                    />
                </div>
            </ContentHeader>
            <DraftLoadModals
                isDrafts={isDrafts}
                table={table}
                dialogOpen={dialogOpen}
                setDialogOpen={setDialogOpen}
                bulkDraftsOpen={bulkDraftsOpen}
                setBulkDraftsOpen={setBulkDraftsOpen}
                editableLoad={editableLoad}
                setEditableLoad={setEditableLoad}
                delieryLogLoad={delieryLogLoad}
                setDeliveryLogLoad={setDeliveryLogLoad}
                unmergeLoad={unmergeLoad}
                setUnmergeLoad={setUnmergeLoad}
                completableLoads={completableLoads}
                setCompletableLoads={setCompletableLoads}
                assignableLoads={assignableLoads}
                carrierLoads={carrierLoads}
                setCarrierLoads={setCarrierLoads}
                setAssignableLoads={setAssignableLoads}
                deletableLoads={deletableLoads}
                setDeletableLoads={setDeletableLoads}
                bulkModifyRecords={bulkModifyRecords}
                setBulkModifyRecords={setBulkModifyRecords}
                mergeRecords={mergeRecords}
                setMergeRecords={setMergeRecords}
                cascadeRecords={cascadeRecords}
                setCascadeRecords={setCascadeRecords}
                publishRecords={publishRecords}
                setPublishRecords={setPublishRecords}
            />
            <Content style={{ height: '100%', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
                <Paper sx={{ height: '100%', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
                    <DraftsLoadsProvider>
                        <LoadTableToolbar
                            isDrafts={isDrafts}
                            refetch={draftsLoadsQuery.refetch}
                            table={table}
                            resetTableState={resetTableState}
                        />
                        <DraftLoadTable
                            isDrafts={isDrafts}
                            isLoading={isLoading}
                            isFetching={isFetching}
                            table={table}
                        />
                    </DraftsLoadsProvider>
                </Paper>
            </Content>
        </>
    );
};
