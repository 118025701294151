import React, { FC } from 'react';
import { Stop } from '../../../../../types';
import { AgisticsDateTimePicker } from '../../../../shared/inputs/agistics-date-time-picker';
import { useDraftLoadStopContext } from '../../../hooks/draft-load-stop-context';
import { isValidStopArrivalTime } from '../../../../../utils/data-mapping-utils';
import { useDraftLoadContext } from '../../../hooks/draft-load-context';
import { L } from 'harmony-language';
import { isValidDate } from '../../../../../utils/date-time-utils';
import { getNewLoadDate } from '../../../utils/drafts-loads-utils';

interface EditableStopArrivalTimeProps {
    stop: Stop;
    isDrafts: boolean;
}

export const EditableStopArrivalTime: FC<EditableStopArrivalTimeProps> = (props) => {
    const { stop, isDrafts } = props;
    const { editedLoad } = useDraftLoadContext();
    const { onEditedStopChanged, isCarrier, isComplete } = useDraftLoadStopContext();

    const validations = isDrafts ? undefined : [{
        isValid: () => isValidStopArrivalTime(stop, editedLoad),
        message: L.loadInvalidStopTimeOrder(),
    }];

    return (
        <AgisticsDateTimePicker
            value={stop.arrivalTime}
            disabled={isCarrier || isComplete}
            onChange={(dt: string | null) => {
                // valid and NOT this stop as that is what is changing
                const filteredStops = editedLoad.stops.filter(x => isValidDate(x.arrivalTime)).filter(x => x !== stop);
                const loadDate = getNewLoadDate(filteredStops, dt) || editedLoad.date;

                onEditedStopChanged({ arrivalTime: dt || null }, loadDate);
            }}
            validations={validations}
            smallText={true}
        />
    );
};
