import React from 'react';
import { DateTimePicker, renderTimeViewClock  } from '@mui/x-date-pickers';
import { is12HourFormat } from '../../../utils/date-time-utils';
import { L } from 'harmony-language';
import { useValidations, Validation } from './validated-text-field';
import moment from 'moment-timezone';

// @mui/x-date-pickers": "^7.2.0" - Think it actually broke in v6 though
// "Please fill out this field." native validation message is broken and no work around
// https://github.com/mui/mui-x/issues/10253

// Might be able to something with how "This stop must occur after the previous stop." message works.

export interface AgisticsDateTimePickerProps {
    value: string | null;
    onChange: (date: string | null) => void;
    required?: boolean;
    label?: string;
    disabled?: boolean;
    validations?: Validation[];
    referenceDate?: string;
    smallText?: boolean;
}

export const AgisticsDateTimePicker: React.FC<AgisticsDateTimePickerProps> = (props) => {
    const { value, onChange, required = false, label, disabled, validations, referenceDate, smallText = false } = props;
    const ref = useValidations(validations);

    const use12HourFormat = is12HourFormat();
    const localizedDateFormat = 'YYYY-MM-DD ' + (use12HourFormat ? 'hh:mm a' : 'HH:mm');

    return (
        <DateTimePicker
            label={label}
            value={value ? moment(value) : null}
            referenceDate={referenceDate ? moment(referenceDate) : undefined}
            onChange={(momentObj) => {
                onChange(momentObj ? momentObj.toISOString() : null)
            }}
            disabled={disabled}
            format={localizedDateFormat}
            ampm={use12HourFormat}
            viewRenderers={{
                hours: renderTimeViewClock,
                minutes: renderTimeViewClock,
                seconds: renderTimeViewClock,
            }}
            slotProps={
                {
                    actionBar: {
                        actions: ['clear', 'today', 'cancel', 'accept'],
                    },
                    textField: {
                        required: required,
                        // hot-reload does not correctly reload these inputRefs sometimes/always?
                        inputRef: ref,
                        size: 'small',
                        placeholder: ' ',
                        variant: 'standard',
                        inputProps: {
                            sx: { fontSize: smallText ? '0.875rem' : '1rem' },
                        },
                    },
                    openPickerIcon: { fontSize: smallText ? 'small' : 'normal' },
                }
            }
            sx={{
                marginTop: '0.225rem',
                // adding width for inline edit when at high resolutions
                width: '100%',
            }}
            localeText={{
                okButtonLabel: L.ok(),
                cancelButtonLabel: L.cancel(),
                todayButtonLabel: L.today(),
                clearButtonLabel: L.clear(),
            }}
        />
    );
}
