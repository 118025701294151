import React, { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react';
import { Load } from '../../../types';
import { deepEqual } from '../../../utils/deep-equal';
import { CargoTypeMode, cargoTypeModeProxyNullHandler } from '../table-components/stops/cargo-type-mode-proxy';

interface DraftLoadContext {
    editedLoad: Load;
    onEditedLoadChanged: (value: Partial<Load>) => void;
    isDirty: boolean;
    compartmentLinkingEnabled: boolean;
    setCompartmentLinkingEnabled: (b: boolean) => void;
    changeOrderNumber: number;
    setChangeOrderNumber: (n: number) => void;
    getUnitsState: (index: number) => CargoTypeMode;
    everyUnitsStateChange: (cargoTypeId: number) => void;
    oneSelectUnitsStateChange: (index: number, cargoTypeId: number) => void;
    addOneUnitState: (cargoTypeId: number) => void;
    deleteOneUnitState: (index: number) => void;
    unitSwitcherUnitStateChange: (index: number) => void;
}

const DraftLoadContext = createContext<DraftLoadContext | undefined>(undefined);

interface DraftLoadProviderProps {
    inputLoad: Load;
    handleGlobalIsDirtyChange?: (loadId: number, isDirty: boolean) => void;
    children: ReactNode;
}

// overtime LoadProvider got very bloated causing all sorts of performace problems
// which is 'fine' when only 1 LoadProvider is on the page, but when needing to load up 100+
// starts to cause problems
// DraftLoadProvider is an attempt to only create what is needed for inline editing
export const DraftLoadProvider: FC<DraftLoadProviderProps> = (props) => {
    const { inputLoad, handleGlobalIsDirtyChange, children } = props;
    const [editedLoad, setEditedLoad] = useState(inputLoad);
    const [isDirty, setIsDirty] = React.useState(false);
    const [compartmentLinkingEnabled, setCompartmentLinkingEnabled] = useState(true);
    const [changeOrderNumber, setChangeOrderNumber] = useState(0);

    // see README before adding this back...
    // useEffect(() => {
    //     setEditedLoad(inputLoad);
    // }, [inputLoad]);

    useEffect(() => {
        const isDirty = !deepEqual(inputLoad, editedLoad);

        setIsDirty(isDirty);
        handleGlobalIsDirtyChange && handleGlobalIsDirtyChange(editedLoad.id, isDirty);
    }, [inputLoad, editedLoad]);

    // unitsState got complicated because of everyUnitsStateChange
    // if not for changing every cargotype when a load has none, this could be simplified a lot
    const [unitsState, setUnitsState] = useState(() => {
        return editedLoad.stops.map(stop => cargoTypeModeProxyNullHandler(stop.cargoTypeId));
    });

    const getUnitsState = (index: number) => {
        return unitsState[index];
    }

    const everyUnitsStateChange = (cargoTypeId: number) => {
        const cargoTypeMode = cargoTypeModeProxyNullHandler(cargoTypeId);

        const newUnitsState = unitsState.map(_ => cargoTypeMode);

        setUnitsState(newUnitsState);
    }

    const oneSelectUnitsStateChange = (index: number, cargoTypeId: number) => {
        const cargoTypeMode = cargoTypeModeProxyNullHandler(cargoTypeId);
        const newUnitsState = unitsState.map((x, i) => {
            if (i === index) {
                return cargoTypeMode;
            }
            return x;
        });

        setUnitsState(newUnitsState);
    }

    const addOneUnitState = (cargoTypeId: number) => {
        const cargoTypeMode = cargoTypeModeProxyNullHandler(cargoTypeId);
        const newUnitsState = unitsState.concat(cargoTypeMode);

        setUnitsState(newUnitsState);
    }

    const deleteOneUnitState = (index: number) => {
        const newUnitsState = unitsState.filter((_, i) => i !== index);

        setUnitsState(newUnitsState);
    }

    const unitSwitcherUnitStateChange = (index: number) => {
        const newUnitsState = unitsState.map((x, i) => {
            if (i === index) {
                return unitsState[index] === CargoTypeMode.Quantity ? CargoTypeMode.Weight : CargoTypeMode.Quantity;
            }
            return x;
        });

        setUnitsState(newUnitsState);
    }

    const onEditedLoadChanged = (value: Partial<Load>) => {
        const newLoad = {
            ...editedLoad,
            ...value,
        }

        setEditedLoad(newLoad);
    }

    return (
        <DraftLoadContext.Provider
            value={{
                editedLoad: editedLoad,
                onEditedLoadChanged: onEditedLoadChanged,
                isDirty: isDirty,
                compartmentLinkingEnabled: compartmentLinkingEnabled,
                setCompartmentLinkingEnabled: setCompartmentLinkingEnabled,
                changeOrderNumber: changeOrderNumber,
                setChangeOrderNumber: setChangeOrderNumber,
                getUnitsState: getUnitsState,
                everyUnitsStateChange: everyUnitsStateChange,
                oneSelectUnitsStateChange: oneSelectUnitsStateChange,
                addOneUnitState: addOneUnitState,
                deleteOneUnitState: deleteOneUnitState,
                unitSwitcherUnitStateChange: unitSwitcherUnitStateChange,
            }}
        >
            {children}
        </DraftLoadContext.Provider>
    );
}

export const useDraftLoadContext = () => {
    const draftLoadContext = useContext(DraftLoadContext);

    if (draftLoadContext === undefined) {
        throw new Error('useDraftLoadContext must be used within a DraftLoadProvider');
    }

    return draftLoadContext;
}
