import React, { FC, FormEvent, useRef } from 'react';
import { useOrganizationQuery } from '../../../api/queries/use-organization-query';
import { Driver } from '../../../types';
import { OrgQueryKeys } from '../../../api/config';
import { useLoadEditableItems } from '../hooks/use-load-editable-items';
import { EditableLoadDriver } from '../drafts-loads-table-cells/loads/editable/editable-load-driver';
import { EditableLoadTractor } from '../drafts-loads-table-cells/loads/editable/editable-load-tractor';
import { EditableLoadTrailer } from '../drafts-loads-table-cells/loads/editable/editable-load-trailer';
import { HereMap, HereMapRefObject } from '../../here-maps/here-map';
import { LoadTableStops } from '../table-components/stops/load-table-stops';
import { useAddLoadMutation } from '../../../api/mutations/reworked/add/use-add-load-mutation';
import { useDraftLoadContext } from '../hooks/draft-load-context';
import { useEditLoadMutation } from '../../../api/mutations/reworked/edit/use-edit-load-mutation';
import { LoadStopTime } from '../drafts-loads-table-cells/loads/display/load-stop-time';
import { EstimatedTotalTripTime } from '../drafts-loads-table-cells/loads/display/estimated-total-trip-time';
import { L } from 'harmony-language';
import { Button } from '@mui/material';
import Save from '@mui/icons-material/Save';
import { ResourceSchedulingButton } from '../drafts/scheduling/resource-scheduling-button';
import { HereMapOrderContent } from '../loads/edit-create-order/here-map-order-content';

interface EditCreateLoadFormProps {
    isDrafts: boolean;
    onSubmit: () => void;
}

export const EditCreateLoadForm: FC<EditCreateLoadFormProps> = (props) => {
    const { isDrafts, onSubmit } = props;
    const { data: drivers = [] } = useOrganizationQuery<Driver[]>(OrgQueryKeys.drivers);
    const loadEditableItems = useLoadEditableItems();
    const mapRef = useRef<HereMapRefObject>(null);
    const { editedLoad } = useDraftLoadContext();
    const { mutate: addLoad } = useAddLoadMutation();
    const { mutate: editLoad } = useEditLoadMutation(isDrafts);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        // ignore typescript here until we get a typescript concept for Create/Edit Load
        if ('id' in editedLoad) {
            editLoad({ load: editedLoad });
        } else {
            addLoad({ load: editedLoad });
        }

        onSubmit();
    };

    return (
        <form
            onSubmit={handleSubmit}
            style={{ fontSize: '.875rem' }}
        >
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 2fr',
                    gridColumnGap: '1rem',
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
                    {editedLoad.id && (
                        <div style={{ display: 'flex', columnGap: '.3rem' }}>
                            <div>#{editedLoad.id}</div>
                        </div>
                    )}
                    <div style={{ display: 'flex', columnGap: '.3rem' }}>
                        <span>{L.date()}:</span>
                        <div><LoadStopTime time={editedLoad.date} /></div>
                    </div>
                    <div style={{ display: 'flex', columnGap: '.3rem' }}>
                        <span>{L.estimatedTotalTripTime()}:</span>
                        <EstimatedTotalTripTime load={editedLoad} />
                    </div>
                    <div>
                        <ResourceSchedulingButton
                            loadEditableItems={loadEditableItems}
                        />
                    </div>
                    <EditableLoadDriver
                        drivers={drivers}
                        getDriverProperties={loadEditableItems.getDriverProperties}
                    />
                    <EditableLoadTractor
                        getTractorProperties={loadEditableItems.getTractorProperties}
                    />
                    <EditableLoadTrailer
                        getTrailerProperties={loadEditableItems.getTrailerProperties}
                    />
                </div>
                <div style={{ border: '1px solid #ccc', height: '100%', minHeight: '40vh' }}>
                    <HereMap ref={mapRef}>
                        <HereMapOrderContent mapRef={mapRef} />
                    </HereMap>
                </div>
            </div>
            <LoadTableStops isDrafts={isDrafts} />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    startIcon={<Save />}
                    type='submit'
                    color='primary'
                    variant='contained'
                >
                    {L.save()}
                </Button>
            </div>
        </form>
    );
};
