import { ColumnSort } from '@tanstack/react-table';
import React from 'react';
import { localNow } from '../../../utils/date-time-utils';
import { ReportBase } from '../report-base';
import { ReportFilters } from '../report-filters';
import { type ReportProps, type SequencingReportColumns } from '../report-types';
import { getSequencingUrlAndParams } from '../report-utils';
import { useReports } from '../use-reports';
import { useSequencingColumns } from './sequencing-columns';
import { SequencingFilters } from './sequencing-filters';

const defaultSort: ColumnSort = {
    id: 'trailer',
    desc: false,
}

export const SequencingReport: React.FC<ReportProps> = (props) => {
    const { isAdmin, companyId } = props;
    const [organizationId, setOrganizationId] = React.useState<number>(isAdmin ? 0 : companyId);
    const [startDate, setStartDate] = React.useState<string>(localNow().subtract(1, 'day').startOf('day').toISOString());
    const [endDate, setEndDate] = React.useState<string>(localNow().subtract(1, 'day').endOf('day').toISOString());
    const [trailerIds, setTrailerIds] = React.useState<number[]>([]);
    const [originSiteIds, setOriginSiteIds] = React.useState<number[]>([]);
    const [destinationSiteIds, setDestinationSiteIds] = React.useState<number[]>([]);
    const columns = useSequencingColumns(isAdmin);
    const { reportUrl, reportParams } = getSequencingUrlAndParams(isAdmin, organizationId, startDate, endDate, trailerIds, originSiteIds, destinationSiteIds);
    const { data, runReport, table, isLoading } = useReports<SequencingReportColumns>(defaultSort, columns, reportUrl, reportParams, false);

    return (
        <>
            <ReportFilters
                isAdmin={isAdmin}
                organizationId={organizationId}
                setOrganizationId={setOrganizationId}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                runReport={runReport}>
                        <SequencingFilters
                        isAdmin={isAdmin}
                            trailerIds={trailerIds}
                            setTrailerIds={setTrailerIds}
                            originSiteIds={originSiteIds}
                            setOriginSiteIds={setOriginSiteIds}
                            destinationSiteIds={destinationSiteIds}
                            setDestinationSiteIds={setDestinationSiteIds}
                        />
            </ReportFilters>
            <ReportBase
                table={table}
                hasData={Boolean(data)}
                isLoading={isLoading}
                runReport={runReport}
            />
        </>
    );
};
