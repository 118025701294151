import { useCallback } from 'react';
import { Driver, Load, OrganizationCarrier } from '../../../types';
import { AgisticsItem } from '../../shared/agistics-select';
import { useCarrier } from '../../user/selectors/use-permissions';
import { carrierResourceSettings } from '../../shared/load-table/utils/load-utils';
import { useUser } from '../../../api/queries/use-user';
import { useOrganizationQuery } from '../../../api/queries/use-organization-query';
import { OrgQueryKeys } from '../../../api/config';
import { useTractorTrailer } from '../../../api/queries/use-tractor-trailer';

export interface LoadEditableProperties {
    items: AgisticsItem[];
    isDisabled: boolean;
}

export interface LoadEditableItems {
    getDriverProperties: (l: Load) => LoadEditableProperties;
    getTractorProperties: (l: Load) => LoadEditableProperties;
    getTrailerProperties: (l: Load) => LoadEditableProperties;
}

// thoughts: previously we computed these in each component instance
// going to try and just compute once for the page and pass the list down
// UPDATE: carriers pretty much made the above concept impossible, but the seperation
// of editable/not editable does make this logic run WAY less then before
export const useLoadEditableItems = (): LoadEditableItems => {
    const { user } = useUser();
    const currentUserIsCarrier = useCarrier();
    const { data: drivers = [] } = useOrganizationQuery<Driver[]>(OrgQueryKeys.drivers);
    const { tractors = [], trailers = [] } = useTractorTrailer();
    const { data: carriers = [] } = useOrganizationQuery<OrganizationCarrier[]>(OrgQueryKeys.carriers);

    const getDriverProperties: (l: Load) => LoadEditableProperties = useCallback((load: Load) => {
        const applicableDrivers = drivers.filter(driver => driver.enabled || driver.id === load.transportedByUserId);
        const loadCarrier = carriers.find(x => x.carrierOrganizationId === load.transportingOrganizationId && x.organizationId === load.organizationId);

        const resourceSettings = loadCarrier
            ? carrierResourceSettings(applicableDrivers, currentUserIsCarrier, user.organizationId, loadCarrier, 'loadDriver')
            : { resources: applicableDrivers.filter(x => x.organizationId === user.organizationId), isDisabled: false };

        return {
            items: resourceSettings.resources.map(driver => ({ id: driver.id, value: driver.name })),
            isDisabled: resourceSettings.isDisabled,
        }
    }, [drivers, carriers]);

    const getTractorProperties: (l: Load) => LoadEditableProperties = useCallback((load: Load) => {
        const applicableTractors = tractors.filter(tractor => tractor.enabled || tractor.id === load.tractorId);
        const loadCarrier = carriers.find(x => x.carrierOrganizationId === load.transportingOrganizationId && x.organizationId === load.organizationId);

        const resourceSettings = loadCarrier
            ? carrierResourceSettings(applicableTractors, currentUserIsCarrier, user.organizationId, loadCarrier, 'loadTractor')
            : { resources: applicableTractors.filter(x => x.organizationId === user.organizationId), isDisabled: false };

        return {
            items: resourceSettings.resources.map(tractor => ({ id: tractor.id, value: tractor.userDisplayName })),
            isDisabled: resourceSettings.isDisabled,
        }
    }, [tractors, carriers]);

    const getTrailerProperties: (l: Load) => LoadEditableProperties = useCallback((load: Load) => {
        const applicableTrailers = trailers.filter(trailer => trailer.enabled || trailer.id === load.trailerId);
        const loadCarrier = carriers.find(x => x.carrierOrganizationId === load.transportingOrganizationId && x.organizationId === load.organizationId);

        const resourceSettings = loadCarrier
            ? carrierResourceSettings(applicableTrailers, currentUserIsCarrier, user.organizationId, loadCarrier, 'loadTrailer')
            : { resources: applicableTrailers.filter(x => x.organizationId === user.organizationId), isDisabled: false };

        return {
            items: resourceSettings.resources.map(trailer => ({ id: trailer.id, value: trailer.userDisplayName })),
            isDisabled: resourceSettings.isDisabled,
        };
    }, [trailers, carriers]);

    return {
        getDriverProperties: getDriverProperties,
        getTractorProperties: getTractorProperties,
        getTrailerProperties: getTrailerProperties,
    };
}
