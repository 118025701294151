import React, { FC } from 'react';
import { Stop } from '../../../../../types';
import { DynamicQueryKeys } from '../../../../../api/config';
import { StopNotesModal } from '../../../../shared/load-table/stop-notes-modal';
import { ImagesModal } from '../../../../shared/images-modal';
import { L } from 'harmony-language';
import { useDraftLoadStopContext } from '../../../hooks/draft-load-stop-context';

interface EditableStopNotesProps {
    stop: Stop;
}

export const EditableStopNotes: FC<EditableStopNotesProps> = (props) => {
    const { stop } = props;
    const { onEditedStopChanged, isCarrier, isComplete } = useDraftLoadStopContext();

    const key = DynamicQueryKeys.stopImages(stop.id);

    return (
        <div>
            <StopNotesModal
                stop={stop}
                onChange={onEditedStopChanged}
                isDisabled={isCarrier || isComplete}
            />
            {stop.imageIds?.length &&
                <ImagesModal imageLabel={L.stopPhoto()} modalLabel={L.stopPhotos()} queryKey={key} />
            }
        </div>
    )
};
