import { Autocomplete, AutocompleteRenderGetTagProps, TextField } from '@mui/material';
import React from 'react';
import { CustomPopper } from './multi-select/custom-popper';
import { L } from 'harmony-language';
import { ValidatedTextField, Validation } from './inputs/validated-text-field';

export interface AgisticsItem {
    id: number;
    value: string;
}

interface AgisticsSelectProps {
    item: number | null;
    items: AgisticsItem[];
    onChange: (n: number) => void;
    label?: string;
    confinedSpace?: boolean;
    customPopper?: boolean;
    disabled?: boolean;
    showNone?: boolean;
    validations?: Validation[];
}

const noneItem = () => {
    return {
        id: -1,
        value: L.none(),
    }
}

export const AgisticsSelect: React.FC<AgisticsSelectProps> = (props) => {
    const { item, items, onChange, label, confinedSpace = false, customPopper = false, disabled = false, showNone = false, validations } = props;
    const selectedValue = React.useMemo(() => {
        const val = items.find(x => x.id === item);
        return val ? val : noneItem();
    }, [item]);

    const newOptions: () => AgisticsItem[] = () => {
        if (showNone) {
            return [
                noneItem(),
                ...items,
            ];
        } else {
            return items;
        }
    }

    return (
        <Autocomplete
            disableClearable={true}
            disabled={disabled}
            options={newOptions()}
            getOptionLabel={(option) => option.value}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={selectedValue}
            onChange={(event, value) => {
                if (event.type !== 'keydown' && value) {
                    onChange(value.id);
                }
            }}
            PopperComponent={customPopper ? CustomPopper : undefined}
            sx={confinedSpace ? { input: { fontSize: '.875rem' }} : undefined}
            renderInput={(params) => {
                return (
                    <ValidatedTextField
                        {...params}
                        label={label}
                        validations={validations}
                    />
                );
            }}
            renderOption={(props, option) => {
                return (
                    <li
                        {...props}
                        // props passes a key that is value, which might not be unique (users/contacts with same name)
                        key={option.id}
                    >
                        {option.value}
                    </li>
                );
            }}
        />
    );
};
