import React from 'react';
import PropTypes from 'prop-types';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ContentCopy from '@mui/icons-material/ContentCopy';
import TextField from '@mui/material/TextField';
import { L } from 'harmony-language';

export const BulkEntryTableBody = (props) => {
    const { loadQtyChange, loads, onCopyLastWeeksProjections, siteProjections, weekOf } = props;

    const getSiteQuantity = (siteIndex) => {
        return loads?.[siteIndex]?.sum(day => parseInt(day) || 0) || 0;
    };

    const siteClass = (siteIndex) => {
        return getSiteQuantity(siteIndex) > 0 ? 'no-left-padding' : 'no-left-padding gray-text';
    };

    const checkForLastWeekProjections = (siteIndex) => {
        return siteProjections[siteIndex].lastWeeksProjections?.sum();
    };

    // const workingWeek = getDaysOfWorkingWeek(weekOf);

    return (
        <TableBody className='bulk-entry-table-body'>
            {siteProjections.map((site, siteIndex) => (
                <TableRow className='bulk-entry-table-row' key={siteIndex}>
                    <TableCell className='no-right-padding'>
                        <IconButton
                            tabIndex={-1}
                            className='bulk-entry-copy-button'
                            aria-label={L.copy()}
                            disabled={!checkForLastWeekProjections(siteIndex)}
                            onClick={() => onCopyLastWeeksProjections(siteIndex)}
                            size='large'>
                                <ContentCopy />
                            <span className='tooltip'>{L.bulkEntryCopy()}</span>
                        </IconButton>
                    </TableCell>
                    <TableCell className={siteClass(siteIndex)}>{site.name}</TableCell>
                    {[...Array(7)].map((val, dayIndex) => {
                        return (
                            // This was only needed for resource conflict stuff that was removed 2 years ago
                            // <LoadProvider key={(siteIndex + 1) * dayIndex} load={{ date: workingWeek[dayIndex].value }}>
                                <TableCell className='bulk-entry-load-amount'>
                                    {/*I accidentally broke this when I reworked some resource confilct stuff and was not aware this existed.
                                    Comment out for now to at least make app not crash.
                                    <ResourceDowntimeConflictBadge resourceType={'location'} resourceId={`${site.id}`}
                                        style={{ fontSize: '1.5rem' }}>*/}
                                        <TextField
                                            variant='standard'
                                            onChange={(event) => loadQtyChange(event, siteIndex, dayIndex)}
                                            value={loads?.[siteIndex][dayIndex] || ''}
                                            autoComplete='off'
                                            autoFocus={siteIndex === 0 && dayIndex === 0}
                                            inputProps={{ maxLength: 3 }} />
                                    {/*</ResourceDowntimeConflictBadge>*/}
                                </TableCell>
                            // </LoadProvider>
                        );
                    })}
                    <TableCell sx={{ textAlign: 'right' }}>{getSiteQuantity(siteIndex)}</TableCell>
                    <TableCell sx={{ textAlign: 'right' }}>
                        <div>{site.currentProjections + getSiteQuantity(siteIndex)}</div>
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    );
};

BulkEntryTableBody.propTypes = {
    weekOf: PropTypes.string,
    loadQtyChange: PropTypes.func,
    loads: PropTypes.array,
    onCopyLastWeeksProjections: PropTypes.func,
    siteProjections: PropTypes.array,
};
