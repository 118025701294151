import React from 'react';
import { AgisticsMultiItem, AgisticsMultiSelect } from './agistics-multi-select';
import { useUpdateUserPreferences } from '../hooks/use-update-user-preferences';
import { Preferences } from '../../../types';

interface AgisticsMultiSelectWithPrefsProps<T> {
    selectedIds: T[];
    setSelectedIds: (s: T[]) => void;
    items: AgisticsMultiItem<T>[];
    label: string;
    prefKey: keyof Preferences;
    // some preferences remove items, others store the actual items selected
    removeMode: boolean;
}

export const AgisticsMultiSelectWithPrefs = <T extends string | number>(props: AgisticsMultiSelectWithPrefsProps<T>) => {
    const { selectedIds, setSelectedIds, items, label, prefKey, removeMode } = props;
    const { preferences, updatePreferences } = useUpdateUserPreferences();

    React.useEffect(() => {
        const prefs = preferences?.[prefKey] || [];
        const constructedItems = items.filter(item => removeMode ? !prefs.includes(item.id) : prefs.includes(item.id));
        setSelectedIds(constructedItems.map(x => x.id));
    }, []);

    const presistPrefsChange = (value: T[]) => {
        const newPreferences = {
            ...preferences,
            [prefKey]: removeMode ? items.filter(x => !value.includes(x.id)).map(x => x.id) : value,
        }
        updatePreferences(newPreferences);
    }

    return (
        <AgisticsMultiSelect
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            items={items}
            label={label}
            presistPrefsChange={presistPrefsChange}
        />
    );
};
