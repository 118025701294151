import React, { FC } from 'react';
import { isCargoStop } from '../../../../../constants/constants';
import { Stop } from '../../../../../types';
import { TextField } from '@mui/material';
import { editableStopInputProps } from '../../../table-components/stops/editable-stop-table-row';
import { useDraftLoadStopContext } from '../../../hooks/draft-load-stop-context';
import { useDraftLoadContext } from '../../../hooks/draft-load-context';

interface EditableStopOrderNumberProps {
    stop: Stop;
}

export const EditableStopOrderNumber: FC<EditableStopOrderNumberProps> = (props) => {
    const { stop } = props;
    const { changeOrderNumber } = useDraftLoadContext();
    const { onEditedStopChanged, orderNumberEditable, isCarrier, isComplete } = useDraftLoadStopContext();

    if (!isCargoStop(stop)) {
        return null;
    }

    return (
        <TextField
            variant='standard'
            value={stop.orderNumber || ''}
            inputProps={editableStopInputProps}
            disabled={isComplete || isCarrier || (changeOrderNumber >= 7 ? false : orderNumberEditable ? false : true)}
            fullWidth
            onChange={(e) => {
                const newVal = e.target.value ? e.target.value : null;
                onEditedStopChanged({ orderNumber: newVal });
            }}
        />
    );
};
