import React from 'react';
import { orderToCargoQuantities } from '../../../utils/data-mapping-utils';
import { Load } from '../../../types';

// children of HereMapMarker (LiveMapRouteTooltip) which calls renderToStaticMarkup on the children components
// needs access to useSelector in useWeights() to get the users weight preference.  calling renderToStaticMarkup
// on redux things causes a crash
export const LoadCargo2: React.FC<{load: Load, convertFromGramsDisplay: (g: number) => string}> = (props) => {
    const { load, convertFromGramsDisplay } = props;
    const cargoQuantities = orderToCargoQuantities(load, convertFromGramsDisplay);

    return (
        <LoadCargoInternal
            cargoQuantities={cargoQuantities}
            load={load} />
    );
};

const LoadCargoInternal: React.FC<{cargoQuantities: any, load: Load}> = (props) => {
    const { cargoQuantities, load } = props;

    return (
        <div>
            {Object.entries(cargoQuantities).map(([cargo, quantity]) => {
                return (
                    <div key={cargo}>
                        {quantity ? `${quantity} - ${cargo}` : `${cargo}`}
                    </div>
                );
            })}
            {/* <SalmonellaMeter severity={load.metadata?.cargo?.salmonellaResult} /> */}
        </div>
    );
};
