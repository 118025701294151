import { DynamicQueryKeys } from '../../../../../api/config';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { deliveryLogQueryConfig } from './delivery-log-config'
import { type DeviceLocation } from '../../../../../types';

export const useDeviceLocations = (type: string, loadId: number, startDate?: string, endDate?: string): UseQueryResult<DeviceLocation[], unknown> => {
    const key = DynamicQueryKeys.resourceDeviceLocations(type, loadId, startDate ? startDate : null, endDate ? endDate : null);

    return useQuery<DeviceLocation[]>([key], async (): Promise<DeviceLocation[]> => {
        return await deliveryLogQueryConfig.queries.queryFnNoSort(key);
    },
    {
        staleTime: deliveryLogQueryConfig.queries.staleTime,
        cacheTime: deliveryLogQueryConfig.queries.cacheTime,
        refetchInterval: deliveryLogQueryConfig.queries.refetchInterval,
    });
};
