import { Grid, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { L } from 'harmony-language';
import React from 'react';
import { OrgQueryKeys } from '../../../api/config';
import { useCargoTypes } from '../../../api/queries/use-cargo-types';
import { useOrganizationQuery } from '../../../api/queries/use-organization-query';
import { STOP_TYPES } from '../../../constants/constants';
import { type Customer } from '../../../types';
import { AgisticsMultiSelect } from '../../shared/multi-select/agistics-multi-select';
import { CargoFilterMode } from './on-time-report';

interface OnTimeFiltersProps {
    isAdmin: boolean;
    stopTypes: { state: string[], setState: (s: string[]) => void };
    customerIds: { state: number[], setState: (n: number[]) => void };
    cargoFilterMode: { state: CargoFilterMode, setState: (m: CargoFilterMode) => void };
    cargoTypeIds: { state: number[], setState: (n: number[]) => void };
    cargoCategories: { state: string[], setState: (s: string[]) => void };
}

export const OnTimeFilters: React.FC<OnTimeFiltersProps> = (props) => {
    const { isAdmin, stopTypes, cargoTypeIds, cargoFilterMode, cargoCategories, customerIds } = props;
    const { cargoTypeList } = useCargoTypes();
    const { data: customers = [] } = useOrganizationQuery<Customer[]>(OrgQueryKeys.customers, { enabled: !isAdmin });
    
    const stopTypesMultiItems = Object.values(STOP_TYPES()).filter(t => t.key !== 'Wash').map(type => ({
        id: type.key,
        value: type.label,
    }));
    const customersMultiItems = customers.map((customer) => ({
        id: customer.id,
        value: customer.customerName
    }));
    const cargoTypesMultiItems = cargoTypeList.map((cargoType) => ({
        id: cargoType.id,
        value: cargoType.label
    }));
    const cargoCategoriesMultiItems = [...new Set(cargoTypeList.map(cargoType => cargoType.category))].map(category => ({
        id: category,
        value: category
    }));

    function handleCargoFilterModeChange(_: React.MouseEvent<HTMLElement>, value: CargoFilterMode) {
        if (value === 'cargoCategory') {
            cargoTypeIds.setState([]);
        }
        else {
            cargoCategories.setState([]);
        }
        cargoFilterMode.setState(value);
    }

    return (
        <Grid container sx={{ width: '24em' }}>
            <Grid item xs={12}>
                <AgisticsMultiSelect
                    selectedIds={stopTypes.state}
                    setSelectedIds={stopTypes.setState}
                    items={stopTypesMultiItems}
                    label={L.stopTypes()}
                />
            </Grid>
            {!isAdmin && <Grid item xs={12}>
                <AgisticsMultiSelect
                    selectedIds={customerIds.state}
                    setSelectedIds={customerIds.setState}
                    items={customersMultiItems}
                    label={L.customers()}
                />
            </Grid>}
            <Grid item xs={12} sx={{ marginY: '1em' }}>
                <Typography variant='subtitle2'>{L.cargoMode()}</Typography>
                <ToggleButtonGroup
                    color='primary'
                    value={cargoFilterMode.state}
                    exclusive
                    onChange={handleCargoFilterModeChange}
                    >
                    <ToggleButton value='cargoType'>{L.stopCargoTypes()}</ToggleButton>
                    <ToggleButton value='cargoCategory'>{L.stopCargoCategories()}</ToggleButton>
                </ToggleButtonGroup>

                {cargoFilterMode.state == 'cargoType' ? (
                    <AgisticsMultiSelect
                        selectedIds={cargoTypeIds.state}
                        setSelectedIds={cargoTypeIds.setState}
                        items={cargoTypesMultiItems}
                        label={L.stopCargoTypes()}
                    />
                ) : (
                    <AgisticsMultiSelect
                        selectedIds={cargoCategories.state}
                        setSelectedIds={cargoCategories.setState}
                        items={cargoCategoriesMultiItems}
                        label={L.stopCargoCategories()}
                    />
                )}
            </Grid>
        </Grid>
    );
};
