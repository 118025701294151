import React from 'react';
import PropTypes from 'prop-types';
import { L } from 'harmony-language';
import { TextFieldWithValidation } from '../../../../shared/inputs/text-field-with-validation';

export const LoadSize = (props) => {
    const { loadSize, setLoadSize, sizeWeightValidation } = props;

    const loadSizeChange = (event) => {
        const val = event.target.value;
        setLoadSize(val ? val : null);
    };

    return (
        <TextFieldWithValidation
            className='bulk-config-load-size'
            label={L.bulkSelectQuantity()}
            onChange={loadSizeChange}
            type='number'
            value={loadSize}
            required={false}
            validationFunc={sizeWeightValidation}
            requiredMsg={L.loadInvalidStopValue()}
        />
    );
};

LoadSize.propTypes = {
    loadSize: PropTypes.string,
    setLoadSize: PropTypes.func,
    sizeWeightValidation: PropTypes.func,
};
