import React, { FC } from 'react';
import { Stop } from '../../../../../types';
import { STOP_STATUSES, STOP_TYPES } from '../../../../../constants/constants';
import { AgisticsTooltip } from '../../../../shared/agistics-tooltip';

interface DisplayStopTypeProps {
    stop: Stop;
}

export const DisplayStopType: FC<DisplayStopTypeProps> = (props) => {
    const { stop } = props;

    return (
        <AgisticsTooltip
            tooltip={STOP_STATUSES()[stop.status].label}
        >
            {/* @ts-ignore - TODO */}
            <span style={{ color: STOP_STATUSES()[stop.status].color, fontWeight: 'bold' }}>{STOP_TYPES()[stop.type].label}</span>
        </AgisticsTooltip>
    );
};
