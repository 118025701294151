import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { L } from 'harmony-language';
import { useSnackbar } from '../../../components/notifications/use-snackbar';
import { dateRangeOverlaps } from '../../../utils/date-time-utils';
import { Load } from '../../../types';
import { useDraftsKey } from '../../config-hooks';

interface MergeDraftsOptions {
    draft: Load;
    previousDrafts: Load[];
}

const mergeDrafts = async (options: MergeDraftsOptions) => {
    const draft = options.draft;
    const previousDrafts = options.previousDrafts;
    const draftIds = previousDrafts.map(x => x.id);

    return (await axios.post(`${API_BASE}/api/orders/merge?orderIds=${JSON.stringify(draftIds)}`, draft)).data;
}

const stringMessage = (previouseDrafts: Load[]) => {
    let message = '';
    previouseDrafts.map(d => {
        message += '#' + d.id + ' ';
    });
    return message.trim();
}

export const useMergeDraftsMutation = () => {
    const snackbar = useSnackbar();
    const queryKey = useDraftsKey();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: mergeDrafts,
        onMutate: () => {
            const previousCache = queryClient.getQueryData<Load[]>(queryKey);

            return previousCache;
        },
        onError: (_1, _2, context) => {
            if (context) {
                queryClient.setQueryData<Load[]>(queryKey, context);
            }
        },
        onSuccess: (data, options: { previousDrafts: Load[] }) => {
            const previousDrafts = options.previousDrafts;
            const previousCache = queryClient.getQueryData<Load[]>(queryKey);

            if (previousCache) {
                const filteredDrafts = previousCache.filter(draft => !previousDrafts.some(d => d.id === draft.id)) || [];
                const fromDate = queryKey[3];
                const toDate = queryKey[4];

                const mergedDraftWithinCacheDateRange = dateRangeOverlaps(data.date, fromDate, toDate);

                const newDrafts = [
                    ...filteredDrafts,
                    ...(mergedDraftWithinCacheDateRange ? [data] : []),
                ];

                queryClient.setQueryData(queryKey, newDrafts);
            }

            snackbar(L.mergeDraftsSuccessMessage(stringMessage(previousDrafts), `#${data.id}`));
        },
        // onSettled: () => {
        //     queryClient.invalidateQueries(queryKey);
        // },
    });

    return mutation;
};
