import React from 'react';
import { ModalDialog } from '../shared/containers/modal-dialog';
import { L } from 'harmony-language';
import { Load } from '../../types';
import { Table } from '@tanstack/react-table';
import { DrawerDialog } from '../shared/containers/drawer-dialog';
import { DriverAssignment } from '../drivers/driver-assignment';
import { CarrierAssignment } from '../carriers/carrier-assignment';
import { EditCreateLoad } from './edit-create-load/edit-create-load';
import { BulkDraftModal } from './drafts/bulk-draft-modal';
import { DeliveryLog } from './loads/delivery-log/delivery-log';
import { ManuallyCompleteModal } from './loads/manually-complete/manually-complete-modal';
import { DeleteLoadsModal } from './drafts/delete-loads-modal';
import { BulkModify } from './drafts/bulk-modify/bulk-modify';
import { MergeDrafts } from './drafts/merge/merge-drafts';
import { CascadeStopTimes } from './drafts/cascade/cascade-stop-times';
import { PublishDraftsModal } from './drafts/publish-drafts-modal';
import { UnmergeDrafts } from './drafts/unmerge/unmerge-drafts';

interface DraftLoadModalsProps {
    isDrafts: boolean;
    table: Table<Load>;

    dialogOpen: boolean;
    setDialogOpen: (b: boolean) => void;

    bulkDraftsOpen: boolean;
    setBulkDraftsOpen: (b: boolean) => void;

    editableLoad: Load | null;
    setEditableLoad: (l: Load | null) => void;

    delieryLogLoad: Load | undefined;
    setDeliveryLogLoad: (l: Load | undefined) => void;

    unmergeLoad: Load | null;
    setUnmergeLoad: (l: Load | null) => void;

    completableLoads: Load[];
    setCompletableLoads: (l: Load[]) => void;

    assignableLoads: Load[];
    setAssignableLoads: (l: Load[]) => void;

    carrierLoads: Load[];
    setCarrierLoads: (l: Load[]) => void;

    deletableLoads: Load[];
    setDeletableLoads: (l: Load[]) => void;

    bulkModifyRecords: Load[];
    setBulkModifyRecords: (l: Load[]) => void;

    mergeRecords: Load[];
    setMergeRecords: (l: Load[]) => void;

    cascadeRecords: Load[];
    setCascadeRecords: (l: Load[]) => void;

    publishRecords: Load[];
    setPublishRecords: (l: Load[]) => void;
}

export const DraftLoadModals: React.FC<DraftLoadModalsProps> = (props) => {
    const {
        isDrafts, table,
        dialogOpen, setDialogOpen,
        bulkDraftsOpen, setBulkDraftsOpen,
        editableLoad, setEditableLoad,
        delieryLogLoad, setDeliveryLogLoad,
        unmergeLoad, setUnmergeLoad,
        completableLoads, setCompletableLoads,
        assignableLoads, setAssignableLoads,
        carrierLoads, setCarrierLoads,
        deletableLoads, setDeletableLoads,
        bulkModifyRecords, setBulkModifyRecords,
        mergeRecords, setMergeRecords,
        cascadeRecords, setCascadeRecords,
        publishRecords, setPublishRecords,
    } = props;

    return (
        <>
            <BulkDraftModal isOpen={bulkDraftsOpen} onClose={() => setBulkDraftsOpen(false)} />
            <ModalDialog
                title={L.loadDetails()}
                onClose={() => {
                    setDialogOpen(false);
                    setEditableLoad(null);
                }}
                open={dialogOpen || Boolean(editableLoad)}
            >
                <EditCreateLoad
                    isDrafts={isDrafts}
                    existingLoad={editableLoad}
                    onSubmit={() => {
                        setDialogOpen(false);
                        setEditableLoad(null);
                    }}
                />
            </ModalDialog>
            <ModalDialog
                title={L.deliveryLog()}
                onClose={() => setDeliveryLogLoad(undefined)}
                open={Boolean(delieryLogLoad)}
            >
                <DeliveryLog isResourceLog={false} load={delieryLogLoad} />
            </ModalDialog>
            <ModalDialog
                title={L.manuallyCompleteConfirmationTitle(`${completableLoads.length > 1 ? L.loads() : L.load()}`)}
                open={completableLoads.length > 0}
                onClose={() => setCompletableLoads([])}
            >
                <ManuallyCompleteModal
                    completableLoads={completableLoads}
                    onSubmit={() => {
                        table.resetRowSelection(true);
                        setCompletableLoads([]);
                    }}
                />
            </ModalDialog>
            <DrawerDialog
                title={L.assignDriver()}
                open={assignableLoads.length > 0}
                onClose={() => setAssignableLoads([])}
                anchor='right'
            >
                <DriverAssignment
                    loads={assignableLoads}
                    onAssign={() => {
                        table.resetRowSelection(true);
                        setAssignableLoads([]);
                    }}
                    isDrafts={isDrafts}
                />
            </DrawerDialog>
            <DrawerDialog
                title={L.assignCarrier()}
                open={carrierLoads.length > 0}
                onClose={() => setCarrierLoads([])}
                anchor='right'
            >
                <CarrierAssignment
                    loads={carrierLoads}
                    onAssign={() => {
                        table.resetRowSelection(true);
                        setCarrierLoads([]);
                    }}
                />
            </DrawerDialog>
            <DeleteLoadsModal
                records={deletableLoads}
                unselectFn={() => {
                    table.resetRowSelection(true);
                }}
                onResultCallback={() => setDeletableLoads([])}
                isDrafts={isDrafts}
            />
            <ModalDialog
                title={L.bulkModify()}
                onClose={() => setBulkModifyRecords([])}
                open={bulkModifyRecords.length > 0}
            >
                <BulkModify
                    drafts={bulkModifyRecords}
                    onSubmit={() => {
                        // table.resetRowSelection(true);
                        setBulkModifyRecords([]);
                    }}
                />
            </ModalDialog>
            <ModalDialog
                title={L.mergeDrafts()}
                onClose={() => setMergeRecords([])}
                open={mergeRecords.length > 0}
            >
                <MergeDrafts
                    drafts={mergeRecords}
                    onSubmit={() => {
                        table.resetRowSelection(true);
                        setMergeRecords([]);
                    }}
                />
            </ModalDialog>
            <ModalDialog
                title={L.cascadeStopTimes()}
                onClose={() => setCascadeRecords([])}
                open={cascadeRecords?.length > 0}
            >
                <CascadeStopTimes
                    drafts={cascadeRecords}
                    onSubmit={() => {
                        setCascadeRecords([]);
                    }}
                />
            </ModalDialog>
            <PublishDraftsModal
                records={publishRecords}
                unselectFn={() => {
                    table.resetRowSelection(true);
                }}
                onResultCallback={() => setPublishRecords([])}
            />
            <ModalDialog
                title={L.unmerge()}
                open={Boolean(unmergeLoad)}
                onClose={() => setUnmergeLoad(null)}
            >
                {unmergeLoad && <UnmergeDrafts
                    loadId={unmergeLoad.id}
                    mergedFromOrderIds={unmergeLoad.mergedFromOrderIds ? unmergeLoad.mergedFromOrderIds : []}
                    unmergeCloseDialog={() => setUnmergeLoad(null)}
                />}
            </ModalDialog>
        </>
    );
};
