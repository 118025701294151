import { FC } from 'react';
import { useWeights } from '../../../../shared/hooks/use-weights';

interface DisplayStopWeightProps {
    weight: number | null;
}

export const DisplayStopWeight: FC<DisplayStopWeightProps> = (props) => {
    const { weight } = props;
    const { convertFromGramsDisplay } = useWeights();

    if (weight) {
        return convertFromGramsDisplay(weight);
    }
};
