import React from 'react';
import { useCargoTypes } from '../../../../../api/queries/use-cargo-types';
import { Stop } from '../../../../../types';
import { AgisticsSelect } from '../../../../shared/agistics-select';
import { useDraftLoadStopContext } from '../../../hooks/draft-load-stop-context';
import { L } from 'harmony-language';
import { useDraftLoadContext } from '../../../hooks/draft-load-context';
import { stopHasCargo } from '../../../../../constants/constants';

const isValidCargoType = (stop: Stop) => {
    return stop.cargoTypeId && stop.cargoTypeId > 0 ? true : false;
}

interface EditableStopCargoTypeProps {
    stop: Stop;
    index: number;
}

export const EditableStopCargoType: React.FC<EditableStopCargoTypeProps> = (props) => {
    const { stop, index } = props;
    const { editedLoad, onEditedLoadChanged, everyUnitsStateChange, oneSelectUnitsStateChange } = useDraftLoadContext();
    const { onEditedStopChanged, isCarrier, isComplete } = useDraftLoadStopContext();
    const { cargoTypeList, isLoading } = useCargoTypes();

    if (isLoading || !stopHasCargo(stop)) {
        return null;
    }

    const validations = [
        {
            isValid: () => isValidCargoType(stop),
            message: L.cargoTypeInvalid(),
        },
    ];

    return (
        <AgisticsSelect
            item={stop.cargoTypeId}
            items={cargoTypeList.map(x => ({ id: x.id, value: x.label }))}
            onChange={(cargoTypeId) => {
                if (editedLoad.stops.every(x => x.cargoTypeId === null)) {
                    const newStops = editedLoad.stops.map(stop => {
                        return {
                            ...stop,
                            cargoTypeId: stopHasCargo(stop) ? cargoTypeId : null,
                        }
                    });

                    onEditedLoadChanged({
                        ...editedLoad,
                        stops: newStops,
                    });

                    everyUnitsStateChange(cargoTypeId);
                } else {
                    onEditedStopChanged({ cargoTypeId: cargoTypeId === -1 ? null : cargoTypeId });

                    oneSelectUnitsStateChange(index, cargoTypeId);
                }
            }}
            showNone={true}
            confinedSpace={true}
            customPopper={true}
            disabled={isCarrier || isComplete}
            validations={validations}
        />
    );
};
