import { Table } from '@tanstack/react-table';
import React, { FC } from 'react';
import { Load } from '../../../types';
import { L } from 'harmony-language';
import { ConditionalLoadTableRow } from './loads/conditional-load-table-row';
import { isLoadRowEditable } from '../utils/drafts-loads-utils';

interface LoadTableBodyProps {
    isDrafts: boolean;
    table: Table<Load>;
    showEstimatedTotalTripTime: boolean;
    showTractor: boolean;
    showTrailer: boolean;
}

export const LoadTableBody: FC<LoadTableBodyProps> = (props) => {
    const { isDrafts, table, showEstimatedTotalTripTime, showTractor, showTrailer } = props;

    return (
        <div className='lt-body'>
            {table.getRowModel().rows.length ? table.getRowModel().rows.map(row => {
                const isRowExpanded = row.getIsExpanded();
                const isRowEditable = isLoadRowEditable(isRowExpanded, row.original);

                return (
                    <div key={row.id} className='lt-row-parent'>
                        <ConditionalLoadTableRow
                            isDrafts={isDrafts}
                            showEstimatedTotalTripTime={showEstimatedTotalTripTime}
                            showTractor={showTractor}
                            showTrailer={showTrailer}
                            isRowExpanded={isRowExpanded}
                            isRowEditable={isRowEditable}
                            row={row}
                        />
                    </div>
                );
            }) : (
                <div style={{ display: 'flex', height: '10rem', justifyContent: 'center', alignItems: 'center' }}>
                    <span>{L.noRecords()}</span>
                </div>
            )}
        </div>
    );
};
