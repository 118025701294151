import { Button, IconButton, Menu, MenuItem, TextField } from '@mui/material';
import { Table } from '@tanstack/react-table';
import { L } from 'harmony-language';
import React, { useState } from 'react';
import { Load, Preset2 } from '../../../types';
import { anyPresetableColumnFiltered, getPresetableColumns } from '../utils/drafts-loads-utils';
import { useUpdateUserPreferences } from '../../shared/hooks/use-update-user-preferences';
import { SnackbarTypes, useSnackbar } from '../../notifications/use-snackbar';
import Lens from '@mui/icons-material/Lens';
import LensOutlined from '@mui/icons-material/LensOutlined';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import { AgisticsTooltip } from '../../shared/agistics-tooltip';
import InfoOutlined from '@mui/icons-material/InfoOutlined';

const applyPresetToTable = (table: Table<Load>, preset: Preset2) => {
    table.resetColumnFilters(true);
    for (const filter of preset.filters) {
        const column = table.getColumn(filter.field);
        if (column) {
            column.setFilterValue(filter.value);
        }
    }
}

interface Presets2Props {
    table: Table<Load>;
}

export const Presets2: React.FC<Presets2Props> = (props) => {
    const { table } = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { preferences, updatePreferences } = useUpdateUserPreferences();
    const [name, setName] = useState<string>('');
    const snackbar = useSnackbar();

    const anyColFiltered = anyPresetableColumnFiltered(table);

    const handlePresetButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePresetMenuClose = () => {
        setAnchorEl(null);
    };

    const handlePresetSave = () => {
        if (preferences.presets2?.find(x => x.name.toLocaleLowerCase() === name.toLocaleLowerCase())) {
            snackbar(L.presetNameError(), SnackbarTypes.error);
        } else {
            const presetableColumns = getPresetableColumns(table);
            const filters = presetableColumns.map(column => {
                const filterValue = column.getFilterValue();
                if (filterValue) {
                    const newFilter = {
                        field: column.id,
                        value: filterValue,
                    }

                    return newFilter;
                }
            }).filter(x => !!x);

            const newPreferences = {
                ...preferences,
                presets2: [
                    ...(preferences.presets2 ? preferences.presets2 : []),
                    {
                        name: name,
                        default: false,
                        filters: filters,
                    }
                ],
            }

            updatePreferences(newPreferences);
            setName('');
        }
    }

    const handlePresetApply = (preset2: Preset2) => {
        applyPresetToTable(table, preset2);
        setAnchorEl(null);
    }

    const handlePresetDefault = (preset2: Preset2) => {
        const isDefault = preset2.default;

        if (preferences.presets2) {
            const newPresets = preferences.presets2.map(x => {
                if (x.name === preset2.name) {
                    return {
                        ...x,
                        default: !isDefault,
                    }
                } else {
                    return {
                        ...x,
                        default: isDefault ? false : isDefault,
                    }
                }
            });

            const newPreferences = {
                ...preferences,
                presets2: newPresets,
            }

            updatePreferences(newPreferences);
        }
    }

    const handlePresetDelete = (preset2: Preset2) => {
        if (preferences.presets2) {
            const newPresets = preferences.presets2.filter(x => x.name !== preset2.name);

            const newPreferences = {
                ...preferences,
                presets2: newPresets,
            }

            updatePreferences(newPreferences);
        }
    }

    return (
        <div>
            <Button
                variant='contained'
                color='inherit'
                onClick={handlePresetButtonClick}
            >
                {L.presets()}
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handlePresetMenuClose}
            >
                <div style={{ padding: '0 .5rem', minWidth: '300px', minHeight: '70px' }}>
                    <AgisticsTooltip
                        tooltip={<div style={{ width: '200px' }}>{L.presetableFields()}</div>}
                        placement='right-start'
                    >
                        <InfoOutlined />
                    </AgisticsTooltip>
                    {anyColFiltered && <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', paddingBottom: '.5rem' }}>
                        <TextField
                            variant='standard'
                            sx={{ flexGrow: 1 }}
                            // https://github.com/mui/material-ui/issues/19116
                            // MUI Menu has some keybinds that it will catch, 'S', 'Home', etc...
                            onKeyDown={(e) => {
                                e.stopPropagation();
                            }}
                            onChange={(e) => {
                                setName(e.target.value);
                            }}
                        />
                        <Button
                            variant='contained'
                            size='small'
                            disabled={name.length === 0}
                            onClick={handlePresetSave}
                        >
                            {L.save()}
                        </Button>
                    </div>}
                    <div>
                        {preferences.presets2?.length ? preferences.presets2.map(preset2 => {
                            return (
                                <div key={preset2.name} style={{ display: 'flex' }}>
                                    <MenuItem
                                        sx={{ flexGrow: 1 }}
                                        onClick={() => handlePresetApply(preset2)}
                                    >
                                        {preset2.name}
                                    </MenuItem>
                                    <IconButton onClick={() => handlePresetDefault(preset2)}>
                                        {preset2.default
                                            ? <Lens fontSize='small' />
                                            : <LensOutlined fontSize='small' />
                                        }
                                    </IconButton>
                                    <IconButton onClick={() => handlePresetDelete(preset2)}>
                                        <DeleteOutlined fontSize='small'/>
                                    </IconButton>
                                </div>
                            )
                        }): <div style={{ textAlign: 'center' }}>{L.noPresetsHaveBeenDefined()}</div>}
                    </div>
                </div>
            </Menu>
        </div>
    );
};
