import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useSnackbar } from '../../../../components/notifications/use-snackbar';
import { L } from 'harmony-language';
import { Load } from '../../../../types';
import { useDraftsKey, useLoadsKey } from '../../../config-hooks';

const deleteLoads = async (options: { ids: number[] }) => {
    const ids = options.ids;

    await axios.delete(`${API_BASE}/api/orders`, { data: ids });
}

export const useDeleteLoadsMutation = (isDrafts: boolean) => {
    const snackbar = useSnackbar();
    const queryKey = isDrafts ? useDraftsKey() : useLoadsKey();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: deleteLoads,
        onMutate: async (variables) => {
            await queryClient.cancelQueries(queryKey);

            const previousCache = queryClient.getQueryData<Load[]>(queryKey);

            if (previousCache) {
                const ids = variables.ids;
                const filteredLoads = previousCache.filter(load => !ids.includes(load.id));

                queryClient.setQueryData(queryKey, filteredLoads);
            }

            return previousCache;
        },
        onError: (_1, _2, context) => {
            if (context) {
                queryClient.setQueryData<Load[]>(queryKey, context);
            }
        },
        onSuccess: (_, options) => {
            snackbar(L.bulkDeleteSnackbarSuccess(options.ids.length.toString()));
        },
        // onSettled: () => {
        //     queryClient.invalidateQueries(queryKey);
        // },
    });

    return mutation;
};
