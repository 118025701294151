import React, { FC } from 'react';
import { Stop } from '../../../../../types';
import { useCargoTypes } from '../../../../../api/queries/use-cargo-types';
import { stopCargoEligibleForCompartments } from '../../../../../utils/data-mapping-utils';
import { CompartmentDiagram } from '../../../../shared/compartment-diagram';

interface DisplayStopCompartmentsProps {
    stop: Stop;
    trailerId: number | null;
}

export const DisplayStopCompartments: FC<DisplayStopCompartmentsProps> = (props) => {
    const { stop, trailerId } = props
    const { data: cargoTypes, isLoading: isLoadingCargoTypes } = useCargoTypes();

    if (isLoadingCargoTypes || !stopCargoEligibleForCompartments(cargoTypes, stop)) {
        return null;
    }

    return (
        <CompartmentDiagram
            trailerId={trailerId}
            cargoTypeId={stop.cargoTypeId}
            compartments={stop.compartments || []}
            hideLabel={true}
        />
    );
};
