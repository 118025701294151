import { CircularProgress, IconButton, InputAdornment, TextField } from '@mui/material';
import { Table } from '@tanstack/react-table';
import React, { FC, useEffect, useState } from 'react';
import { Load } from '../../../../types';
import { useDebounce } from '../../../shared/hooks/use-debounce';
import { GlobalFilterValue } from './use-loads-global-filter';
import { AgisticsTooltip } from '../../../shared/agistics-tooltip';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { L } from 'harmony-language';

// this is split now as tooltip does not like to 'hover' over header because it's position: sticky
const ArbitraryTextFilterTooltipContent: FC = () => {
    return (
        <div style={{ display: 'flex', gap: '.5rem' }}>
            <div>
                <div>{`${L.loadId()}: ${L.id()}`}</div>
                <div>{`${L.driver()}: ${L.name()}`}</div>
                <div>{`${L.tractor()}: ${L.name()}`}</div>
                <div>{`${L.trailer()}: ${L.name()}`}</div>
                <div>{`${L.stopArrivalTime()}: YYYY-MM-DD`}</div>
                <div>{`${L.readyTime()}: YYYY-MM-DD`}</div>
                <div>{`${L.requiredBegin()}: YYYY-MM-DD`}</div>
                <div>{`${L.requiredEnd()}: YYYY-MM-DD`}</div>
            </div>
            <div>
                <div>{`${L.cargo()}: ${L.cargoType()} - ${L.productionPlan()} - ${L.lotId()}`}</div>
                <div>{`${L.customerOrderNumber()}: ${L.customerOrderNumber()}`}</div>
                <div>{`${L.orderNumber()}: ${L.orderNumber()}`}</div>
                <div>{`${L.location()}: ${L.name()} - ${L.description()} - ${L.subLocation()}`}</div>
                {/* <div>{`${L.loadingTeam()}: ${L.name()}`}</div> */}
            </div>
        </div>
    );
}

interface ArbitraryTextFilterProps {
    table: Table<Load>;
}

export const ArbitraryTextFilter: React.FC<ArbitraryTextFilterProps> = (props) => {
    const { table } = props;
    const [search, setSearch] = useState('');
    const { debouncedValue, isDebouncing } = useDebounce(search);

    useEffect(() => {
        const newFilter: GlobalFilterValue = {
            ...table.getState().globalFilter,
            arbitraryText: debouncedValue,
        }

        table.setGlobalFilter(newFilter);
    }, [debouncedValue]);

    return (
        <div style={{ display: 'flex', alignItems: 'flex-end', gap: '.3rem' }}>
            <AgisticsTooltip
                tooltip={<ArbitraryTextFilterTooltipContent />}
                placement='left'
            >
                <InfoOutlined />
            </AgisticsTooltip>
            <TextField
                variant='standard'
                value={search}
                label={L.search()}
                onChange={(e) => {
                    setSearch(e.target.value);
                }}
                sx={{
                    width: '15rem',
                }}
                InputProps={{
                    endAdornment: isDebouncing
                    ? <InputAdornment position='end'><CircularProgress size={'1em'} /></InputAdornment>
                    : search
                    ? <InputAdornment position='end'>
                        <IconButton size='small' onClick={() => setSearch('')}>
                            <CloseIcon fontSize='inherit' />
                        </IconButton>
                    </InputAdornment>
                    : undefined
                }}
            />
        </div>
    );
};
