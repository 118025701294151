import React from 'react';
import TextField from '@mui/material/TextField';
import { InputBaseComponentProps, TextFieldProps } from '@mui/material';

export interface Validation {
    isValid: (...args: any[]) => boolean;
    message: string;
}

export const useValidations = (validations?: Validation[]): React.MutableRefObject<HTMLInputElement | undefined> => {
    const ref = React.useRef<HTMLInputElement>();

    React.useEffect(() => {
        ref.current?.setCustomValidity('');

        validations?.forEach((validation) => {
            if (!validation.isValid()) {
                ref.current?.setCustomValidity(validation.message);
            }
        });
    }, [validations]);

    return ref;
};

type ValidatedTextFieldProps = {
    validations?: Validation[];
    inputProps?: InputBaseComponentProps;
    label?: string;
} & TextFieldProps;

export const ValidatedTextField: React.FC<ValidatedTextFieldProps> = (props) => {
    const { validations, inputProps, label } = props;
    const textInputRef = useValidations(validations);

    return (
        <TextField
            variant='standard'
            {...props}
            InputProps={{
                ...props.InputProps,
                inputRef: textInputRef,
                inputProps: {
                    ...inputProps,
                    title: label,
                },
            }}
        />
    );
};
