import { StopStatus } from 'harmony-constants';
import { getSocket } from '../../services/socket-factory';

interface SocketUpdatedStop {
    id: number;
    status: StopStatus;
    updatedAt: string;
}

export const connectSocket = (addSocketStopId: (stopId: number) => void) => {
    const socket = getSocket();

    socket.on('connect', () => {
        console.log('Socket connected!');
    });

    socket.on('disconnect', () => {
        console.log('Socket disconnected!');
    });

    socket.on('orders:update', (updatedOrder: any) => {
        console.log(`Socket orders:update: ${updatedOrder.id}`);
        // ordersUpdate(updatedOrder, dispatch, getState); TODO: Re-implement this with react-query
    });

    socket.on('orders:stops:update', (updatedStop: SocketUpdatedStop) => {
        console.log(`Socket orders:stops:update: ${updatedStop.id}`);

        addSocketStopId(updatedStop.id);
    });

    // socket.on('device:locations:latest:update', (deviceLocation) => {
    //     console.log(`Socket device:locations:latest:update:`, deviceLocation);
    // });
};
